import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { IncidentModel } from '../models/incident.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';

@Injectable({
    providedIn: 'root'
})
export class IncidentService extends ResourceService<IncidentModel> {
    protected servicePath: string = '/Incident';

    public ModelType = IncidentModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }
    
}
