import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';

import {
    RelatedCompany
} from './company.model';

import {
    Role
} from './role.model';
import { BehaviorSubject } from 'rxjs';
import { ScheduleModel } from './schedule.model';

export interface RelatedUser {
    uuid: string;
    display_name: string;
}

export interface UserContact {
    name: string;
    phone: string[];
    email: string[];
}

export interface BillingContact {
    name: string;
    phone: string;
    cell: string;
    email: string;
}

export interface CustomerProfile {
    name: string;
    addressStreet: string;
    addressCity: string;
    addressState: string;
    addressZip: string;
    fax: string;
    contact: UserContact[];
}

export interface CustomerBilling {
    name: string;
    addressStreet: string;
    addressCity: string;
    addressState: string;
    addressZip: string;
    fax: string;
    contact: BillingContact[];
}

export interface CustomerTaxProfile {
    id: string;
    code: string;
}

export interface Customer {
    profile: CustomerProfile;
    billing: CustomerBilling;
    taxProfile: CustomerTaxProfile;
}

export interface DefaultTruck {
    id: string;
    name: string;
}

export interface DriverInfo {
    defaultTruck: DefaultTruck;
    lastDrugTest: string;
    lastDrugTestResult: string;
    lastDUI: string;
}

// export interface Billing {
//     address: Address
//     fax: string
// }

// export interface MasterUserAccount {
//     id: string,
//     name: string
// }

// export interface Address {
//     street: string,
//     city: string,
//     state: string,
//     zip: string
// }

// export interface BillingControl {
//     billingCycle: BillingCycle,
//     taxProfiles: TaxProfile[],
//     startLateCharges: number,
//     startLateNotice: number,
//     isElectronicFundsTransfer: boolean,
//     isContractRequired: boolean,
//     requiresFinancialApproval: boolean,
//     requiresOperationsApproval: boolean,
//     isDigitalStatement: boolean
// }

// export interface BillingCycle {
//     id: string,
//     name: string
// }

// export interface TaxProfile {
//     id: string,
//     name: string
// }

// export interface Financial {
//     unbilledAmount: number,
//     currentAmount: number,
//     lateTotalThirtyDays: number,
//     lateTotalSixtyDays: number,
//     lateTotalNinetyDays: number,
//     lateTotaloneTwentyDays: number
//     lateChargeAmount: number,
//     balanceAmount: number,
//     lastPaidAt: Date,
//     lastPaidAmount: number,
//     lastLateChargeAmount: number,
//     computedOn: number,
//     lastChargedDate: Date,
//     lastNotice: string,
//     lastNoticeDate: Date,
//     purchaseOrder: number,
//     purchaseOrderExpires: Date,
//     creditLimitAmount: number,
//     depositAmout: number,
//     depositDate: Date,
// }

// export interface UploadFile {
//     id: string,
//     fileName: string,
//     contentType: string
// }

export class UserModel extends ResourceModel {

    @Field(String)
    public username: string = "";

    @Field(String)
    public cognitoId: string = "";

    @Field(String)
    public name: string = "";

    @Field(String)
    public phone: string = "";

    @Field(String)
    public mobilePhone: string = "";

    @Field(String)
    public email: string = "";

    @Field(String)
    public address: string = "";

    @Field(String)
    public password: string = "";

    @Field(Object)
    public customer: Customer | null = null;

    @Field(Object)
    public company: RelatedCompany = { id: "", name: "" };

    @Field(Object)
    public driver: DriverInfo | null = null;

    @Field(Object)
    public role: Role = {
        id: "",
        name: "",
        isAdmin: false,
        isCustomer: false,
        isDriver: false,
        isMechanic: false,
        isSuperAdmin: false,
        isGuest: false,
        isAccounter: false,
        isSalesRep: false
    };

    @Field(Boolean)
    public showNotifications: boolean = true;

    @Field(Boolean)
    public newOrders: boolean = true;

    @Field(Boolean)
    public orderModifications: boolean = true;

    @Field(Boolean)
    public driverUpdates: boolean = true;

    @Field(Boolean)
    public recurringOrders: boolean = true;

    @Field(String)
    public activeStatus: string = "";

    @Field(Date)
    public registeredAt: Date | null = null;

    @Field(Boolean)
    public isCustomerManager: boolean = true;

    @Field(Boolean)
    public isBillingManager: boolean = true;

    @Field(Array)
    public permissions: string[] = [];

    @Field(Array)
    public roles: string[] = [];

    @Field(String)
    public customerId: string | null = null;

    // @Field(Object)
    // public masterAccount: MasterUserAccount | null = null;

    // @Field(Object)
    // public billing: Billing | null = null

    // @Field(Object)
    // public billingControl: BillingControl | null = null;

    // @Field(Object)
    // public financial: Financial | null = null;

    // @Field(Array)
    // public files: UploadFile[] = [];

    // @Field(Boolean)
    // public customerHasLateCharges: boolean = false;

    // non saved fields, just there to help the profile
    public roleData: any = null;
    public companyData: any = null;
    public customerStatus: string = null;
    public assignedSchedules$: BehaviorSubject<ScheduleModel[]> = new BehaviorSubject<ScheduleModel[]>([]);

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
