import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { ZoneModel } from '../models/zone.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ZoneService extends ResourceService<ZoneModel> {
    protected servicePath: string = '/Zone';

    public ModelType = ZoneModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public flatZone(zone: ZoneModel): any {
        return {
            id: zone.id,
            name: zone.name,
            accountNumber: zone.accountNumber
        };
    }

    // TODO: Add address to zone query
    public getZones(companyId: string = null, customerId: string = null, zoneName: string = null, address: string = null): Observable<ZoneModel[]> {
        let where: any = {
            query: {
                bool: {
                    must: [],
                    must_not: [
                        {
                            exists: {
                                field: "deletedAt"
                            }
                        }
                    ]
                }
            },
            size: 1000
        };

        if (companyId) {
            where.query.bool.must.push({
                nested: {
                    path: "company",
                    query: {
                        term: {
                            "company.id": companyId
                        }
                    }
                }
            });
        }

        if (customerId) {
            where.query.bool.must.push({
                match: {
                    type: "Customer"
                }
            });

            where.query.bool.must.push({
                nested: {
                    path: "entity",
                    query: {
                        term: {
                            "entity.id": customerId
                        }
                    }
                }
            })
        }

        if (zoneName) {
            let zoneArr: string[] = zoneName.replace(/[^a-zA-Z0-9 ]/g, "").split(" ");

            zoneArr.forEach((zone: string) => {
                where.query.bool.must.push({
                    wildcard: {
                        "name": `*${zone}*`
                    }
                });
            });
        }

        if (address) {
            let addressArr: string[] = address.replace(/[^a-zA-Z0-9 ]/g, "").split(" ");

            addressArr.forEach((term: string) => {
                where.query.bool.must.push({                
                    wildcard: {
                        "address": `*${term}*`
                    }                 
                });
            });
        }

        return this.list({
            allowCache: false,
            where: where
        }).pipe(
            map(
                (results: ZoneModel[]) => results.sort((a, b) => a.name.localeCompare(b.name))
            )
        );
    }

}
