import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';

export class FileUploadModel extends ResourceModel {

    @Field(String)
    public entityId: string = "";

    @Field(String)
    public entityTable: string = "";

    @Field(String)
    public fileName: string = "";

    @Field(String)
    public contentType: string = "";

    @Field(String)
    public relativeFilePath: string = "";

    @Field(String)
    public parentId: string | null = null;

    @Field(Boolean)
    public visible: boolean = true;

    @Field(Boolean)
    public includeWithInvoice: boolean = false;

    @Field(Boolean)
    public includeWithLEEDReport: boolean = false;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
