import { Component, OnDestroy, ViewChild, ElementRef, Input, EventEmitter } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import {
	AuthResourceService, ServiceResponse,
	UserModel,
	RouteTrackingDeviceService
} from '../../modules/resource-module';

import { Subscription, BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { MatFormFieldControl } from '@angular/material/form-field';

@Component({
	selector: 'fg-typeahead',
	templateUrl: './fg-typeahead.component.html',
	styleUrls: ['./fg-typeahead.component.scss']
})
/**
 * Component to handle fg-typeahead UI.
 */
export class FgTypeaheadComponent implements OnDestroy {

	public choices$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	public timerFreq: number = 1;
    public timerInterval: any = null;

    @Input() initialValue: string;
    @Input() fullWidth: boolean = false;
    @Input() matLabel: string;
	@Input() changeCallback: any;
	@Input() selectCallback: any;
	@Input() callbackTarget: any;
    @Input() setInput: boolean = false;

    @Input() public action: EventEmitter<string> = new EventEmitter<string>();
	@Input() choiceValue: any;
	@Input() choiceDisplay: any;
	@Input() value: string;

	public inputControl: FormControl;
	public inputValue: any;

	public form: FormGroup;

	constructor(
		protected _dialog: MatDialog,
		protected _authResourceService: AuthResourceService,
		protected _routeTrackingDeviceService: RouteTrackingDeviceService,
		protected _router: Router,
		protected _titleService: Title
	) {}

	ngOnInit() {
        this.action.subscribe((action: string) => {
            if (action == 'reset') {
                this.setTypeaheadControl("");
            } else if (action == "get") {
                this.getData();
            }
            else if (action == "full-reset") {
                this.setTypeaheadControl("");
                this.getData();
            } else {
                this.setTypeaheadControl(action);
            }
        });

		this.inputControl = new FormControl(this.value, []);
		this.form = new FormGroup({
			inputControl: this.inputControl
		});

        // Set initial value if passed to component
        if (this.initialValue) {
            this.inputControl.setValue(this.initialValue);
            this.inputValue = this.initialValue;
            this.value = this.initialValue;
        }

		// This sets a .5 second delay on Customer input before retrieving new data.
        this.inputControl.valueChanges.subscribe((value: any) => {
            this.timerFreq = 1;
            this.inputValue = value;

            if (!this.timerInterval) {
                this.timerInterval = setInterval(() => {
                    this.timerFreq--;
                    if (this.timerFreq < 0) {
						this.getData();
                        clearInterval(this.timerInterval);
                        this.timerInterval = null;
                    }
                }, 500);
            }
        });

        // Init Typeahead List
        this.getData();
	}

	ngOnDestroy() {

	}

    /**
     * Sets data for the typeahead control.
     *
     * @return  {void}    None
     */
    private getData(): void {
        this.changeCallback(this.inputValue, this.callbackTarget).subscribe((results: any[]) => {
            this.choices$.next(results);
        });
    }

    /**
     * Resets the typeahead control.
     *
     * @return  {<void>}  None
     */
    public setTypeaheadControl(value: string): void {
        this.inputControl.setValue(value);
        this.inputValue = value;
        this.value = value;
    }

    /**
     * Sets the value of the typeahead control back to original value when loss of focus.
     *
     * @return  {void}    None
     */
    public onBlur(): void {
        this.inputControl.setValue(this.value);
    }

    /**
     * Resets the typeahead control to original value on loss of focus.
     *
     * @param   {[type]}  $event  the event containing the original value
     *
     * @return  {void}            None
     */
    public resetInput($event): void {
        if (this.setInput) {
            this.inputControl.setValue($event.option?.value?.name || '');
            this.value = $event.option?.value?.name || '';
        }
    }

}
