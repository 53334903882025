import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SimpleModalComponent } from '../components/simple-modal/';
import { CalendarEventModalComponent } from '../modules/orders/components/scheduler/calendar-event-modal';
import { TimeAdjustModalComponent } from '../modules/orders/components/scheduler/time-adjust-modal';
import { OrderTemplateModalComponent } from '../modules/orders/components/orders/orders-edit/order-template-modal';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    constructor(
        private dialog: MatDialog
    ) { }

    public openDialog(configData: MatDialogConfig): Observable<any> {
        const dialogRef = this.dialog.open(
            SimpleModalComponent,
            configData
        );

        return dialogRef.afterClosed();
    }

    public openCalendarDialog(configData: MatDialogConfig): Observable<any> {
        const dialogRef = this.dialog.open(
            CalendarEventModalComponent,
            configData
        );

        return dialogRef.afterClosed();
    }

    public openTimeAdjustDialog(configData: MatDialogConfig): Observable<any> {
        const dialogRef = this.dialog.open(
            TimeAdjustModalComponent,
            configData
        );

        return dialogRef.afterClosed();
    }

    public openTemplateDialog(configData: MatDialogConfig): Observable<any> {
        const dialogRef = this.dialog.open(
            OrderTemplateModalComponent,
            configData
        );

        return dialogRef.afterClosed();
    }
}
