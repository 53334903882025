import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { OrderModel } from '../models/order.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';

@Injectable({
    providedIn: 'root'
})
export class OrderService extends ResourceService<OrderModel> {
    protected servicePath: string = '/Order';

    public ModelType = OrderModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public flatOrder(order: OrderModel): any {
        return {
            id: order.id,
            orderNumber: order.orderId
        }
    }
}
