import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/index';
import { defaultIfEmpty, map } from 'rxjs/operators';

import { UserModel } from '../../resource-module/models';
import { ApiClientService, ServiceResponse } from './api-client.service';
import { ResourceService } from './resource.service';

@Injectable({
    providedIn: 'root'
})
export class UserService extends ResourceService<UserModel> {
    protected servicePath: string = '/User';

    public ModelType = UserModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    /**
     * Request for currently authenticated user's profile info.
     */
    public viewProfile(): Observable<ServiceResponse> {
        return this._apiClient
            .get(
                this.serviceUrl + 'profile',
                {
                    observe: 'response'
                }
            )
            .pipe(
                map<ServiceResponse, ServiceResponse>(
                    (response) => {
                        if (response.success)
                            response.data = new this.ModelType(response.data);
                        return response;
                    }

                )
            );
    }

    /**
     * Request to update currently authenticated user's profile.
     */
    public updateProfile(entry: UserModel): Observable<UserModel> {
        return this._apiClient
            .request
            (
                'put',
                this.serviceUrl + 'profile',
                {
                    observe: 'response',
                    body: entry.data
                }
            )
            .pipe(
                map<any, UserModel>(
                    (response) =>
                        new this.ModelType(response)
                )
            );
    }

    /**
     * Request to delete currently authenticated user's profile.
     */
    public requestAccountClosure(id: string): Observable<any> {
        return this._apiClient
            .request
            (
                'POST',
                this.serviceUrl + 'requestAccountClosure/' + id,
                {
                    observe: 'response'
                }
            );
    }

    /**
     * Delete and purge currently authenticated user's profile.
     */
    public purgeAccount(id: string): Observable<any> {
        return this._apiClient
            .request
            (
                'DELETE',
                this.serviceUrl + 'closeAccount/' + id,
                {
                    observe: 'response'
                }
            );
    }

    /**
     * Request to delete currently authenticated user's profile.
     */
    public deleteAccount(): Observable<any> {
        return this._apiClient
            .request
            (
                'DELETE',
                this.serviceUrl + 'profile',
                {
                    observe: 'response'
                }
            );
    }

    public inviteUser(email: string, companyCode: string, roleId: string, customerId: string | null = null): Observable<any> {
        return this._apiClient
            .request
            (
                'POST',
                this.serviceUrl + 'sendInvite',
                {
                    observe: 'response',
                    body: {
                        email: email,
                        companyCode: companyCode,
                        role: roleId,
                        customerId: customerId
                    }
                }
            );
    }

    public welcomeUser(email: string): Observable<any> {
        return this._apiClient
            .request
            (
                'POST',
                this.serviceUrl + 'sendWelcome',
                {
                    observe: 'response',
                    body: {
                        email: email,
                    }
                }
            );
    }

    public unsubscribeUser(email: string): Observable<any> {
        return this._apiClient
            .request(
                'POST',
                this.serviceUrl + 'unsubscribe',
                {
                    observe: 'response',
                    body: {
                        email: email
                    }
                }
            );
    }

    public usersForCompany(companyId: string) {
        return this.search({
            "query": {
                "bool": {
                    "must": [
                        {
                            "nested": {
                                "path": "company",
                                "query": {
                                    "term": {
                                        "company.id": companyId
                                    }
                                }
                            }
                        }
                    ]
                }
            }
        });
    }

    public flatUser(user: UserModel): any {
        return {
            id: user.id,
            name: user.name
        }
    }

    /**
     * Gets users by role
     *
     * @param   {string}                   role       Role that will filter users by.
     * @param   {string}                   companyId  Optional parameter to filter users by company if provided.
     *
     * @return  {Observable<UserModel>[]}             Users record filtered by role and by company if company id is provided.
     */
    public getUsersByRole(role: string, companyId: string = null): Observable<UserModel[]> {
        let where: any = this.buildQuery(role, companyId);

        return this.list({
            allowCache: false,
            where: where
        }).pipe(
            map(
                (results: UserModel[]) => results.sort((a, b) => a.name.localeCompare(b.name))
            )
        );
    }

    /**
     * Gets list of Users
     *
     * @param   {string}                   companyId  Optional parameter to filter users by company if provided.
     * @param   {string}                    userName  Optional parameter to filter users by user name if provided.
     *
     * @return  {Observable<UserModel>[]}             Users record filtered by company if company id is provided.
     */
    public getUsers(companyId: string = null, userName: string = null): Observable<UserModel[]> {
        let where: any = {
            query: {
                bool: {
                    must: [],
                    must_not: [
                        {
                            exists: {
                                field: "deletedAt"
                            }
                        }
                    ]
                }
            },
            size: 1000
        }

        if (companyId) {
            where.query.bool.must.push({
                nested: {
                    path: "company",
                    query: {
                        term: {
                            "company.id": companyId
                        }
                    }
                }
            });
        }

        if (userName) {
            let userArr: string[] = userName.replace(/[^a-zA-Z0-9 ]/g, "").split(" ");

            userArr.forEach((user: string) => {
                where.query.bool.must.push({
                    wildcard: {
                        "name": `*${user}*`
                    }
                });
            });
        }

        return this.list({
            allowCache: false,
            where: where
        }).pipe(
            map(
                (results: UserModel[]) => results.sort((a, b) => a.name.localeCompare(b.name))
            )
        );
    }

    /**
     * Builds the query that will be used to get users by role
     *
     * @param   {string}  role       Type of role to filter users by.
     * @param   {string}  companyId  Optional parameter to filter down by company.
     *
     * @return  {any}                Query object used when getting users.
     */
    private buildQuery(role: string, companyId: string = null): any {
        let roleQuery: any = {}

        switch (role) {
            case 'isGuest':
                roleQuery = {
                    "role.isGuest": true
                };
                break;
            case 'isAccounter':
                roleQuery = {
                    "role.isAccounter": true
                };
                break;
            case 'isSalesRep':
                roleQuery = {
                    "role.isSalesRep": true
                };
                break;
            case 'isDriver':
                roleQuery = {
                    "role.isDriver": true
                };
                break;
            case 'isMechanic':
                roleQuery = {
                    "role.isMechanic": true
                };
                break;
            case 'isCustomer':
                roleQuery = {
                    "role.isCustomer": true
                };
                break;
            case 'isAdmin':
                roleQuery = {
                    "role.isAdmin": true
                };
                break;
            case 'isSuperAdmin':
                roleQuery = {
                    "role.isSuperAdmin": true
                };
                break
        }

        let where: any = {
            query: {
                bool: {
                    must: [
                        {
                            nested: {
                                path: "role",
                                query: {
                                    term: roleQuery
                                }
                            }
                        }
                    ],
                    must_not: [
                        {
                            exists: {
                                field: "deletedAt"
                            }
                        }
                    ]
                }
            }
        };

        if (companyId) {
            where.query.bool.must.push({
                nested: {
                    path: "company",
                    query: {
                        term: {
                            "company.id": companyId
                        }
                    }
                }
            });
        }

        return where;
    }
}
