import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    ForgotPasswordComponent,
    ConfirmForgotPasswordComponent,
    ChangePasswordComponent,
} from './components';

const routes = [
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'confirm-forgot-password',
        component: ConfirmForgotPasswordComponent
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class RoutingModule {}
