import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { QuoteModel } from '../models/quote.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { ProfileService } from '../../user-profile/services';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class QuoteService extends ResourceService<QuoteModel> {
    protected servicePath: string = '/Quote';

    public ModelType = QuoteModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService,
        protected profileService: ProfileService
    ) {
        super(http, _apiClient);
    }

    public requestQuoteInfo(email: string, quoteId: string): Observable<any> {
        return this._apiClient
            .request
            (
            'POST',
            this.serviceUrl + 'requestQuoteInfo',
            {
                observe: 'response',
                body: {
                    email: email,
                    quoteId: quoteId
                }
            }
            );
    }
    
}
