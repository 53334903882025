import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';

import {
    RelatedCompany
} from './company.model';
import {
    User
} from './driverActivityLog.model';
import {
    ServiceType
} from './serviceType.model';

export interface Quote {
    id: string,
    quote: string
}

export interface Site {
    id: string,
    site: string
}

export interface Charge {
    active: boolean,
    service: ServiceType,
    units: number,
    cost: number,
    override: number
}

export class OrderTemplateModel extends ResourceModel {

    @Field(Object)
    public company: RelatedCompany = { id: "", name: "" };

    @Field(Object)
    public customer: User = { id: "", name: "" };

    @Field(Number)
    public amount: number = 0.00;

    @Field(Object)
    public quote: Quote = { id: "", quote: "" };

    @Field(String)
    public customPurchaseOrder: string = "";

    @Field(Object)
    public createdBy: User = { id: "", name: "" };

    @Field(Object)
    public site: Site = { id: "", site: "" };

    @Field(String)
    public siteAddress: string = "";

    @Field(String)
    public siteCity: string = "";

    @Field(String)
    public siteState: string = "";

    @Field(String)
    public siteZip: string = "";

    @Field(Array)
    public charges: Charge[] = [];

    constructor(data?: any) {
        super();
        this.mapData(data);
    }
}
