import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { StripePaymentsService } from '../../services/stripe-payments.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import {
    StripeElementsOptions,
    PaymentIntent,
    StripePaymentElement,
} from '@stripe/stripe-js';
import { FormBuilder, Validators } from '@angular/forms';


@Component({
    selector: 'app-payment-dialog',
    templateUrl: './payment-dialog.component.html',
    styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent {

    protected paymentIntent: PaymentIntent;
    protected paymentElement: StripePaymentElement
    public isLoading: boolean = false;

    constructor(
        private fb: FormBuilder,
        private stripeService: StripePaymentsService,
        protected dialog: MatDialogRef<PaymentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: any
    ) {
        this.isLoading = true;
        this.paymentIntent = data.paymentIntent;
        this.stripeService.initStripe(this.paymentIntent.client_secret).then(() => {
            this.paymentElement = this.stripeService.elements.create("payment");
            this.paymentElement.mount('#payment-element');

            this.paymentElement.on('ready', (event: any) => {
                this.isLoading = false;
            })
        });
    }

    public cancel(): void {
        this.stripeService.cancelPaymentIntent(this.paymentIntent.id).subscribe(() => {
            this.dialog.close();
        });
    }

    public pay(): void {
        this.setLoading(true);
        this.stripeService.confirmPayment().then((data: any) => {

            this.setLoading(false);
            if (data.type === "card_error" || data.type === "validation_error"
                || data.type === "invalid_request_error") {
                this.showMessage(data.message);
            } else {
                this.setStatusMessage(data.paymentIntent);
                if (data.paymentIntent.status === "succeeded") {
                    setTimeout(() => {
                        this.dialog.close(data);
                    }, 2000);
                }
            }
        });
    }

    public showMessage(messageText): void {
        const messageContainer = document.querySelector("#payment-message");

        messageContainer.textContent = messageText;
        messageContainer.classList.remove("hidden");

        setTimeout(() => {
            messageContainer.textContent = "";
            messageContainer.classList.add("hidden");
        }, 4000);
    }

    // Show a spinner on payment submission
    public setLoading(isLoading): void {
        this.isLoading = isLoading;
        if (isLoading) {
            // Disable the button and show a spinner
            // document.querySelector("#submit").disabled = true;
            document.querySelector("#spinner").classList.remove("hidden");
            document.querySelector("#button-text").classList.add("hidden");
        } else {
            // document.querySelector("#submit").disabled = false;
            document.querySelector("#spinner").classList.add("hidden");
            document.querySelector("#button-text").classList.remove("hidden");
        }
    }

    public setStatusMessage(paymentIntent: PaymentIntent): void {
        switch (paymentIntent.status) {
            case "succeeded":
                this.showMessage("Payment succeeded!");
                break;
            case "processing":
                this.showMessage("Your payment is processing.");
                break;
            case "requires_payment_method":
                this.showMessage("Your payment was not successful, please try again.");
                break;
            default:
                this.showMessage("Something went wrong.");
                break;
        }
    }

}
