import { Component, OnDestroy, ViewChild, ElementRef, Inject } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
	UserService, ServiceResponse,
	UserModel, AuthResourceService
} from '../../../../../modules/resource-module';

import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'close-account-dialog',
	templateUrl: './close-account.dialog.html',
	styleUrls: ['./close-account.dialog.scss']
})
/**
 * Component to handle change password UI.
 *
 */
export class CloseAccountDialog implements OnDestroy {

	protected _dialogSubscription: Subscription;

	// TODO temp, delete
	public message: string = '';

	public pending: boolean = false;
	public code: string = "";
	private id: string = "";

    /**
     * Form group for change password.
     */
	public changePasswordForm: FormGroup = new FormGroup({
	});

	protected _subscriptions: Array<Subscription> = [];

	constructor(
		protected _dialogRef: MatDialogRef<any>,
		protected _userService: UserService,
		protected _authResourceService: AuthResourceService,
		protected _router: Router,
		protected _titleService: Title,
		@Inject(MAT_DIALOG_DATA) public data: any
		) {
		this.id = data.id;
	}

	ngOnDestroy() {
		this._subscriptions.forEach((subscription) => {
			subscription.unsubscribe();
		})
	}

    /**
     * Submit form and change password
     */
	public submit() {
		this.message = "";
		this._userService.requestAccountClosure(this.id).subscribe((response) => {
			if(response.success) {
				this.message = "An email has been sent with further instructions."
			} else {
				this.message = response.message;
			}
		})
	}
}
