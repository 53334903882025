import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

import {
    RelatedCompany
} from './company.model';

export class RouteTrackingDeviceModel extends ResourceModel {

    @Field(String)
    public name: string = "";
	
    @Field(String)
    public deviceId: string = "";

    @Field(String)
    public assignedTruckId: string = "";

    @Field(Object)
    public company: RelatedCompany = { id: "", name: "" };

    @Field(String)
    public notes: string = "";

    constructor(data?: any) {
	super();
	this.mapData(data);
    }

}
