import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core'
import { FgResourceToolbarComponent } from './fg-resource-toolbar.component';

@NgModule({
    imports: [
        CommonModule,
        MatTabsModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatRippleModule
    ],
    exports: [
        FgResourceToolbarComponent
    ],
    declarations: [
        FgResourceToolbarComponent
    ],
    entryComponents: [],
    providers: []
})
export class FgResourceToolbarModule { }
