import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';

import {
    RelatedCompany
} from './company.model';

export interface ServiceCategory {
    id: string;
    code: string;
    name: string;
}

export interface ServiceType {
    id: string;
    code: string;
    name: string;
    description: string;
}

export interface Overage {
    serviceType: ServiceType,
    threshold: number
}

export class ServiceTypeModel extends ResourceModel {

    @Field(String)
    public name: string = "";

    @Field(String)
    public code: string = "";

    @Field(String)
    public description: string = "";

    @Field(Object)
    public company: RelatedCompany = { id: "", name: "" };

    @Field(Object)
    public category: ServiceCategory = { id: "", code: "", name: "" };

    @Field(Number)
    public unitPrice: number = 0;

    @Field(String)
    public unitType: string = "";

    @Field(Boolean)
    public applyAsCredit: boolean = false;

    @Field(Boolean)
    public taxLevel1: boolean = false;
    @Field(Boolean)
    public taxLevel2: boolean = false;
    @Field(Boolean)
    public taxLevel3: boolean = false;
    @Field(Boolean)
    public taxLevel4: boolean = false;
    @Field(Boolean)
    public taxLevel5: boolean = false;

    @Field(Array)
    public overages: Overage[] = [];

    @Field(Boolean)
    public isTaxCode: boolean = false;

    @Field(String)
    public notes: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }
}
