import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { CustomerSiteModel } from '../models';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerSiteService extends ResourceService<CustomerSiteModel> {
    protected servicePath: string = '/CustomerSite';

    public ModelType = CustomerSiteModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }
}
