import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-simple-modal',
    templateUrl: './simple-modal.component.html',
    styleUrls: ['./simple-modal.component.scss']
})
export class SimpleModalComponent {

    public title: string = "Yes/No";
    public description: string = "Do you wish to continue?";
    public descriptionLines: string[] = null;
    public actionButton: string = "Yes";
    public cancelButton: string = "No";

    constructor(
        protected dialog: MatDialogRef<SimpleModalComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: any,
    ) {
        this.title = data.title ? data.title : this.title;
        this.description = data.description ? data.description : this.description;
        this.descriptionLines = data.descriptionLines ? data.descriptionLines : null;
        this.actionButton = data.actionButton ? data.actionButton : this.actionButton;
        this.cancelButton = data.cancelButton ? data.cancelButton : this.cancelButton;
    }

    public closeDialog(action: boolean): void {
        this.dialog.close(action);
    }
}
