import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs/index';
import { FileUploadModel } from '../models/fileUpload.model';
import { ApiClientService, ServiceResponse } from './api-client.service';
import { ResourceService } from './resource.service';
import { flatMap, map } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class FileUploadService extends ResourceService<FileUploadModel> {
    protected servicePath: string = '/FileUpload';

    public ModelType = FileUploadModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    /**
     * Function used to upload files to S3 and create a FileUpload record in Dynamo.
     *
     * @param   {File}                              file          File to be uploaded
     * @param   {FileUploadModel}                   uploadRecord  FileUpload record that has relevant file information
     *
     * @return  {Observable<ServiceResponse>}                     Observable if uploading was successful or not.
     */
    public uploadFile(file: File, uploadRecord: FileUploadModel): Observable<ServiceResponse> {

        return this.requestUploadFileUrl(uploadRecord)
            .pipe(
                flatMap((response: ServiceResponse) => {
                    if (response.success) {
                        let url: string = response.data['url'];
                        return this.upload(file, url)
                            .pipe(
                                flatMap(() => {
                                    return of(response);
                                })
                            );

                    } else {
                        let res: ServiceResponse = {
                            success: false
                        };

                        return of(res);
                    }
                })
            );
    }

    /**
     * Function used to delete file from S3 and delete FileUpload record from Dynamo.
     *
     * @param   {FileUploadModel}                   uploadRecord  Instance of the FileUpload record with relevant file information.
     *
     * @return  {Observable<ServiceResponse>}                     Observable if deleting the file was successful or not.
     */
    public deleteFile(uploadRecord: FileUploadModel): Observable<ServiceResponse> {
        return this._apiClient.delete(
            this.serviceUrl + `delete-file/${uploadRecord.id}`, {}
        );
    }

    /**
     * Function used to download a specific file from S3.
     *
     * @param   {FileUploadModel}  uploadRecord  UploadFile record containing relevant information about the file.
     *
     * @return  {any}                            A file from S3.
     */
    public downloadFile(uploadRecord: FileUploadModel): any {
        return this.requestDownloadFileUrl(uploadRecord)
            .subscribe((response: ServiceResponse) => {
                if (response.success) {
                    let url: string = response.data['url'];
                    return this.download(url)
                        .subscribe((data: any) => {
                            saveAs(data, uploadRecord.fileName);
                        })
                } else {
                    let res: ServiceResponse = {
                        success: false
                    };

                    return of(res);
                }
            });
    }

    public downloadFileAsDataUrl(uploadRecord: FileUploadModel): Promise<any> {
        return new Promise((resolve, reject) => {
            this.requestDownloadFileUrl(uploadRecord)
                .subscribe((response: ServiceResponse) => {
                    if (response.success) {
                        let url: string = response.data['url'];
                        this.download(url)
                            .subscribe((data: any) => {
                                this.convertBlobToBase64DataUrl(data)
                                    .then((dataUrl: any) => {
                                        resolve(dataUrl)
                                    });
                            })
                    } else {
                        let res: ServiceResponse = {
                            success: false
                        };

                        reject(of(res));
                    }
                })
        })

    }

    /**
     * Gets an S3 URL that will be used to upload the file.
     *
     * @param   {FileUploadModel}                   uploadRecord  UploadFile record that has relevant file information.
     *
     * @return  {Observable<ServiceResponse>}                     Response containing the S3 file URL for uploading.
     */
    private requestUploadFileUrl(uploadRecord: FileUploadModel): Observable<ServiceResponse> {
        return this._apiClient.request(
            'post',
            this.serviceUrl + "upload",
            {
                observe: 'response',
                body: uploadRecord
            }
        );
    }

    /**
     * Returns the S3 file URL for downloading
     *
     * @param   {FileUploadModel}                   uploadRecord  UploadFile record that has relevant file information.
     *
     * @return  {Observable<ServiceResponse>}                     Response containing the S3 file URL for downloading.
     */
    private requestDownloadFileUrl(uploadRecord: FileUploadModel): Observable<ServiceResponse> {
        return this._apiClient.request(
            'post',
            this.serviceUrl + `download/${uploadRecord.id}`,
            {
                observe: 'response'
            }
        );
    }

    /**
     * Helper function used to upload the file to S3.
     *
     * @param   {File}                         file  The file to be uploaded
     * @param   {string}                       url   The S3 url that will be used to upload to.
     *
     * @return  {Observable<ServiceResponse>}        Response that tells is if it is successful or not.
     */
    private upload(file: File, url: string): Observable<ServiceResponse> {
        return this.http.put(
            url,
            file,
            {
                headers: {
                    "Content-Type": file.type
                }
            }
        ).pipe(map<any, ServiceResponse>(
            (response: any) => {
                return {
                    success: true,
                    data: response
                }
            }, (error: any) => {
                return {
                    success: false,
                    data: error
                }
            }
        ));
    }

    /**
     * Helper function used to download a specific file.
     *
     * @param   {string}           url  S3 url to download from.
     *
     * @return  {Observable<any>}       Returns file as a blob for downloading.
     */
    private download(url: string): Observable<any> {
        return this.http.get(
            url,
            {
                responseType: 'blob'
            }
        );
    }

    private convertBlobToBase64DataUrl(blob: any): Promise<any> {
        return new Promise((resolve) => {
            var reader: FileReader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob)
        })
    }

     /**
     * Helper function for getting all applicable images to add to invoice generation.
     *
     * @param   {string[]}       orderIds  The order ids related to the selected invoices.
     *
     * @return  {Promise<any>}             Promise completion saying that all images have been gathered.
     */
     public getImagesForInvoices(orderIds: string[]): Promise<any> {
        let images: ImageModel[] = [];

        return new Promise((resolve) => {
            let query: any = {
                query: {
                    bool: {
                        must: [
                            {
                                bool: {
                                    should: [

                                    ]
                                }
                            },
                            {
                                match: {
                                    entityTable: "Order"
                                }
                            },
                            {
                                match: {
                                    includeWithInvoice: true
                                }
                            },
                            {
                                match: {
                                    visible: true
                                }
                            }
                        ]
                    }
                }
            };

            for (let orderId of orderIds) {
                query.query.bool.must[0].bool.should.push({
                    term: {
                        entityId: orderId
                    }
                })
            }

            this.list({
                allowCache: false,
                where: query
            }).subscribe((results: FileUploadModel[]) => {

                // If no results, just return empty array
                if (results.length == 0) {
                    resolve([]);
                    return;
                }

                let dependentQuery: any = {
                    query: {
                        bool: {
                            should: []
                        }
                    }
                };

                for (let file of results) {
                    dependentQuery.query.bool.should.push({
                        term: {
                            parentId: file.id
                        }
                    });
                }

                this.list({
                    allowCache: false,
                    where: dependentQuery
                }).subscribe((depResults: FileUploadModel[]) => {
                    // No dependent records found.
                    if (depResults.length == 0) {
                        resolve([]);
                        return;
                    }

                    let promises: Promise<any>[] = [];

                    depResults.forEach((depFile) => {
                        promises.push(this.downloadFileAsDataUrl(depFile).then((data: any) => {
                            images.push({
                                orderId: results.find(x => x.id == depFile.parentId).entityId,
                                dataUrl: data
                            });
                        }));
                    })

                    Promise.all(promises).then(() => {
                        resolve(images);
                    })
                })
            })
        })
    }

}

export interface ImageModel {
    orderId: string,
    dataUrl: string
}

