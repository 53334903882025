import {
    Field
} from '@smartsoftware/reflex-core';
import { OrderModel } from './order.model';

import {
    ResourceModel
} from './resource.model';
import { RelatedCompany } from './company.model';

export enum ScheduleType {
    Custom = "Custom",
    Service = "Service"
}

export class ScheduleModel extends ResourceModel {

    @Field(String)
    public companyId: string | null = null;

    @Field(String)
    public scheduleType: ScheduleType | null = null

    @Field(String)
    public scheduleName: string | null = null;

    @Field(String)
    public orderId: string | null = null;

    @Field(Date)
    public startTime: Date | null = null;

    @Field(Date)
    public endTime: Date | null = null;

    @Field(Number)
    public totalHours: number = 0;

    @Field(String)
    public driverId: string | null = null;

    @Field(String)
    public cssHeight: string = "";

    public order: OrderModel | null = null;

    public showHoverDiv: boolean = false;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
