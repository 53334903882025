import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { Auth } from './auth.service';
import { AuthResourceService } from '../../resource-module/services/auth-resource.service';
import { ServiceResponse } from '../../resource-module/services/api-client.service';
import { ProfileService } from '../../user-profile/services';
import { map } from 'rxjs/operators';

import { Subscription, Observable } from 'rxjs';

import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UserModel } from '../../resource-module/models';

@Injectable({
    providedIn: 'root'
})
export class RoleAuthGuardService implements CanActivate {

    protected _isAuthenticated: Promise<boolean>;

    protected _dialogReference: MatDialogRef<any>;
	protected _dialogSubscription: Subscription;

	constructor(
		protected _auth : Auth,
        protected _authResourceService: AuthResourceService,
        protected _profileService: ProfileService,
        protected _matDialog: MatDialog,
        protected _route: ActivatedRoute,
		protected router: Router
	) {
        this._auth.credentialsUpdatedEvent.subscribe((newCreds)=> {
            if(newCreds) {
                this._isAuthenticated = this.authCheck();
            } else {
                this._isAuthenticated = Promise.resolve(false);
            }
        })
    }
    
    private checkForNoLogin(route: ActivatedRouteSnapshot) : boolean {
        if(route.data.noLogin)
            return true;
        for(let i = 0; i < route.children.length; ++i) {
            if(this.checkForNoLogin(route.children[i]))
                return true;
        }

        return false;
    }

	public canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        
        // For routes within parents that have an auth guard but don't themselves need a login
        if (this.checkForNoLogin(route)) {
            return new Promise((resolve, reject) => {
                resolve(true);
            })
        }

        return this._isAuthenticated.then((isAuth: boolean) => {
            if(!isAuth) {
                this.router.navigate([''], {skipLocationChange: true});
            }
            let user = this._profileService.userModel;
            if(!user) {
                let obj = JSON.parse(window.sessionStorage.getItem('user'));
                if(obj.roleData) {
                    user = new UserModel(obj);
                    user.companyData = obj.companyData;
                    user.roleData = obj.roleData;
                }
            }

            if(this._profileService.userModel?.roleData?.isSuperAdmin)
                return true;

            if(!this.validatePath(route, this._profileService.userModel?.roleData)) {
                this.router.navigate(['/dashboard']);
            }
            return isAuth;
        });
    }
    
    public validatePath(route: ActivatedRouteSnapshot, role: any, permissionType: string = "view") {
        if(!role)
            return false;

        if(role.isSuperAdmin)
            return true;

        let path: string = this.getResolvedUrl(route);
        if(role?.permissions[path]?.[permissionType])
            return true
        return false;

    }

    public validatePathString(path: string, role: any, permissionType: string = "view") {
        if(!role)
            return false;

        if(role.isSuperAdmin)
            return true;

        if(role?.permissions[path]?.[permissionType])
            return true
        return false;

    }

    private getResolvedUrl(route: ActivatedRouteSnapshot): string {
        let url: string = '';
        let r = route;
        while(r) {
            // Get rid of params as they're not part of the base route
            if(r.url[0] && Object.keys(r.params).length == 0)
                url = '/' + r.url[0].path + url;
            r = r.parent;
        }
        return url;
    }

    protected authCheck(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this._authResourceService.getCurrentUser().pipe(
                map((response: ServiceResponse) => {
                    if(!response.success) {
                        this._authResourceService.refresh().subscribe((response) => {
                            resolve(response.success);
                        })
                    } else {
                        resolve(true)
                    }
                    return response;
                })
            ).subscribe((response: ServiceResponse) => {})
        })
    }
}
