import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

import { Company } from './companyStatement.model';
import { User } from './driverActivityLog.model';
import { DefaultTruck } from './user.model';

export interface MaintenanceType {
    id: string,
    name: string
}

export interface Inspection {
    id: string,
    code: string
}

export class TruckMaintenanceLogModel extends ResourceModel {

    @Field(Object)
    public company: Company = { id: "", name: "" };

    @Field(Object)
    public truck: DefaultTruck = { name: "", id: "" };

    @Field(Object)
    public inspection: Inspection = { code: "", id: "" };

    @Field(Date)
    public date: Date | null = null;

    @Field(Object)
    public type: MaintenanceType = { name: "", id: "" };

    @Field(Object)
    public createdBy: User = { name: "", id: "" };

    @Field(String)
    public notes: string = "";

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
