import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

export class MessageModel extends ResourceModel {

    @Field(String)
    public text: string = "";

    @Field(String)
    public entityId: string = "";

    @Field(String)
    public entityType: string = "";

    @Field(String)
    public authorId: string = "";

    @Field(String)
    public authorName: string = "";

    @Field(Date)
    public messageTime: Date = new Date();

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
