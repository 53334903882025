import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { BinTypeModel } from '../models/binType.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BinTypeService extends ResourceService<BinTypeModel> {
    protected servicePath: string = '/BinType';

    public ModelType = BinTypeModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public flatType(type: BinTypeModel): any {
        return {
            id: type.id,
            name: type.name,
            code: type.code
        }
    }

    public getBinTypes(companyId: string = null, binTypeName: string = null): Observable<BinTypeModel[]> {
        let where: any = {
            query: {
                bool: {
                    must: [],
                    must_not: [
                        {
                            exists: {
                                field: "deletedAt"
                            }
                        }
                    ]
                }
            },
            size: 1000
        };

        if (companyId) {
            where.query.bool.must.push({
                nested: {
                    path: "company",
                    query: {
                        term: {
                            "company.id": companyId
                        }
                    }
                }
            });
        }

        if (binTypeName) {
            let binTypeArray: string[] = binTypeName.replace(/[^a-zA-Z0-9 ]/g, "").split(" ");

            binTypeArray.forEach((binType: string) => {
                where.query.bool.must.push({
                    wildcard: {
                        "name": `*${binType}*`
                    }
                });
            });
        }

        return this.list({
            allowCache: false,
            where: where
        }).pipe(
            map(
                (results: BinTypeModel[]) => results.sort((a, b) => a.name.localeCompare(b.name))
            )
        );
    }
}
