import { Amplify, Geo } from 'aws-amplify';
import { Injectable } from '@angular/core';
import { ReflexEnvironment as Environment } from '@smartsoftware/reflex-core';
import * as AWS from 'aws-sdk';

@Injectable({
    providedIn: 'root'
})
export class AwsLocationService {

    private awsCredentials: AWS.CognitoIdentityCredentials;

    constructor() {
        this.loadCredentials();
    }

    private async loadCredentials() {
        Amplify.configure(Environment.config['amplify']);
        this.awsCredentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: Environment.config['amplify']["Auth"]["identityPoolId"],
        });
        AWS.config.region = Environment.config['amplify']["Auth"]["identityPoolId"].split(':')[0];
        AWS.config.credentials = this.awsCredentials;
        await this.awsCredentials.getPromise();
    }

    async suggestPois(term) {
        const location = new AWS.Location({
            region: Environment.config['amplify']["Auth"]["identityPoolId"].split(":")[0],
            credentials: this.awsCredentials
        });
        const results = await location.searchPlaceIndexForSuggestions({
            IndexName: Environment.config['amplify']["geo"]["AmazonLocationService"]["search_indices"]["default"], // required
            Text: term // required
        }).promise();
        return results.Results;
    }


}
