import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

export class LEEDReportModel extends ResourceModel {

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
