import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MessageService, MessageModel } from 'src/app/modules/resource-module/';
import { ProfileService } from 'src/app/modules';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
    selector: 'message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent {

    public messages: MessageModel[] = [];

    @Input() entityId: string = '';
    @Input() entityType: string = '';

    public messageForm: FormGroup = new FormGroup({
		message: new FormControl('', [Validators.required])
	});

    constructor(
        protected _messageService: MessageService,
        protected _profileService: ProfileService
    ) {
        
    }

    ngOnInit() {
        this._messageService.getEntityMessages(this.entityId).subscribe((results) => {
            this.messages = results.results;
        })
    }

    public ownMessage(message: MessageModel) {
        return message.authorId == this._profileService.userModel.id;
    }

    public sendMessage() {
        if(this.messageForm.valid) {
            this._messageService.sendMessage(this.messageForm.value['message'], this.entityId, this.entityType)
            .subscribe((result) => {
                if(result) {
                    this.messageForm.patchValue({
                        message: ''
                    });
                    this.messages.unshift(result);
                }
            })
        }
    }
}
