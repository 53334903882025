import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { FuelLocationModel } from '../models/';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FuelLocationService extends ResourceService<FuelLocationModel> {
    protected servicePath: string = '/FuelLocation';

    public ModelType = FuelLocationModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    /**
     * Gets fuel locations based on company id and location name
     *
     * @param   {string}                           companyId     The company id
     * @param   {string}                           locationName  Location name
     *
     * @return  {Observable<FuelLocationModel>[]}                List of fuel locations.
     */
    public getFuelLocations(companyId: string = null, locationName: string = null): Observable<FuelLocationModel[]> {
        let where: any = {
            query: {
                bool: {
                    must: [],
                    must_not: [
                        {
                            exists: {
                                field: "deletedAt"
                            }
                        }
                    ]
                }
            },
            size: 1000
        }

        if (companyId) {
            where.query.bool.must.push({
                nested: {
                    path: "company",
                    query: {
                        term: {
                            "company.id": companyId
                        }
                    }
                }
            });
        }

        if (locationName) {
            let locationArr: string[] = locationName.replace(/[^a-zA-Z0-9 ]/g, "").split(" ");

            locationArr.forEach((name: string) => {
                where.query.bool.must.push({
                    wildcard: {
                        "name.search": `*${name}*`
                    }
                });
            });
        }

        return this.list({
            allowCache: false,
            where: where
        }).pipe(
            map(
                (results: FuelLocationModel[]) => results.sort((a, b) => a.name.localeCompare(b.name))
            )
        );
    }

}
