import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';

export interface Company {
    id: string;
    name: string;
}

export interface SubscriptionTierStatement {
    id: string;
    name: string;
}

export class CompanyStatementModel extends ResourceModel {

    @Field(Object)
    public company: Company = { id: "", name: "" };

    @Field(Object)
    public subscriptionTier: SubscriptionTierStatement = { id: "", name: "" };

    @Field(Number)
    public amount: number = 0.00;

    @Field(Date)
    public statementPeriodStart: Date | null = null;

    @Field(Date)
    public statementPeriodEnd: Date | null = null;

    @Field(Date)
    public issued: Date | null = null;

    @Field(Date)
    public due: Date | null = null;

    @Field(Date)
    public paidAt: Date | null = null;

    @Field(String)
    public displayStatus: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }
}
