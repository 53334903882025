import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';

export class CustomerUserModel extends ResourceModel {

    @Field(String)
    public customerId: string = "";

    @Field(String)
    public userId: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
