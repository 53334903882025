import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';

export class FuelCostHistoryModel extends ResourceModel {

    @Field(String)
    public state: string | null = null;

    @Field(String)
    public city: string | null = null;

    @Field(String)
    public zip: string | null = null;

    @Field(Number)
    public regularAvg: number | null = null;

    @Field(Number)
    public midGradeAvg: number | null = null;

    @Field(Number)
    public premiumAvg: number | null = null;

    @Field(Number)
    public dieselAvg: number | null = null;

    @Field(Date)
    public lookupDate: Date | null = null;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
