import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { ServiceCategoryModel } from '../models/serviceCategory.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ServiceCategoryService extends ResourceService<ServiceCategoryModel> {
    protected servicePath: string = '/ServiceCategory';

    public ModelType = ServiceCategoryModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public flatCategory(cat: ServiceCategoryModel): any {
        return {
            id: cat.id,
            name: cat.name,
            code: cat.code
        }
    }
}
