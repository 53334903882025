import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { BillingCycleModel } from '../models';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';

@Injectable({
    providedIn: 'root'
})
export class BillingCycleService extends ResourceService<BillingCycleModel> {
    protected servicePath: string = '/BillingCycle';

    public ModelType = BillingCycleModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public flatCycle(cycle: BillingCycleModel): any {
        return {
            id: cycle.id,
            name: cycle.name
        };
    }
}
