import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

import { Company } from './companyStatement.model';

export class TruckModel extends ResourceModel {

    @Field(String)
    public name: string = "";

    @Field(String)
    public licenseNumber: string = "";

    @Field(String)
    public vin: string = "";

    @Field(Number)
    public capacity: number = 0;

    @Field(String)
    public type: string = "";

    @Field(Object)
    public company: Company = { id: "", name: "" };

    @Field(String)
    public truckStatus: string = "";

    @Field(Object)
    public site: any = { id: "", name: "" };

    @Field(Date)
    public lastInspection: Date | null = null;

    @Field(Date)
    public nextInspection: Date | null = null;

    @Field(Date)
    public lastMaintenance: Date | null = null;
    
    @Field(Date)
    public nextMaintenance: Date | null = null;

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
