import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Auth } from './auth.service';
import { AuthResourceService } from '../../resource-module/services/auth-resource.service';
import { ServiceResponse } from '../../resource-module/services/api-client.service';
import { map } from 'rxjs/operators';

import { Subscription } from 'rxjs';

import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    protected _isAuthenticated: Promise<boolean>;

    protected _dialogReference: MatDialogRef<any>;
	protected _dialogSubscription: Subscription;

	constructor(
		protected _auth : Auth,
        protected _authResourceService: AuthResourceService,
        protected _matDialog: MatDialog,
		protected router: Router
	) {
        this._auth.credentialsUpdatedEvent.subscribe((newCreds)=> {
            if(newCreds) {
                this._isAuthenticated = this.authCheck();
            } else {
                this._isAuthenticated = Promise.resolve(false);
            }
        })
    }
    
    private checkForNoLogin(route: ActivatedRouteSnapshot) : boolean {
        if(route.data.noLogin)
            return true;
        for(let i = 0; i < route.children.length; ++i) {
            if(this.checkForNoLogin(route.children[i]))
                return true;
        }

        return false;
    }

	public canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        // For routes within parents that have an auth guard but don't themselves need a login
        if (this.checkForNoLogin(route)) {
            return new Promise((resolve, reject) => {
                resolve(true);
            })
        }

        return this._isAuthenticated.then((isAuth: boolean) => {
            if(!isAuth && route.routeConfig.path != "") {
                this.router.navigate(['']);
            } else if (isAuth && route.routeConfig.path == "") {
                this.router.navigate(['/dashboard']);
            } else if (!isAuth && route.routeConfig.path == "") {
                return Promise.resolve(true);
            }
            return isAuth;
        });
	}

    protected authCheck(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this._authResourceService.getCurrentUser().pipe(
                map((response: ServiceResponse) => {
                    if(!response.success) {
                        this._authResourceService.refresh().subscribe((response) => {
                            resolve(response.success);
                        })
                    } else {
                        resolve(true)
                    }
                    return response;
                })
            ).subscribe((response: ServiceResponse) => {})
        })
    }
}
