import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs/index';
import { Financial, CustomerModel } from '../../resource-module/models';
import { ApiClientService, ServiceResponse } from './api-client.service';
import { ResourceService } from './resource.service';
import { ResourceListOptions, SearchResponse } from '@smartsoftware/reflex-core';
import { flatMap, map } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class CustomerService extends ResourceService<CustomerModel> {
    protected servicePath: string = '/Customer';
    public ModelType = CustomerModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public flatCustomer(customer: CustomerModel): any {
        return {
            id: customer.id,
            name: customer.name
        };
    }

    public uploadFile(file: File, filename: string, user: CustomerModel): Observable<ServiceResponse> {

        return this.requestFileUrl(file, filename, String(user.id), "upload")
            .pipe(
                flatMap((response: ServiceResponse) => {
                    if (response.success) {
                        let url: string = response.data['url'];
                        return this.upload(file, url);

                    } else {
                        let res: ServiceResponse = {
                            success: false
                        };

                        return of(res);
                    }
                })
            );
    }

    public deleteFile(filename: string, customer: CustomerModel): Observable<ServiceResponse> {
        return this._apiClient.delete(
            this.serviceUrl + "upload/" + customer.id + "/" + filename, {}
        );
    }

    public downloadFile(filename: string, fileType: string, id: string, customer: CustomerModel): any {
        return this.requestFileUrl(fileType, id, String(customer.id), "download")
            .subscribe((response: ServiceResponse) => {
                if (response.success) {
                    let url: string = response.data['url'];
                    return this.download(url)
                        .subscribe((data: any) => {
                            saveAs(data, filename);
                        })
                } else {
                    let res: ServiceResponse = {
                        success: false
                    };

                    return of(res);
                }
            });
    }

    public requestFileUrl(file: File | string, filename: string, id: string, uploadType: string): Observable<ServiceResponse> {

        let contentType: string = (typeof file === "object") ? file.type : file;
        return this._apiClient.request(
            'post',
            this.serviceUrl + uploadType + "/" + id,
            {
                observe: 'response',
                body: {
                    filename: filename,
                    contentType: contentType
                }
            }
        );
    }

    public upload(file: File, url: string): Observable<ServiceResponse> {
        return this.http.put(
            url,
            file,
            {
                headers: {
                    "Content-Type": file.type
                }
            }
        ).pipe(map<any, ServiceResponse>(
            (response: any) => {
                return {
                    success: true,
                    data: response
                }
            }, (error: any) => {
                return {
                    success: false,
                    data: error
                }
            }
        ));
    }

    public download(url: string): Observable<any> {
        return this.http.get(
            url,
            {
                responseType: 'blob'
            }
        );
    }

    public getStatus(customer: CustomerModel, activeStatus: string): string {
        let date: Date = new Date(customer?.financial?.lastChargedDate);
        let now: Date = new Date();

        if (customer.financial) {
            if (this.sumOfLateCharges(customer?.financial) > 0 && now.getTime() > date.getTime()) {
                return "Overdue"
            } else if (this.sumOfLateCharges(customer?.financial) > 0 && now.getTime() <= date.getTime()) {
                return "Outstanding"
            }
        }

        return activeStatus;
    }

    private sumOfLateCharges(financial: Financial): number {
        return financial.lateTotalThirtyDays + financial.lateTotalSixtyDays
            + financial.lateTotalNinetyDays + financial.lateTotalOneTwentyDays
            + financial.lateChargeAmount;
    }

    /**
     * Gets list of Customers
     *
     * @param   {string}                       companyId     The Company for Customers, if applicable
     * @param   {string<CustomerModel>[]}      customerName  The Customer Name to look up
     *
     * @return  {Observable<CustomerModel>[]}                List of Customers.
     */
    public getCustomers(companyId: string = null, customerName: string = null): Observable<CustomerModel[]> {
        let where: any = {
            query: {
                bool: {
                    must: [],
                    must_not: [
                        {
                            exists: {
                                field: "deletedAt"
                            }
                        }
                    ]
                }
            },
            size: 1000
        }

        if (companyId) {
            where.query.bool.must.push({
                nested: {
                    path: "company",
                    query: {
                        term: {
                            "company.id": companyId
                        }
                    }
                }
            });
        }

        if (customerName) {
            let customerArray: string[] = customerName.replace(/[^a-zA-Z0-9 ]/g, "").split(" ");

            customerArray.forEach((name: string) => {
                where.query.bool.must.push({
                    wildcard: {
                        "name.search": `*${name}*`
                    }
                });
            });
        }

        return this.list({
            allowCache: false,
            where: where
        }).pipe(
            map(
                (results: CustomerModel[]) => results.sort((a, b) => a.name.localeCompare(b.name))
            )
        );
    }
}
