import { Injectable } from '@angular/core';
import { ApiClientService } from '../modules/resource-module/services/api-client.service';
import { Observable } from 'rxjs';
import { BaseService } from '@smartsoftware/reflex-core';

import { HttpClient } from '@angular/common/http';

import {
    PaymentIntent, StripeElementsOptions, Appearance, StripeElements,
    loadStripe, Stripe
} from '@stripe/stripe-js';

@Injectable({
    providedIn: 'root'
})
export class StripePaymentsService extends BaseService {

    protected servicePath: string = "/Stripe";
    public elements: StripeElements;
    protected stripe: Stripe;
    private appearance: Appearance = {
        theme: 'stripe'
    };

    constructor(
        protected httpClient: HttpClient,
        private _apiClient: ApiClientService,
    ) {
        super(httpClient);
    }

    public getPaymentIntent(amount: number): Observable<PaymentIntent> {
        return this._apiClient.request(
            'post',
            this.serviceUrl + "create-charge",
            {
                observe: 'response',
                body: {
                    amount: amount
                }
            }
        );
    }

    public cancelPaymentIntent(paymentIntentId: string): Observable<PaymentIntent> {
        console.log(paymentIntentId);
        return this._apiClient.request(
            'post',
            this.serviceUrl + "cancel-charge",
            {
                observe: 'response',
                body: {
                    paymentIntentId: paymentIntentId
                }
            }
        );
    }

    public async initStripe(clientSecret: string): Promise<void> {
        this.stripe = await loadStripe('pk_test_51KeOEGFEfNPaCfJPwrJ5ybxjkQeIilJBrfv6kp2GQPWFtuuU7V4kvh6i2DifdNGS0TixYKhRst0DlVohuQHeoPfI00uOiv75Sf');
        this.elements = this.stripe.elements({ appearance: this.appearance, clientSecret: clientSecret });
    }

    public async confirmPayment(): Promise<any> {
        let response = await this.stripe.confirmPayment({
            elements: this.elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: "",
            },
            redirect: 'if_required'
        });
        return response;
    }
}
