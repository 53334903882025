import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { TripModel } from '../models/trip.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TripService extends ResourceService<TripModel> {
    protected servicePath: string = '/Trip';

    public ModelType = TripModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }
    
    
    public getOrderTrips(orderId: string) {
        return this._apiClient
            .request
            (
            'post',
            this.serviceUrl + 'order-trips',
            {
                observe: 'response',
                body: {orderId: orderId}
            }
            )
            .pipe(
                map<any, TripModel[]>(
                    (response) => {
                        return response ? response : undefined
                    }
                )
            );
    }

    public getRecentTripsForTruck(truckId: string) {
        return this._apiClient
            .get
            (
            this.serviceUrl + 'get-recent-trips/' + truckId,
            {
                observe: 'response'
            }
            )
            .pipe(
                map<any, TripModel[]>(
                    (response) => {
                        return response ? response : undefined
                    }
                )
            );
    }
}
