import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { RoleModel } from '../models/role.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RoleService extends ResourceService<RoleModel> {
    protected servicePath: string = '/Role';

    public ModelType = RoleModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public rolesForCompany(companyId: string, isCustomer: boolean = false) {
        let query: any = {
            "query": {
                "bool": {
                    "must": [
                        {
                            "nested": {
                                "path": "company",
                                "query": {
                                    "term": {
                                        "company.id": companyId
                                    }
                                }
                            }
                        }
                    ]
                }
            }
        }

        if (isCustomer) {
            query.query.bool.must.push({
                match: {
                    isCustomer: true
                }
            })
        }

        return this.search(query);
    }

    public flatRole(role: RoleModel): any {
        return {
            id: role.id,
            name: role.name,
            isAdmin: role.isAdmin,
            isDriver: role.isDriver,
            isMechanic: role.isMechanic,
            isCustomer: role.isCustomer,
            isSuperAdmin: role.isSuperAdmin
        }
    }

    public getRoles(companyId: string = null): Observable<RoleModel[]> {
        let where: any = {
            query: {
                bool: {
                    must: [],
                    must_not: [
                        {
                            exists: {
                                field: "deletedAt"
                            }
                        }
                    ]
                }
            },
            size: 1000
        }

        if (companyId) {
            where.query.bool.must.push({
                nested: {
                    path: "company",
                    query: {
                        term: {
                            "company.id": companyId
                        }
                    }
                }
            });
        }


        return this.list({
            allowCache: false,
            where: where
        }).pipe(
            map(
                (results: RoleModel[]) => results.sort((a, b) => a.name.localeCompare(b.name))
            )
        );
    }
}
