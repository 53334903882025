import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs/index';
import { flatMap, map } from 'rxjs/operators';

import { LEEDReportModel } from '../../resource-module/models';
import { ApiClientService, ServiceResponse } from './api-client.service';
import { ResourceService } from './resource.service';

@Injectable({
    providedIn: 'root'
})
export class LEEDReportService extends ResourceService<LEEDReportModel> {
    protected servicePath: string = '/LEEDReport';

    public ModelType = LEEDReportModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public uploadReport(file: File, filename: string, customerId: string): Observable<ServiceResponse> {

        return this.requestUploadUrl(file, filename, customerId)
            .pipe(
                flatMap((response: ServiceResponse) => {
                    if (response.success) {
                        let url: string = response.data['url'];
                        let path = ["content", "leedReports", customerId, filename].join('/');
                        return this.upload(file, url, filename, path);

                    } else {
                        let res: ServiceResponse = {
                            success: false
                        };

                        return of(res);
                    }
                })
            );
    }

    public deleteReport(filename: string, customerId: string): Observable<ServiceResponse> {
        return this._apiClient.delete(
            this.serviceUrl + "deleteReport/" + customerId + "/" + filename, {}
        );
    }

    public requestUploadUrl(file: File, filename: string, customerId: string): Observable<ServiceResponse> {
        return this._apiClient.request(
            'post',
            this.serviceUrl + "getReportUrl",
            {
                observe: 'response',
                body: {
                    filename: filename,
                    contentType: file.type,
                    customerId: customerId,
                }
            }
        );
    }

    public upload(file: File, url: string, filename: string, path: string): Observable<ServiceResponse> {
        return this.http.put(
            url,
            file,
            {
                headers: {
                    "Content-Type": file.type
                }
            }
        ).pipe(map<any, ServiceResponse>(
            (response: any) => {
                return {
                    success: true,
                    data: response,
                    filename: filename,
                    path: path
                }
            }, (error: any) => {
                return {
                    success: false,
                    data: error,
                    filename: filename,
                    path: path
                }
            }
        ));
    }

    public sendReport(email: string, attachments: any[]): Observable<any> {
        return this._apiClient
            .request
            (
            'POST',
            this.serviceUrl + 'sendReport',
            {
                observe: 'response',
                body: {
                    ToAddresses: [email],
                    Message: `<style>p{ white-space: pre-wrap;}</style>
                    <p>Your Recycled Materials Summary Report is attached</p>`,
                    Subject: `Recycled Materials Summary Report`,
                    Attachments: attachments
                }
            }
            );
    }

}
