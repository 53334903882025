import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

import { Company } from './companyStatement.model';

export interface Landfill {
    id: string;
    name: string;
    code: string;
}

export interface DiversionRate {
    material: DiversionMaterial;
    rate: number;
}

export interface DiversionMaterial {
    id: string,
    name: string
}

export class LandfillModel extends ResourceModel {

    @Field(String)
    public code: string = "";

    @Field(String)
    public name: string = "";

    @Field(Object)
    public company: Company = { id: "", name: "" };

    @Field(String)
    public measureType: string = "";

    @Field(String)
    public unitMeasure: string = "";

    @Field(Object)
    public paginatorOptions: any = {};

    @Field(Array)
    public diversionRates: DiversionRate[] = []

    @Field(String)
    public notes: string = "";

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
