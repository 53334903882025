import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { ServiceTypeModel } from '../models/serviceType.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ServiceTypeService extends ResourceService<ServiceTypeModel> {
    protected servicePath: string = '/ServiceType';

    public ModelType = ServiceTypeModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public flatService(service: ServiceTypeModel): any {
        return {
            id: service.uuid,
            name: service.name,
            code: service.code,
            description: service.description
        };
    }

    /**
     * Gets list of Service Types
     *
     * @param   {string}                       companyId        The Company for Service Types, if applicable
     * @param   {string<ServiceTypeModel>[]}      serviceTypeCode  The Service Type Code to look up
     *
     * @return  {Observable<ServiceTypeModel>[]}                   List of Service Types.
     */
    public getServiceTypes(companyId: string = null, serviceTypeCode: string = null): Observable<ServiceTypeModel[]> {
        let where: any = {
            query: {
                bool: {
                    must: [],
                    must_not: [
                        {
                            exists: {
                                field: "deletedAt"
                            }
                        }
                    ]
                }
            },
            size: 1000
        }

        if (companyId) {
            where.query.bool.must.push({
                nested: {
                    path: "company",
                    query: {
                        term: {
                            "company.id": companyId
                        }
                    }
                }
            });
        }

        if (serviceTypeCode) {
            let serviceTypeArr: string[] = serviceTypeCode.replace(/[^a-zA-Z0-9 ]/g, "").split(" ");

            serviceTypeArr.forEach((serviceTypeCode: string) => {
                where.query.bool.must.push({
                    wildcard: {
                        "code.search": `*${serviceTypeCode}*`
                    }
                });
            });
        }

        return this.list({
            allowCache: false,
            where: where
        }).pipe(
            map(
                (results: ServiceTypeModel[]) => results.sort((a, b) => a.name.localeCompare(b.name))
            )
        );
    }
}
