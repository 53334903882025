import { Field } from '@smartsoftware/reflex-core';
import { ResourceModel } from './resource.model';
import { RelatedCompany } from './company.model';
import { Zone } from './bin.model';
import { Role } from './role.model';
import { BehaviorSubject } from 'rxjs';
import { ScheduleModel } from './schedule.model';
import { User } from './driverActivityLog.model';

export interface Billing {
    address: string
    fax: string
}

export interface MasterUserAccount {
    id: string,
    name: string
}

// export interface Address {
//     street: string,
//     city: string,
//     state: string,
//     zip: string
// }

export interface BillingControl {
    billingCycle: BillingCycle,
    taxProfiles: TaxProfile[],
    startLateCharges: number,
    startLateNotice: number,
    isElectronicFundsTransfer: boolean,
    isContractRequired: boolean,
    requiresFinancialApproval: boolean,
    requiresOperationsApproval: boolean,
    isDigitalStatement: boolean
}

export interface BillingCycle {
    id: string,
    name: string
}

export interface TaxProfile {
    id: string,
    name: string
}

export interface Financial {
    unbilledAmount: number,
    currentAmount: number,
    lateTotalThirtyDays: number,
    lateTotalSixtyDays: number,
    lateTotalNinetyDays: number,
    lateTotalOneTwentyDays: number
    lateChargeAmount: number,
    balanceAmount: number,
    lastPaidAt: Date,
    lastPaidAmount: number,
    lastLateChargeAmount: number,
    computedOn: number,
    lastChargedDate: Date,
    lastNotice: string,
    lastNoticeDate: Date,
    purchaseOrder: number,
    purchaseOrderExpires: Date,
    creditLimitAmount: number,
    depositAmount: number,
    depositDate: Date,
}

export interface UploadFile {
    id: string,
    fileName: string,
    contentType: string
}

export class CustomerModel extends ResourceModel {

    @Field(String)
    public name: string = "";

    @Field(String)
    public phone: string = "";

    @Field(String)
    public email: string = "";

    @Field(Object)
    public address: string = "";

    @Field(Object)
    public company: RelatedCompany = { id: "", name: "" };

    // Depreciated 20220909 GG
    // @Field(Object)
    // public masterAccount: MasterUserAccount | null = null;

    @Field(Object)
    public billing: Billing | null = null;

    @Field(Object)
    public billingControl: BillingControl | null = null;

    @Field(Object)
    public financial: Financial | null = null;

    @Field(Boolean)
    public customerHasLateCharges: boolean = false;

    @Field(String)
    public activeStatus: string = "";

    @Field(String)
    public displayStatus: string = "";

    // Non saved fields
    public customerStatus: string = null;

    @Field(Array)
    public files: UploadFile[] = [];

    @Field(Object)
    public salesRep: User | null = null;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }
}
