import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { MaterialModel } from '../models/material.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';

@Injectable({
    providedIn: 'root'
})
export class MaterialService extends ResourceService<MaterialModel> {
    protected servicePath: string = '/Material';

    public ModelType = MaterialModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public flatType(type: MaterialModel): any {
        return {
            id: type.id,
            name: type.name
        }
    }
}
