import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { OrderTemplateModel } from '../models';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OrderTemplateService extends ResourceService<OrderTemplateModel> {
    protected servicePath: string = '/OrderTemplate';

    public ModelType = OrderTemplateModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public getOrderTemplates(companyId: string = null, customerId: string = null): Observable<OrderTemplateModel[]> {
        let where: any = {
            query: {
                bool: {
                    must: [],
                    must_not: [
                        {
                            exists: {
                                field: "deletedAt"
                            }
                        }
                    ]
                }
            },
            size: 1000
        }

        if (companyId) {
            where.query.bool.must.push({
                nested: {
                    path: "company",
                    query: {
                        term: {
                            "company.id": companyId
                        }
                    }
                }
            });
        }

        if (customerId) {
            where.query.bool.must.push({
                nested: {
                    path: "customer",
                    query: {
                        term: {
                            "customer.id": customerId
                        }
                    }
                }
            });
        }

        return this.list({
            allowCache: false,
            where: where
        }).pipe(
            map(
                (results: OrderTemplateModel[]) => results.sort((a, b) => (a?.site?.site || '').localeCompare(b?.site?.site || ''))
            )
        );
    }
}
