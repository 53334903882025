import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { FuelCostHistoryModel, FuelLocationModel } from '../models/';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FuelCostHistoryService extends ResourceService<FuelCostHistoryModel> {
    protected servicePath: string = '/FuelCostHistory';

    public ModelType = FuelCostHistoryModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public getCostData(fuelLocation: FuelLocationModel): Observable<FuelCostHistoryModel[] | null> {
        if (fuelLocation.state == null) {
            return null;
        }

        let where: any = {
            query: {
                bool: {
                    must: [
                        {
                            term: {
                                state: fuelLocation.state
                            }
                        }
                    ]
                }
            },
            size: 2,
            sort: {
                lookupDate: {
                    order: "desc"
                }
            }
        }

        return this.list({
            allowCache: false,
            where: where
        }).pipe(
            map((results: FuelCostHistoryModel[]) => {
                if (results.length == 0) {
                    return null;
                }
                return results;
            })
        )
    }

}
