import { Component, OnDestroy, ViewChild, ElementRef, Inject } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
	UserService, ServiceResponse,
	UserModel, AuthResourceService
} from '../../../../../modules/resource-module';

import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'change-password-dialog',
	templateUrl: './change-password.dialog.html',
	styleUrls: ['./change-password.dialog.scss']
})
/**
 * Component to handle change password UI.
 *
 */
export class ChangePasswordDialog implements OnDestroy {

	protected _dialogSubscription: Subscription;

	// TODO temp, delete
	public message: string = '';

	public pending: boolean = false;
	public code: string = "";

    /**
     * Form group for change password.
     */
	public changePasswordForm: FormGroup = new FormGroup({
		oldPassword: new FormControl('', [Validators.required]),
		newPassword: new FormControl('', [Validators.required]),
		confirmNewPassword: new FormControl('', [Validators.required])
	});

	protected _subscriptions: Array<Subscription> = [];

	constructor(
		protected _dialogRef: MatDialogRef<any>,
		protected _userService: UserService,
		protected _authResourceService: AuthResourceService,
		protected _router: Router,
		protected _titleService: Title,
		@Inject(MAT_DIALOG_DATA) public data: any
		) {
			
	}

	ngOnDestroy() {
		this._subscriptions.forEach((subscription) => {
			subscription.unsubscribe();
		})
	}

	public toggleInputType(fieldId) {
        let elem = document.getElementById(fieldId);
        let newType = elem.getAttribute("type") === "password" ? "text" : "password";
        elem.setAttribute("type", newType)
    }

    /**
     * Submit form and change password
     */
	public submit() {
		this.message = "";
		if(this.changePasswordForm.value['newPassword'] != this.changePasswordForm.value['confirmNewPassword']) {
			this.message = "Password confirmation must match.";
			return;
		}
		this._authResourceService.changePassword(
			this.changePasswordForm.value['oldPassword'], 
			this.changePasswordForm.value['newPassword']
		).subscribe((response) => {
			console.log(response);
			if(response.success) {
				this.message = "Password changed successfully."
			} else {
				this.message = response.message;
			}
		})
	}
}
