import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';

export interface SubscriptionFeature {
    name: string,
    description: string,
    hasAccess: boolean
}

export class SubscriptionTierModel extends ResourceModel {

    @Field(String)
    public name: string = "";

    @Field(String)
    public description: string = "";

    @Field(Number)
    public price: number = 0.00;

    @Field(String)
    public frequency: string = "";

    @Field(Number)
    public subscriptionCount: number = 0;

    @Field(Array)
    public features: SubscriptionFeature[] = [];

    @Field(String)
    public activeStatus: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }
}
