import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { TruckMaintenanceLogModel } from '../models/truckMaintenanceLog.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';

@Injectable({
    providedIn: 'root'
})
export class TruckMaintenanceLogService extends ResourceService<TruckMaintenanceLogModel> {
    protected servicePath: string = '/TruckMaintenanceLog';

    public ModelType = TruckMaintenanceLogModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

}
