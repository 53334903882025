import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

import { Company } from './companyStatement.model';

import { User } from './driverActivityLog.model';

export class DriverHoursModel extends ResourceModel {

    @Field(Object)
    public driver: User = { id: "", name: "" };

    @Field(Object)
	public company: Company = { id: "", name: "" };
	
	@Field(Date)
	public logStart: Date | null = null;
	
	@Field(Date)
	public logEnd: Date | null = null;
	
	@Field(String)
    public notes: string = "";

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
