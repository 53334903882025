import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

import { Company } from './companyStatement.model';

export interface ActivityType {
    id: string,
    name: string
}

export class DriverActivityTypeModel extends ResourceModel {

    @Field(String)
    public name: string = "";

    @Field(Object)
    public company: Company = { id: "", name: "" };

    @Field(String)
    public type: string = "";

    @Field(String)
    public description: string = "";

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
