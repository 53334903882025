import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AestheticsModule, ResourceModule, MediaModule } from '@smartsoftware/reflex-core';
import { FileManagerPaneComponent } from './file-manager-pane.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SpinnerModule } from '../spinner/spinner.module';

@NgModule({
    imports: [
        CommonModule,
        AestheticsModule,
        ResourceModule,
        MediaModule,
        MatIconModule,
        MatCheckboxModule,
        PdfViewerModule,
        SpinnerModule
    ],
    exports: [
        FileManagerPaneComponent
    ],
    declarations: [
        FileManagerPaneComponent
    ],
    entryComponents: [],
    providers: []
})
export class FileManagerPaneModule { }
