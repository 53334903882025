import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';
import {
    RelatedCompany
} from './company.model';

export class ZoneModel extends ResourceModel {

    @Field(String)
    public name: string = "";

    @Field(Object)
    public company: RelatedCompany = { id: "", name: "" };

    @Field(String)
    public type: string = "";

    @Field(Object)
    public entity: any = { id: "", name: "" };

    @Field(String)
    public address: string = "";

    @Field(Number)
    public latitude: number = 0;

    @Field(Number)
    public longitude: number = 0;

    @Field(Number)
    public radius: number = .1;

    @Field(String)
    public accountNumber: string | null = null;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }
}
