import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';
import {
    RelatedCompany
} from '../';

export class FuelLocationModel extends ResourceModel {

    @Field(Object)
    public company: RelatedCompany | null = null;

    @Field(String)
    public name: string = "";

    @Field(String)
    public notes: string = "";

    @Field(String)
    public state: string = "";

    @Field(String)
    public city: string = "";

    @Field(String)
    public zip: string | null = null;

    @Field(Boolean)
    public autoSetPrice: boolean = false;

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
