import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

import { Company } from './companyStatement.model';

export class QuoteTemplateModel extends ResourceModel {

    @Field(String)
	public templateHtml: string = "";

	@Field(Boolean)
	public masterTemplate: boolean = false;

	@Field(Object)
    public company: Company | null = { name: "", id: "" };

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
