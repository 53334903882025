import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { ConfigService, ReflexEnvironment as environment } from '@smartsoftware/reflex-core';
import * as configFile from './assets/config.json';

if (environment.isProduction) {
  enableProdMode();
}

// Config File is generated by importConfig.js at compile time
// Config is overwritten at runtime after it hits the server
// Note that requring this way compiles the default config into the app. It's not read at runtime. Runtime config changes come from the server invocation
ConfigService.DefaultConfig = (configFile as any).default;

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then(
		(appModule: NgModuleRef<AppModule>) => {
			environment.serviceInjector = appModule.injector;
		}
	)
	.catch(
		error => console.error(error)
	);
