import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { ProfileService } from 'src/app/modules/user-profile';
import { 
    CompanyModel, CompanyService,
    CustomerModel, CustomerService,
    ZoneModel, ZoneService 
} from '../../modules/resource-module';

@Component({
    selector: 'app-site-search-modal',
    templateUrl: './site-search-modal.component.html',
    styleUrls: ['./site-search-modal.component.scss']
})
export class SiteSearchModalComponent {

    public isSuperAdmin: boolean = false;
    public companies: CompanyModel[] = [];

    public selectedSite: ZoneModel;
    public sites$: BehaviorSubject<ZoneModel[]> = new BehaviorSubject<ZoneModel[]>([]);
    public searchForm: FormGroup = new FormGroup({
        company: new FormControl('', [Validators.required]),
        customer: new FormControl('', [Validators.required]),
        site: new FormControl('', [Validators.required])
    }, [])

    public get companyId(): string {
        return this.profileService.getCompanyValue(this.searchForm.get('company').value?.id);
    }

    @Output() customerAction: EventEmitter<string> = new EventEmitter<string>();
    @Output() siteAction: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        protected _companyService: CompanyService,
        protected _customerService: CustomerService,
        protected _siteService: ZoneService,
        protected profileService: ProfileService,
        protected dialog: MatDialogRef<SiteSearchModalComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: any,
    ) {
        this.isSuperAdmin = profileService.isSuperAdmin;
    }

    ngOnInit(): void {
        if (this.isSuperAdmin) {
            this.getCompanies();
        }

        if (this.data.company) {
            this.searchForm.get('company').setValue(this.data.company);
        } else if (!this.isSuperAdmin) {
            this.searchForm.get('company').setValue(this.profileService.getCompanyValue());
        }

        if (this.data.customer) {
            this.searchForm.get('customer').setValue(this.data.customer);
            this.customerAction.emit(this.data.customer?.name);
        }

        if (this.data.company && this.data.customer) {
            this.siteAction.emit('full-reset');
        }
    }

    private getCompanies(): void {
        this._companyService.getCompanies()
            .subscribe((results: CompanyModel[]) => {
                this.companies = results;
            })
    }
    
    public companyChanged(): void {
        this.searchForm.get('customer').setValue('');
        this.searchForm.get('site').setValue('');
        this.selectedSite = null;

        this.customerAction.emit('full-reset');
        this.siteAction.emit('full-reset');
    }

    public readonly customerSelectCallback = this.customerSelect.bind(this);
    public readonly customerChangeCallback = this.customerChange.bind(this);

    public customerSelect($event: any, target: FormControl): void {
        target.setValue({ 
            id:  $event.option.value.id, 
            name:  $event.option.value.name 
        });
        this.selectedSite = null;
        this.siteAction.emit('full-reset');

        if (!this.searchForm.get('company').value) {
            this.searchForm.get('company').setValue($event.option.value.company);
        }
    }

    public customerChange(value: string): Observable<CustomerModel[]> {
        return this._customerService.getCustomers(this.companyId, value);
    }

    public customerValue(entity: CustomerModel): any {
        return entity;
    }

    public customerDisplay(entity: CustomerModel): string {
        return entity.name;
    }


    public readonly siteSelectCallback = this.siteSelect.bind(this);
    public readonly siteChangeCallback = this.siteChange.bind(this);

    public siteSelect($event: any, target: FormControl): void {
        this.selectedSite = $event.option?.value;
        target.setValue($event.option.value);
        
        if (!this.searchForm.get('company').value?.id) {
            this.searchForm.get('company').setValue($event.option.value.company);
        }

        if (!this.searchForm.get('customer').value?.id) {
            this.searchForm.get('customer').setValue($event.option.value.entity);
            this.customerAction.emit($event.option.value.entity.name);
        }

        this.siteAction.emit($event.option.value.address);
    }

    public siteChange(value: string): Observable<ZoneModel[]> {
        return this._siteService.getZones(this.companyId, this.searchForm.get('customer').value?.id, null, value);
    }

    public siteValue(entity: ZoneModel): any {
        return entity;
    }

    public siteDisplay(entity: ZoneModel): string {
        return entity.address;
    }

    public submit(): void {
        this.dialog.close({
            action: true,
            site: this.selectedSite
        });
    }

    public cancel(): void {
        this.dialog.close({
            action: false
        });
    }
    
}
