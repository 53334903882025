import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ProfileService } from '../../modules/user-profile/services';
import { AuthResourceService, Auth } from '../../modules'

@Component({
	selector: 'app-user-action-control',
	templateUrl: './user-action-control.html',
	styleUrls: ['./user-action-control.scss']
})
export class UserActionControlComponent {

	get profile() {
		return this.profileService.userModel;
	}

	constructor(
		protected profileService : ProfileService,
		protected authResourceService: AuthResourceService,
		private auth: Auth,
		protected router : Router
	) {

	}

	ngOnInit() {

	}

	public logout() {
		this.authResourceService.logout().subscribe((response: any) => {
			this.auth.clearLocal();
			this.router.navigate(['/']);
		  })
	}

}
