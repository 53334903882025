import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';

import {
    RelatedCompany
} from './company.model';

export class ServiceCategoryModel extends ResourceModel {

    @Field(String)
    public name: string = "";

    @Field(String)
    public code: string = "";

    @Field(Object)
    public company: RelatedCompany = { id: "", name: "" };

    @Field(String)
    public description: string = "";

    @Field(String)
    public notes: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }
}
