import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

import { Company } from './companyStatement.model';

import { User } from './driverActivityLog.model';

export interface InvoiceLineItem {
    serviceType: string;
    transaction: Date;
    units: number;
    unitPrice: number;
    total: number;
    amount: number;
    maxWeight: number;
    boxSize: string;
}

export interface Message {
    name: string;
    date: Date;
    content: string;
}

export class QuoteModel extends ResourceModel {

    @Field(Object)
    public company: Company | null = { name: "", id: "" };

    @Field(String)
    public title: string = "";

    @Field(Object)
    public lastUpdatedBy: User = { id: "", name: "" };

    @Field(Array)
    public userWatchList: User[] = [];

    @Field(String)
    public recurringSchedule: string = "";

    @Field(Date)
    public approvedAt: Date = new Date();

    @Field(Object)
    public approvedBy: User = { id: "", name: "" };

    @Field(String)
    public quoteStatus: string = "";

    @Field(String)
    public siteAddress: string = "";

    @Field(String)
    public siteCity: string = "";

    @Field(String)
    public siteState: string = "";

    @Field(String)
    public siteZip: string = "";

    @Field(Boolean)
    public billingAddressSame: boolean = false;

    @Field(String)
    public billingTerms: string = "";

    @Field(String)
    public billingAddress: string = "";

    @Field(String)
    public billingCity: string = "";

    @Field(String)
    public billingState: string = "";

    @Field(String)
    public billingZip: string = "";

    @Field(Date)
    public serviceAt: Date = new Date();

    @Field(String)
    public contactName: string = "";

    @Field(String)
    public contactPhone: string = "";

    @Field(String)
    public contactEmail: string = "";

    @Field(Object)
    public customer: User = { id: "", name: "" };

    @Field(String)
    public customerEmail: string = "";

    @Field(Boolean)
    public creditApplication: boolean = false;

    @Field(Boolean)
    public preliminaryApplication: boolean = false;

    @Field(Date)
    public validUntil: Date = new Date();

    @Field(Date)
    public deliveryAt: Date = new Date();

    @Field(String)
    public description: string = "";

    @Field(String)
    public comments: string = "";

    @Field(Array)
    public messages: Message[] = [];

    @Field(Array)
    public invoiceLineItems: InvoiceLineItem[] = [];

    @Field(String)
    public prelimBusinessName: string = "";
    @Field(String)
    public prelimBusinessAddress: string = "";
    @Field(String)
    public prelimDate: string = "";
    @Field(String)
    public prelimJobNo: string = "";
    @Field(String)
    public prelimJobParcelNo: string = "";
    @Field(String)
    public prelimJobAddress: string = "";
    @Field(String)
    public prelimCorrections: string = "";
    @Field(String)
    public prelimHasBond: string = "";
    @Field(String)
    public prelimOwnerContact: string = "";
    @Field(String)
    public prelimOwnerPhone: string = "";
    @Field(String)
    public prelimOwnerFax: string = "";
    @Field(String)
    public prelimContractorContact: string = "";
    @Field(String)
    public prelimContractorLicenseNo: string = "";
    @Field(String)
    public prelimContractorBondNo: string = "";
    @Field(String)
    public prelimContractorPhone: string = "";
    @Field(String)
    public prelimContractorFax: string = "";
    @Field(String)
    public prelimContractorAddress: string = "";
    @Field(String)
    public prelimLenderContact: string = "";
    @Field(String)
    public prelimLenderPhone: string = "";
    @Field(String)
    public prelimLenderFax: string = "";
    @Field(String)
    public prelimLenderAddress: string = "";
    @Field(String)
    public prelimBondingContact: string = "";
    @Field(String)
    public prelimBondingBondNo: string = "";
    @Field(String)
    public prelimBondingPhone: string = "";
    @Field(String)
    public prelimBondingFax: string = "";
    @Field(String)
    public prelimBondingAddress: string = "";
    @Field(String)
    public prelimGateContact: string = "";
    @Field(String)
    public prelimGatePhone: string = "";
    @Field(String)
    public prelimGateFax: string = "";
    @Field(String)
    public prelimGateCompany: string = "";
    @Field(String)
    public notes: string = "";

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
