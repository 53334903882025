import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

import { Company } from './companyStatement.model';

export interface GPSPoint {
    lat: number;
    lng: number;
    logTime: Date;
}

export interface GPSData {
    orderId: string;
    truckId: string;
    driverId: string;
    gpsPoints: GPSPoint[];
}

export class GPSEntryModel extends ResourceModel {

    @Field(Number)
    public lat: number = 0;

    @Field(Number)
    public lng: number = 0;

    @Field(String)
    public driverId: string = "";

    @Field(String)
    public orderId: string = "";

    @Field(String)
    public truckId: string = "";

    @Field(Date)
    public logTime: Date = new Date();

    @Field(Number)
    public duration: number = 0;

    @Field(Boolean)
    public tripCalculated: boolean = false;

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
