import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';

import { Company } from './companyStatement.model';

export interface User {
    id: string;
    name: string;
}

export interface DriverActivityType {
    id: string;
    name: string;
}

export class DriverActivityLogModel extends ResourceModel {

    @Field(Object)
    public driver: User = { id: "", name: "" };

    @Field(Object)
    public activityType: DriverActivityType = { id: "", name: "" };

    @Field(Object)
    public company: Company = { id: "", name: "" };

    @Field(Object)
    public customer: User = { id: "", name: "" };

    @Field(Date)
    public activityDate: Date | null = null;

    @Field(String)
    public notes: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
