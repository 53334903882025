import { Injectable } from '@angular/core';

import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import {
    UserService,
} from '../../../modules/resource-module/services/user.service';

import {
    UserModel
} from '../../../modules/resource-module/models';

import {
    AuthResourceService
} from '../../../modules/resource-module/services/auth-resource.service';

import {
    ServiceResponse
} from '../../../modules/resource-module/services/api-client.service'

import {
    Auth
} from '../../../modules/auth/services/auth.service';

import { Observable, BehaviorSubject, Subject, Subscription } from 'rxjs';

import { ReflexEnvironment as Environment, SearchResponse } from '@smartsoftware/reflex-core';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    /**
     * Singleton containing the current user profile for the app.
     */
    protected _userModel: UserModel = this._auth.getUserProfile() || null;

    /**
     * Fetch the user profile.
     */
    public get userModel(): UserModel | null {
        return this._userModel;
    }

    /**
     * Update the user profile and emit event to services/components listening
     * to changes.
     */
    public set userModel(profile: UserModel | null) {
        this.asyncUserModel = this.asyncUserModel.then(() => {
            return profile;
        });
        this._userModel = profile;
        this._profileEventBehaviorSubject.next(this.userModel);
        this._profileEventSubject.next(this.userModel);
    }

    public asyncUserModel: Promise<UserModel | null>;
    private _asyncUserResolver: Function;

    /**
     * Used to trigger update event notifications to other parts of the app.
     */
    protected _profileEventBehaviorSubject: BehaviorSubject<UserModel | null> = new BehaviorSubject(null);
    protected _profileEventSubject: Subject<UserModel | null> = new Subject();

    /**
     * Used for other services and components to listen for profile changes.
     * Is called on subscription with the last set (or initial) value.
     */
    public profileChangeEvent: Observable<UserModel | null> = this._profileEventBehaviorSubject.asObservable();

    /**
     * Used for other services and components to listen for profile changes.
     * Is only called when the profile value is changed.
     */
    public profileSetEvent: Observable<UserModel | null> = this._profileEventSubject.asObservable();

    protected _subscriptions: Array<Subscription> = [];

    /**
     * Returns true or false if Ferma Greenbox SuperAdmin
     *
     * @return  {<boolean>} Returns true if SuperAdmin, false otherwise.
     */
    public get isSuperAdmin(): boolean {
        return this.userModel?.roleData?.isSuperAdmin;
    }

    constructor(
        protected _auth: Auth,
        protected _authResourceService: AuthResourceService,
        protected _userService: UserService,
        protected _router: Router
    ) {

        //        let callback: Function;
        this.asyncUserModel = new Promise((resolve) => {
            this._asyncUserResolver = resolve;
        });

        /**
         * When the user's auth credentials change we want to make a request to
         * fetch the profile.
         */
        this._subscriptions.push(this._auth.credentialsUpdatedEvent.subscribe((event) => {
            if (event && event.accessToken) {
                this.getProfile(this._asyncUserResolver);
            }
        }));

    }

    /**
     * Fetches user data using the access token which is set in the
     * Authorization header.
     */
    private getProfile(callback?: Function): void {
        this._userService.viewProfile().subscribe((response: ServiceResponse) => {
            if (response.success) {
                callback(response.data);
                this.userModel = response.data;
                let user: any = this.userModel.toJSON();
                user.companyData = response.data.companyData;
                user.roleData = response.data.roleData;
                this._auth.setUserProfile(user);
                if (typeof this._auth.credentials['email'] === 'undefined') {
                    let credentials = this._auth.credentials;
                    credentials['email'] = this.userModel.email;
                    this._auth.credentials = credentials;
                }
            } else {
                //                callback(null);
                // TODO strictly define possible response error types
                switch (response.data) {
                    case 'Unauthorized':
                        if (this._auth.credentials) {
                            let credentials = this._auth.credentials;
                            delete credentials.accessToken;
                            //this._auth.credentials = credentials;
                        }
                        break;
                    default:
                        if (this._auth.credentials) {
                            let credentials = this._auth.credentials;
                            delete credentials.accessToken;
                            //this._auth.credentials = credentials;
                        }
                        break;
                }

            }
        }, (error) => {
            console.error('error: ', error);
            callback(null);
            this.userModel = null;
            this._auth.clearLocal();
            this._router.navigate(['/']);
        });
    }

    /**
     * Helper function for Mat Autocomplete to return a companyId based on Super Admin status
     *
     * @param   {string}  companyId  A nullable company id for Super Admins
     *
     * @return  {string}             If Super Admin, returns company id passed, else the user's default company's id.
     */
    public getCompanyValue(companyId: string = null): string {
        return this.isSuperAdmin ? companyId : this.userModel?.company?.id;
    }
}

/**
 * Route guard requiring the user have an assigned user model (temporary or
 * obtained from their cognito account relationship).
 */
@Injectable({
    providedIn: 'root'
})
export class ProfileRouteGuard implements CanActivate {

    protected _profileExists: Promise<boolean>;

    constructor(
        protected _profileService: ProfileService,
        protected router: Router
    ) {

        this._profileExists = this._profileService.asyncUserModel.then((userModel: UserModel | null) => {

            this._profileService.profileChangeEvent.subscribe((profile: UserModel | null) => {
                if (profile) {
                    this._profileExists = this._profileExists.then(() => true);
                } else {
                    this._profileExists = this._profileExists.then(() => false);
                }
            });

            return userModel !== null;
        });
    }

    public canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        return this._profileExists.then((hasProfile: boolean) => {
            if (!hasProfile) {
                this.router.navigate(
                    ['/'],
                    //                    {skipLocationChange: true}
                );
            }
            return hasProfile;
        });
    }
}
