import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

import { Company } from './companyStatement.model';
import { Order } from './order.model';
import { User } from './driverActivityLog.model';

export interface Zone {
    id: string,
    name: string,
    accountNumber: string
}

export interface Bin {
    id: string,
    binNumber: string
}

export interface BinType {
    id: string,
    name: string,
    code: string
}

export interface Activity {
    date: Date,
    site: Zone,
    address: string,
    action: string,
    information: string
}

export class BinModel extends ResourceModel {

    @Field(Object)
    public company: Company = { id: "", name: "" };

    @Field(String)
    public binNumber: string = "";

    @Field(String)
    public legacyId: string = "";

    @Field(String)
    public legacyInventoryId: string = "";

    @Field(Object)
    public binType: BinType = { id: "", name: "", code: "" };

    @Field(Date)
    public lastDropOff: Date | null = null;

    @Field(Object)
    public currentLocation: Zone = { id: "", name: "", accountNumber: "" };

    @Field(String)
    public binStatus: string = "";

    @Field(Object)
    public activeOrder: Order = { id: "", orderNumber: "" };

    @Field(Object)
    public customer: User = { name: "", id: "" };

    @Field(Date)
    public scheduledPickup: Date | null = null;

    @Field(Object)
    public site: Zone = { name: "", id: "", accountNumber: "" };

    @Field(String)
    public address: string = "";

    @Field(String)
    public city: string = "";

    @Field(String)
    public state: string = "";

    @Field(String)
    public zipCode: string = "";

    @Field(Object)
    public activities: Activity[] = [];

    @Field(String)
    public notes: string = "";

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

        public get name(): string {
            return this.binNumber;
        }

}
