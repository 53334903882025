import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { RouteTrackingDeviceModel } from '../models/routeTrackingDevice.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileService } from '../../user-profile/services/profile.service';

@Injectable({
    providedIn: 'root'
})
export class RouteTrackingDeviceService extends ResourceService<RouteTrackingDeviceModel> {
    protected servicePath: string = '/RouteTrackingDevice';

    public ModelType = RouteTrackingDeviceModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService,
        protected _profileService: ProfileService
    ) {
        super(http, _apiClient);
    }

    public checkCurrentDevice(deviceId: string): Observable<RouteTrackingDeviceModel> {

        return this.http
            .post(
                this.serviceUrl + 'checkDevice',
                {
                    deviceId: deviceId
                },
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (serverResponse: HttpResponse<any>) => {
                        let model = serverResponse.body;
                        return new RouteTrackingDeviceModel(model);
                    }
                )
            );
    }

    public assignDeviceToCompany() {
        let deviceId = window.localStorage.getItem('deviceId');

        if(deviceId && deviceId != '')
        {
            return this.checkCurrentDevice(deviceId).subscribe((response) => {
                let device = new RouteTrackingDeviceModel(response);
                // If the device isn't assigned to a company and the user is logged in
                if(!device.company && this._profileService.userModel && this._profileService.userModel?.company?.id != "") {
                    // Update the device to belong to the company of the logged in user
                    device.company = {
                        id: this._profileService.userModel?.company?.id,
                        name: this._profileService.userModel?.company?.name
                    }
                    this.push(device).subscribe(()=>{});
                }
            })
        }
    }
}
