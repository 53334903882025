import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ScheduleService, ScheduleModel, ScheduleType, OrderModel, OrderService } from '../../../../../modules/resource-module';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

@Component({
    selector: 'app-time-adjust-modal',
    templateUrl: './time-adjust-modal.component.html',
    styleUrls: ['./time-adjust-modal.component.scss']
})
export class TimeAdjustModalComponent implements OnInit {

    public schedule: ScheduleModel;

    public startTime: string = "";
    public startTimeCap: string = "";
    public nextStartTime: string = "11:59 PM";

    public endTime: string = "";
    public endTimeCap: string = "";
    public previousEndTime: string = "12:00 AM"

    public displayError: boolean = false;

    theme: NgxMaterialTimepickerTheme = {
        container: {
            buttonColor: '#198743'
        },
        dial: {
            dialBackgroundColor: '#198743',
        },
        clockFace: {
            clockHandColor: '#198743',
        }
    };

    constructor(
        protected entityService: ScheduleService,
        protected orderService: OrderService,
        protected dialog: MatDialogRef<TimeAdjustModalComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: any,
    ) {
        this.schedule = data.schedule;

        if (data.nextStartTime != null) {
            this.nextStartTime = this.getTime(data.nextStartTime);
        }

        if (data.previousEndTime != null) {
            this.previousEndTime = this.getTime(data.previousEndTime);
        }
    }

    ngOnInit() {
        this.startTime = this.getTime(this.schedule.startTime);
        this.endTime = this.getTime(this.schedule.endTime);
        this.startTimeCap = this.getStartTimeCap(this.schedule.startTime);
        this.endTimeCap = this.getEndTimeCap(this.schedule.endTime);
    }

    /**
     * Closes Time Adjust Modal
     */
    public closeDialog(): void {
        this.dialog.close();
    }

    /**
     * Updates the Schedule entity, displays an error if something goes wrong.
     */
    public updateScheduleTime(): void {
        this.displayError = false;
        let startTimeVals: any = this.getTimeValues(this.startTime);
        let endTimeVals: any = this.getTimeValues(this.endTime);
        this.schedule.startTime.setHours(startTimeVals.hours, startTimeVals.minutes);
        this.schedule.endTime.setHours(endTimeVals.hours, endTimeVals.minutes);
        this.schedule.totalHours = this.calculateTimeDifference();

        // Set CSS Height
        let timeBlockCount = (this.schedule.totalHours / 0.25);
        let blockCssHeight = timeBlockCount * 40;
        let borderAdjust = timeBlockCount + 1;

        this.schedule.cssHeight = `${blockCssHeight + borderAdjust - 24}px`;

        this.entityService.updateSchedule(this.schedule)
            .subscribe(
                (entity: ScheduleModel) => {

                    if (entity.scheduleType != "Service") {
                        this.dialog.close();
                        return;
                    }

                    // TODO: Update order information here if it is a Service type order.
                    this.schedule.order.routeDuration = entity.totalHours;
                    this.schedule.order.scheduledTime = entity.startTime;

                    let order: OrderModel = new OrderModel(this.schedule.order);

                    this.orderService.push(order).subscribe((item: OrderModel) => {
                        // TODO: Maybe add error handling?
                        this.dialog.close();
                    });
                },
                (error: any) => {
                    this.displayError = true;
                }
            )
    }

    /**
     * Gets a Date object and returns the time in a string
     * @param  date               Date object
     * @return      A string containing 12 hour time.
     */
    public getTime(date: Date): string {
        var hours = date.getHours();
        var mid = 'AM';
        if (hours == 0) { //At 00 hours we need to show 12 am
            hours = 12;
        }
        else if (hours > 12) {
            hours = hours % 12;
            mid = 'PM';
        }
        return `${hours}:${date.getMinutes()} ${mid}`;
    }

    /**
     * Updates the Start Time cap to prevent overlapping
     */
    public updateStartTime(): void {
        // Set a small delay to allow model to be updated before making changes.
        setTimeout(() => {
            this.startTimeCap = this.getStartTimeCap(this.startTime);
        }, 10);

    }

    /**
     * Updates the End Time cap to prevent overlapping
     */
    public updateEndTime(): void {
        // Set a small delay to allow model to be updated before making changes.
        setTimeout(() => {
            this.endTimeCap = this.getEndTimeCap(this.endTime);
        }, 10);

    }

    /**
     * Gets the Time Cap for Start
     * @param  date               The Date object to calculate the cap
     * @return      A string in 12 hour containing the cap
     */
    public getStartTimeCap(date: Date | string): string {
        if (typeof date === "object") {
            let capDate: Date = new Date(date);
            capDate.setMinutes(capDate.getMinutes() + 15);
            return this.getTime(capDate);
        } else {
            let capDate: Date = new Date(this.schedule.startTime);
            let timeVals: any = this.getTimeValues(date);
            capDate.setHours(timeVals.hours, timeVals.minutes + 15);
            return this.getTime(capDate);
        }

    }

    /**
     * Gets the Time Cap for End
     * @param  date               The Date object to calculate the cap
     * @return      A string in 12 hour containing the cap
     */
    public getEndTimeCap(date: Date | string): string {
        if (typeof date === "object") {
            let capDate: Date = new Date(date);
            capDate.setMinutes(capDate.getMinutes() - 15);
            return this.getTime(capDate);
        } else {
            let capDate: Date = new Date(this.schedule.endTime);
            let timeVals: any = this.getTimeValues(date);
            capDate.setHours(timeVals.hours, timeVals.minutes - 15);
            return this.getTime(capDate);
        }

    }

    /**
     * Converts a string of time to an object with seperate hours and minutes
     * @param  time              String representation of time in 12 hours.
     * @return      Object with hours and minutes as seperate values.
     */
    public getTimeValues(time: string): any {
        let hours = parseInt(time.substring(0, time.indexOf(':')));
        let minutes = parseInt(time.substring(time.indexOf(':') + 1, time.indexOf(':') + 3));

        if (time.includes('AM') && hours == 12) {
            hours = 0;
        } else if (time.includes('PM')) {
            hours += 12;
        }

        return {
            hours: hours,
            minutes: minutes
        };
    }

    /**
     * Returns the time difference in manhours style.
     * @return A number containing time difference between start and end times.
     */
    public calculateTimeDifference(): number {
        let startTimeVals = this.getTimeValues(this.startTime);
        let endTimeVals = this.getTimeValues(this.endTime);

        return (endTimeVals.hours + (endTimeVals.minutes / 60))
            - (startTimeVals.hours + (startTimeVals.minutes / 60));
    }

}
