import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';

import { Company } from './companyStatement.model';

export class TaxProfileModel extends ResourceModel {

    @Field(Object)
    public company: Company = { id: "", name: "" };

    @Field(String)
    public code: string = "";

    @Field(String)
    public expression: string = "";

    @Field(String)
    public description: string = "";

    @Field(String)
    public notes: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }
}
