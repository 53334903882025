import {
    NgModule,
//    ModuleWithProviders
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core'
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';

import {
    ForgotPasswordComponent,
    ConfirmForgotPasswordComponent,
    ChangePasswordComponent,
} from './components';

import { RoutingModule } from './routing.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,

        RoutingModule,

        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatRippleModule,
        MatTabsModule,
        MatDialogModule
    ],
    exports: [
    ],
    declarations: [
        ForgotPasswordComponent,
        ConfirmForgotPasswordComponent,
        ChangePasswordComponent,
    ],
    entryComponents: [
    ],
    providers: []
})
export class AuthModule {
//    static forRoot(): ModuleWithProviders<AuthModule> {
//        return {
//            ngModule: AuthModule,
//            providers: [
//                AuthResourceService,
//                Auth
//            ]
//        };
//    }
}
