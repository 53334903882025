import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { BinModel } from '../models/bin.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';

import { OrderService } from './order.service';
import { OrderModel } from '../models/order.model';

@Injectable({
    providedIn: 'root'
})
export class BinService extends ResourceService<BinModel> {
    protected servicePath: string = '/Bin';

    public ModelType = BinModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService,
        protected _orderService: OrderService
    ) {
        super(http, _apiClient);
    }

    public flatBin(bin: BinModel): any {
        return {
            id: bin.id,
            binNumber: bin.binNumber
        }
    }

    public updateOrderData(orderForm, addActivities, deleteActivities) {
        let addDropoffSet = [...new Set(addActivities.map(item => item.dropoff?.id))];
        let deleteDropoffSet = [...new Set(deleteActivities.map(item => item.dropoff?.id))];
        let addPickupSet = [...new Set(addActivities.map(item => item.pickup?.id))];
        let deletePickupSet = [...new Set(deleteActivities.map(item => item.pickup?.id))];
        let binSet = [...new Set([...addDropoffSet, ...deleteDropoffSet, ...addPickupSet, ...deletePickupSet])];

        binSet.forEach((binId: string) => {
            this.get(binId).subscribe((bin: BinModel) => {
                if (bin) {
                    // push added records to activities
                    addActivities.forEach((activity) => {
                        if (binId == activity.dropoff?.id) {
                            bin.activities.push({
                                date: new Date(activity.date),
                                site: orderForm.get("site").value,
                                address: orderForm.get("siteAddress").value,
                                action: "Dropoff",
                                information: "Bin dropped off"
                            });
                        }
                        if (binId == activity.pickup?.id) {
                            bin.activities.push({
                                date: new Date(activity.date),
                                site: orderForm.get("site").value,
                                address: orderForm.get("siteAddress").value,
                                action: "Pickup",
                                information: "Bin picked up"
                            });
                        }
                    });

                    // remove deleted records from activities
                    let dropoffDeleted, pickupDeleted = false;
                    deleteActivities.forEach((activity) => {
                        if (binId == activity.dropoff?.id) {
                            bin.activities = bin.activities.filter(function( obj ) {
                                return !(new Date(obj.date).getTime() == new Date(activity.date).getTime() && obj.action == "Dropoff");
                            });
                            if (new Date(bin.lastDropOff).getTime() == new Date(activity.date).getTime()) {
                                bin.lastDropOff = null;
                                dropoffDeleted = true;
                            }
                        }
                        if (binId == activity.pickup?.id) {
                            bin.activities = bin.activities.filter(function( obj ) {
                                return !(new Date(obj.date).getTime() == new Date(activity.date).getTime() && obj.action == "Pickup");
                            });
                            if (bin.binStatus == "On Site" && new Date(bin.scheduledPickup).getTime() == new Date(activity.date).getTime()) {
                                bin.scheduledPickup = null;
                                bin.activeOrder = { id: "", orderNumber: "" };
                                bin.customer = { name: "", id: "" };
                                bin.site = { name: "", id: "", accountNumber: "" };
                                bin.address = "";
                                bin.city = "";
                                bin.state = "";
                                bin.zipCode = "";
                                pickupDeleted = true;
                            }
                        }
                    });

                    // get new latest dropoff of bin
                    orderForm.get("containers").value.forEach((container) => {
                        if (container.dropoff?.id == binId && (!bin.lastDropOff || new Date(container.date).getTime() > bin.lastDropOff.getTime())) {
                            bin.lastDropOff = new Date(container.date);
                        }
                    });

                    // get new active order details if scheduled pickup changed
                    if (bin.binStatus == "On Site") {
                            let pickupChanged = false;

                            orderForm.get("containers").value.forEach((container) => {
                                if (container.pickup?.id == binId && (!bin.scheduledPickup || new Date(container.date).getTime() > bin.scheduledPickup.getTime())) {
                                    bin.scheduledPickup = new Date(container.date);
                                    pickupChanged = true;
                                }
                            });

                            if (pickupChanged) {
                                bin.activeOrder = {
                                    id: orderForm.get("id").value,
                                    orderNumber: orderForm.get("orderId").value
                                };
                                bin.customer = orderForm.get("customer").value;
                                bin.site = orderForm.get("site").value;
                                bin.address = orderForm.get("siteAddress").value;
                                bin.city = orderForm.get("siteCity").value;
                                bin.state = orderForm.get("siteState").value;
                                bin.zipCode = orderForm.get("siteZip").value;
                            }
                    }

                    // if a bin's latest pickup or dropoff was deleted, get the new latest pickup/dropoff
                    if (dropoffDeleted || pickupDeleted) {
                        this._orderService.list({
                            allowCache: false
                        }).subscribe((orders: OrderModel[]) => {
                            orders.forEach((order) => {
                                order.containers.forEach((container) => {
                                    if (pickupDeleted && container.pickup?.id == binId && (!bin.scheduledPickup || new Date(container.date).getTime() > bin.scheduledPickup.getTime())) {
                                        bin.scheduledPickup = new Date(container.date);
                                        bin.activeOrder = this._orderService.flatOrder(order);
                                        bin.customer= order.customer;
                                        bin.site = order.site;
                                        bin.address = order.siteAddress;
                                        bin.city = order.siteCity;
                                        bin.state = order.siteState;
                                        bin.zipCode = order.siteZip;
                                    }
                                    if (dropoffDeleted && container.dropoff?.id == binId && (!bin.lastDropOff || new Date(container.date).getTime() > bin.lastDropOff.getTime())) {
                                        bin.lastDropOff = new Date(container.date);
                                    }
                                });
                            });
                            this.push(bin).subscribe();
                        });
                    } else {
                        this.push(bin).subscribe();
                    }
                }
            });
        });
    }

}
