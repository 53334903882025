import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { ResourceToolbar as ReflexResourceToolbar } from '@smartsoftware/reflex-core';
import { ModalService } from '../../services/modal.service';

import { RoleAuthGuardService } from '../../modules/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from 'src/app/modules/user-profile';

@Component({
    selector: 'fg-resource-toolbar',
    templateUrl: './fg-resource-toolbar.component.html',
    styleUrls: ['./fg-resource-toolbar.component.scss']
})
export class FgResourceToolbarComponent extends ReflexResourceToolbar {

    @ViewChild('deleteButton') deleteButton: MatButton;

    private _hideList: string[] = [];
    public hiddenFields: { [key: string]: boolean } = {};
    private _showList: string[] = [];
    public showUnauthFields: { [key: string]: boolean } = {};

    private _deleteDisabled: boolean = false;
    public initDelete: boolean = false;

    @Input()
    public get deleteDisabled(): boolean {
        return this._deleteDisabled
    }

    public set deleteDisabled(val: boolean) {
        if (this.deleteButton) {
            this.deleteButton._elementRef.nativeElement.setAttribute('style', `width: 81.109375px;`);
        }
        this._deleteDisabled = val;
    }

    @Input() showPrintButton: boolean = true;
    @Input() isNew: boolean = false;

    @Input()
    public get hideList(): string[] {
        return this._hideList;
    }

    public set hideList(val: string[]) {
        this._hideList = val;
        let temp: { [key: string]: boolean } = {};
        val.forEach((v: string) => {
            temp[v] = true;
        });
        this.hiddenFields = temp;
    }

    public hideButton(button: string): boolean {
        return this.hideList.includes(button);
    }

    @Input()
    public get showList(): string[] {
        return this._showList;
    }

    public set showList(val: string[]) {
        this._showList = val;
        let temp: { [key: string]: boolean } = {};
        val.forEach((v: string) => {
            temp[v] = true;
        });
        this.showUnauthFields = temp;
    }

    public showButton(button: string): boolean {
        return this.showList.includes(button);
    }

    public isAuthenticated() {
        return !!this.profileService.userModel;
    }

    public hasPermission(permissionType: string) {
        return this.roleAuthGuardService.validatePath(this.activatedRoute.snapshot, this.profileService.userModel?.roleData, permissionType);
    }

    public hasDeletePermission() {
        return this.roleAuthGuardService.validatePath(this.activatedRoute.snapshot, this.profileService.userModel?.roleData, "delete");
    }

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        protected modalService: ModalService,
        protected profileService: ProfileService,
        protected roleAuthGuardService: RoleAuthGuardService,
        protected location: Location
    ) {
        super();
    }

    public openDialog(): void {
        this.modalService.openDialog({
            data: {
                title: "Unsaved Changes",
                description: "Exit without saving changes?",
                actionButton: "YES",
                cancelButton: "CANCEL"
            },
            width: '260px',
            maxHeight: '400px'
        }).subscribe((action: boolean) => {
            if (action) {
                this.location.back();
            }
        });
    }

    public newWindow(event, auxclick = false) {
        if (auxclick || event.ctrlKey) {
            let url = window.location.href + '/new';
            window.open(url, '_blank').focus();
        } else {
            this.click('new');
        }
    }

    public backWindow(event, auxclick = false) {
        if (auxclick || event.ctrlKey) {
            let url = window.location.href;
            let i = url.lastIndexOf('/');
            i = i == -1 ? url.length : i + 1;
            url = url.substring(0, i);
            window.open(url, '_blank').focus();
        } else if (!this.isNew && this.ngForm.dirty) {
            this.openDialog();
        } else {
            this.location.back();
        }
    }
}
