import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ScheduleService, ScheduleModel, ScheduleType } from '../../../../../modules/resource-module';

@Component({
    selector: 'app-calendar-event-modal',
    templateUrl: './calendar-event-modal.component.html',
    styleUrls: ['./calendar-event-modal.component.scss']
})
export class CalendarEventModalComponent {

    public calendarEventForm: FormGroup = new FormGroup({
        calendarEventName: new FormControl('', [Validators.required])
    });
    public displayError: boolean = false;

    constructor(
        protected entityService: ScheduleService,
        protected dialog: MatDialogRef<CalendarEventModalComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: any,
    ) { }

    public closeDialog(): void {
        this.dialog.close();
    }

    public saveCalendarEvent(): void {
        let newSchedule: ScheduleModel = new ScheduleModel();
        newSchedule.scheduleName = this.calendarEventForm.get('calendarEventName').value;
        newSchedule.scheduleType = ScheduleType.Custom;
        this.entityService.push(newSchedule)
            .subscribe(
                (entity: ScheduleModel) => {
                    this.dialog.close();
                },
                (error: any) => {
                    this.displayError = true;
                }
            )
    }
}
