import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

export class CustomerSiteModel extends ResourceModel {

    @Field(Object)
    public company: any = { id: "", name: "" };

    @Field(Object)
    public salesRep: any = { id: "", name: "" };

    @Field(String)
    public customerId: string = "";

    @Field(String)
    public customerName: string = "";

    @Field(String)
    public siteId: string = "";

    @Field(String)
    public siteName: string = "";

    @Field(String)
    public siteAddress: string = "";

    @Field(String)
    public accountNumber: string = "";

    @Field(String)
    public customerStatus: string = "";

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
