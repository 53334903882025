import { Component, OnInit, HostBinding } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Event } from '@angular/router';
import { map, filter } from 'rxjs/operators';
import { AuthResourceService, Auth } from '../../modules';
import { ProfileService } from '../../modules/user-profile/services';
import { RoleAuthGuardService } from '../../modules/auth';

@Component({
    templateUrl: './adminContainer.html',
    styleUrls: ['./adminContainer.scss']
})
export class AdminContainerComponent implements OnInit {

    private _slideOpen: boolean = false;
    public get slideOpen(): boolean {
        return this._slideOpen;
    };
    public set slideOpen(value: boolean) {
        this._slideOpen = value;
    }

    public submenuAssets: boolean = false;
    public submenuSystem: boolean = false;
    public submenuUsers: boolean = false;
    public submenuCompanies: boolean = false;
    public submenuCustomers: boolean = false;
    public submenuDrivers: boolean = false;
    public submenuQuotes: boolean = false;
    public submenuOrders: boolean = false;
    public submenuBilling: boolean = false;
    public submenuRoutes: boolean = false;
    public submenuLandfills: boolean = false;
    public submenuReports: boolean = false;
    public breadcrumbPath: string[] = [];
    public breadcrumbEndpoint: string = "";

    public get isSuperAdmin(): boolean {
        return this._profileService.isSuperAdmin;
    }

    @HostBinding('class.viewInitialized')
    public viewInitialized: boolean = false;

    ngOnInit() {
        setTimeout(
            () => {
                this.viewInitialized = true;
            },
            500
        )

        this._router.events
            .pipe(filter((event: Event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.refreshBreadcrumb();
            });

        // Update the breadcrumb on refresh.
        this.refreshBreadcrumb();
    }

    public getStyle() {
        return {
            'background-image': 'url("' + this._profileService ?.userModel ?.companyData ?.backgroundFilename + '")'
		}
    }

    constructor(
        protected _authResourceService: AuthResourceService,
        protected _profileService: ProfileService,
        private _auth: Auth,
        protected _router: Router,
        protected _route: ActivatedRoute,
        protected roleAuthGuardService: RoleAuthGuardService
    ) { }

    public refreshBreadcrumb(): void {
        let breadcrumb: string[] = this._refreshBreadcrumb(this._route);
        this.breadcrumbEndpoint = breadcrumb.splice(-1, 1, "")[0];
        this.breadcrumbPath = breadcrumb;
    }

    private _refreshBreadcrumb(route: ActivatedRoute): string[] {
        let breadcrumb: string[] = [];
        const label: string = route ?.snapshot ?.data ?.breadcrumb || "";
        breadcrumb.push(label);

        (route.children || [])
            .filter((child: ActivatedRoute) => {
                return !!child;
            })
            .forEach((child: ActivatedRoute) => {
                breadcrumb = [...breadcrumb, ...this._refreshBreadcrumb(child)];
            });

        return breadcrumb
            .filter((crumb: string) => {
                return (crumb || "") != "";
            });
    }

    public loggedIn() {
        return this._profileService.userModel;
    }

    public hasPermission(permissionType: string, route: string) {
        return this.roleAuthGuardService.validatePathString(route, this._profileService.userModel?.roleData, permissionType);
    }

    public showSubmenu(route: string): boolean {
        if (!this._profileService.userModel?.roleData)
            return false;

        if (this._profileService.userModel?.roleData?.isSuperAdmin)
            return true;

        let data = this._profileService.userModel.roleData.permissions;

        let canView: boolean = false;
        Object.keys(data).forEach((key) => {
            if (key.includes(route)) {
                if (data[key]['view']) {
                    canView = true;
                }
            }
        })

        return canView;
    }

    public logout() {
        this._authResourceService.logout().subscribe((response: any) => {
            console.log(response);
            this._auth.clearLocal();
            this._router.navigate(['/']);
        })
    }

}
