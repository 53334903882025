import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
    UserProfileEditComponent
} from './components';

const routes = [
    {
        path: '',
        component: UserProfileEditComponent,
        data: {
            breadcrumb: null
        },
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class RoutingModule {}
