import {
	ResourceModel as ReflexResourceModel,
	Field,
	FieldDefinition
} from '@smartsoftware/reflex-core';

export class ResourceModel extends ReflexResourceModel {

	public get uuid(): string | number {
		return this.id;
	}

	public set uuid(id: string | number) {
		this.id = id
	}

}

export class ExtendedResourceModel extends ResourceModel { }
