import { Component, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import {
	AuthResourceService, ServiceResponse,
	UserModel,
	RouteTrackingDeviceService
} from '../../modules/resource-module';

import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
/**
 * Component to handle login UI.
 *
 * TODO will move either to dialog, menu or other module/component once mockups
 * have been provided.
 */
export class LoginComponent implements OnDestroy {

	protected _dialogRef: MatDialogRef<any>;
	protected _dialogSubscription: Subscription;

	// TODO temp, delete
	public message: string = '';

	public rememberMe: boolean = false;

	public pending: boolean = false;

    /**
     * Form group for login credentials.
     */
	public loginForm: FormGroup = new FormGroup({
		username: new FormControl('', [Validators.required]),
		password: new FormControl('', [Validators.required])
	});

	protected _subscriptions: Array<Subscription> = [];

	constructor(
		protected _dialog: MatDialog,
		protected _authResourceService: AuthResourceService,
		protected _routeTrackingDeviceService: RouteTrackingDeviceService,
		protected _router: Router,
		protected _titleService: Title
	) {
		this._titleService.setTitle("Ferma Greenbox");
	}

	ngOnDestroy() {
		this._subscriptions.forEach((subscription) => {
			subscription.unsubscribe();
		})
	}

    /**
     * Submit form and if response is correct route to user's dashboard.
     *
     * TODO should a url redirect parameter be added and redirect there if set?
     */
	public submit() {
		this.message = '';
		this._authResourceService.authenticate({
			username: this.loginForm.value['username'],
			password: this.loginForm.value['password']
		}).subscribe((response: ServiceResponse) => {
			if (response.success) {
				this._router.navigate(['/dashboard']).then(() => {
					this._routeTrackingDeviceService.assignDeviceToCompany();
				});
			} else {
				//                this.message = 'Debug message: ' + response.message;
				// We should only get the UserNotFoundException code if we tried to log in while the cognito user exists,
				// as that is what cognito sends when a non verified user or non existant user tries to login.
				// We check if the cognito user exists before attempting the login and throw a different error if
				// the user actually doesn't exist
				if(response.data.code == 'UserNotFoundException') {
					this._router.navigate(['/']);
				}
				this.message = 'You are unable to login with the specified credentials. Please try again.';
			}
		})
	}
}
