import { Component } from '@angular/core';
import { 
  ProfileService
} from '../../modules/user-profile/services'
import { 
  RouteTrackingService
} from '../../modules/route-tracking/services'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'FermaGreen Admin';
    constructor(
      protected _profileService: ProfileService,
      protected _routeTrackingService: RouteTrackingService
    ) {

    }
}
