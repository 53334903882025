import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { OrderModel, ScheduleModel, UserModel } from '../models';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { Observable } from 'rxjs';
import { SearchResponse } from '@smartsoftware/reflex-core';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ScheduleService extends ResourceService<ScheduleModel | any> {
    protected servicePath: string = '/Schedule';
    public ModelType = ScheduleModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public getScheduleData(query: any): Observable<ScheduleData> {

        if (this.companyCheckList.includes(this.servicePath)) {
            let profile: UserModel = this.getUserProfile();
            let isSuperAdmin: boolean = profile ?.roleData ?.isSuperAdmin == true;

            if (!isSuperAdmin) {
                query.query.bool.must.push({
                    nested: {
                        path: "company",
                        query: {
                            term: {
                                "company.id": profile.company.id
                            }
                        }
                    }
                })
            }
        }

        return this.http
            .post(
                this.serviceUrl + 'get-scheduler-data',
                query,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (serverResponse: HttpResponse<any>) => {
                        let records = serverResponse.body;
                        return new ScheduleData(records.scheduledRecords, records.unscheduledRecords);
                    }
                )
            );
    }

    public updateSchedule(entry: ScheduleModel): Observable<ScheduleModel> {
        return this._apiClient
            .request
            (
            entry.uuid ? 'put' : 'post',
            this.serviceUrl + (entry.uuid || ''),
            {
                observe: 'response',
                body: entry
            }
            )
            .pipe(
                map<any, ScheduleModel>(
                    (response) => {
                        return response ? new this.ModelType(response) : undefined
                    }

                )
            );
    }
}

export class ScheduleData {
    public scheduledRecords: ScheduleModel[];
    public unscheduledRecords: ScheduleModel[];

    constructor(
        scheduledRecords: ScheduleModel[],
        unscheduledRecords: ScheduleModel[]
    ) {
        this.scheduledRecords = scheduledRecords;
        this.unscheduledRecords = unscheduledRecords;
    }
}
