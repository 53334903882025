import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    AestheticsModule,
    DialogsModule,

    // TODO not sure if necessary to re-import and export child modules of ResourceModule if importing and exporting ResourceModule.
//    ResourceViewModule,
//    ResourceEditModule,
//    ResourceListModule,
//    ResourceToolbar // ResourceToolbar is already declared and exported by sss-reflex's ResourceModule. Importing sss-reflex's ResourceModule will make all it's exports accessable to the importing module.
    ResourceModule as ReflexResourceModule
} from '@smartsoftware/reflex-core';

//import { ResourceEditComponent } from './components';

@NgModule({
    declarations: [
        //    ResourceToolbar
        //ResourceEditComponent
    ],
    imports: [
        FormsModule, ReactiveFormsModule,
        RouterModule,
//        ResourceListModule,
//        ResourceViewModule,
//        ResourceEditModule,

        AestheticsModule,
        DialogsModule,
        ReflexResourceModule
    ],
    exports: [
        ReflexResourceModule
        //    ResourceToolbar,
//        ResourceListModule,
//        ResourceViewModule,
//        ResourceEditModule
    ]
})
export class ResourceModule {
//    static forRoot(): ModuleWithProviders<ResourceModule> {
//        return {
//            ngModule: ResourceModule,
//            providers: []
//        };
//    }
}
