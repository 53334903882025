import { Injectable } from '@angular/core'

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';

import { Auth } from '../../auth/services/auth.service';
import { UserModel } from '../models';

export interface ServiceResponse {
    success: boolean;
    data?: any;
    message?: any;
}

@Injectable({
    providedIn: 'root'
})
/**
 * Class for appending access tokens and headers to api gateway endpoint
 * requests. Fulfills same purpose as HttpClient for request types that can be
 * sent to the API Gateway (GET, POST, PUT, DELETE) and centralizes logic for
 * appending headers and other common request transformations.
 *
 * TODO Localize error handling in these functions for auth errors (403's etc)
 * so that the Auth service can be informed and have it's stored auth info
 * invalidated.
 */
export class ApiClientService {
    constructor(protected _httpClient: HttpClient, protected _auth: Auth) { }

    public request(method: string, url: string, options: { [key: string]: any }): Observable<any> {
        options['headers'] = this.getHeader(options);
        return this._httpClient.request(method, url, options).pipe(
            map(
                (response: HttpResponse<any>) => response.body
            )
        );
    }

    public delete(url: string, options: { [key: string]: any }): Observable<any> {
        options['headers'] = this.getHeader(options);
        return this._httpClient.delete(url, options).pipe(
            map(
                (response: HttpResponse<any>) => response.body
            )
        );
    }

    public get(url: string, options: { [key: string]: any }): Observable<any> {
        options['headers'] = this.getHeader(options);
        return this._httpClient.get(url, options).pipe(
            map(
                (response: HttpResponse<any>) => response.body
            )
        );
    }

    public post(url: string, body: any | null, options: { [key: string]: any }): Observable<any> {
        options['headers'] = this.getHeader(options);
        return this._httpClient.post(url, body, options).pipe(
            map(
                (response: HttpResponse<any>) => response.body
            )
        );
    }

    public put(url: string, body: any | null, options: { [key: string]: any }): Observable<any> {
        options['headers'] = this.getHeader(options);
        return this._httpClient.put(url, body, options).pipe(
            map(
                (response: HttpResponse<any>) => response.body
            )
        );
    }

    /**
     * Minor helper function to set access token in request options header if
     * the auth service has one available.
     *
     * TODO 'Authorization' should be changed to something other than just the
     * access token (preferably the entire auth response from the api) this way
     * the api client can track when the access token expires and make a
     * refresh request with the refresh token.
     */
    public getHeader(options: { [key: string]: any }): HttpHeaders {
        let headers: HttpHeaders = options && options['headers'] ?
            <HttpHeaders>options['headers'] :
            new HttpHeaders();

        if (this._auth.credentials && this._auth.credentials.accessToken) {
            // Set the access token in the header if we have one.
            headers = headers.set('Authorization', this._auth.credentials['accessToken']);
        }

        return headers;
    }

}
