import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { TaxProfileModel } from '../models';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';

@Injectable({
    providedIn: 'root'
})
export class TaxProfileService extends ResourceService<TaxProfileModel> {
    protected servicePath: string = '/TaxProfile';

    public ModelType = TaxProfileModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public flatTax(taxProfile: TaxProfileModel): any {
        return {
            id: taxProfile.id,
            code: taxProfile.code
        }
    }
}
