import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

import {
    RelatedCompany,
    User,
    DefaultTruck
} from '../';

export enum FuelType {
    regular = "regular",
    midGrade = "midGrade",
    premium = "premium",
    diesel = "diesel"
};

export class FuelLogModel extends ResourceModel {

    @Field(Object)
    public company: RelatedCompany | null = null

    @Field(Object)
    public driver: User | null = null;

    @Field(Object)
    public truck: DefaultTruck | null = null;

    @Field(Number)
    public odometer: number = 0;

    @Field(Number)
    public gallons: number = 0.0;

    @Field(String)
    public location: string = "";

    @Field(Date)
    public fueledAt: Date = new Date();

    @Field(String)
    public notes: string = "";

    @Field(String)
    public fuelType: FuelType = FuelType.regular;

    @Field(Number)
    public costPerGallon: number = 0.00;

    @Field(Number)
    public totalCost: number = 0.00;

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
