import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthResourceService, ServiceResponse } from '../../../resource-module';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

    public message: string = '';

    public pending: boolean = false;

    public state: number = 0;

    public passwordForm:FormGroup = new FormGroup({
        username: new FormControl('', [Validators.required])
    });

    constructor(
        protected _router: Router,
        protected _authResourceService: AuthResourceService
    ) {}

    public submit() {
        this.message = '';
        this.pending = true;
        this._authResourceService.forgotPassword(this.passwordForm.value['username']).subscribe((response: ServiceResponse) => {
            // Don't want to give away whether or not the request succeeded to prevent farming for emails or spamming reset requests.
           if (response.success) {
               this._router.navigate(['/auth', 'confirm-forgot-password']);
           } else {
               this._router.navigate(['/auth', 'confirm-forgot-password']);
//                this.message = 'Debug message: ' + response.message;
           }
            this.state = 1;
            this.pending = false;
        });
    }

}
