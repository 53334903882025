import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';

export interface MainContact {
    id: string;
    name: string;
}

export interface RelatedCompany {
    id: string;
    name: string;
}

export interface SubscriptionTier {
    id: string,
    name: string
}

export class CompanyModel extends ResourceModel {

    @Field(String)
    public name: string = "";

    @Field(String)
    public code: string = "";

    @Field(String)
    public phone: string = "";

    @Field(String)
    public address: string = "";

    @Field(String)
    public email: string = "";

    @Field(String)
    public website: string = "";

    @Field(Object)
    public mainContact: MainContact | null = null;

    @Field(Object)
    public subscriptionTier: SubscriptionTier | null = null;

    @Field(Number)
    public billingDay: number | null = null;

    @Field(Array)
    public permissions: string[] = [];

    @Field(Boolean)
    public isDefault: boolean = false;

    @Field(String)
    public backgroundFilename: string = "";

    @Field(String)
    public letterheadFilename: string = "";

    @Field(String)
    public defaultAdminRole: string = "";

    @Field(String)
    public defaultPublicRole: string = "";

    @Field(String)
    public tagline: string = "";

    @Field(String)
    public defaultScheduleDuration: string = "";

    @Field(String)
    public stripePublicKey: string = "";

    @Field(String)
    public stripeSecretKey: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
