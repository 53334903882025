import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';

import {
    RelatedCompany
} from './company.model';

export interface Role {
    id: string;
    name: string;
    isDriver: boolean;
    isMechanic: boolean;
    isCustomer: boolean;
    isAdmin: boolean;
    isSuperAdmin: boolean;
    isGuest: boolean;
    isAccounter: boolean;
    isSalesRep: boolean;
}

export class RoleModel extends ResourceModel {

    @Field(String)
    public name: string = "";

    @Field(Object)
    public company: RelatedCompany = { id: "", name: "" };

    @Field(Object)
    public permissions: any = {};

    @Field(Boolean)
    public defaultRole: boolean = false;

    @Field(Boolean)
    public isSuperAdmin: boolean = false;

    @Field(Boolean)
    public isAdmin: boolean = false;

    @Field(Boolean)
    public isDriver: boolean = false;

    @Field(Boolean)
    public isCustomer: boolean = false;

    @Field(Boolean)
    public isMechanic: boolean = false;

    @Field(Boolean)
    public isGuest: boolean = false;

    @Field(Boolean)
    public isAccounter: boolean = false;

    @Field(Boolean)
    public isSalesRep: boolean = false;

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
