import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    CompanyService, CompanyModel, CustomerService, CustomerModel,
    OrderTemplateService, OrderTemplateModel
} from '../../../../../resource-module/';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from 'src/app/modules/user-profile';

@Component({
    selector: 'app-order-template-modal',
    templateUrl: './order-template-modal.component.html',
    styleUrls: ['./order-template-modal.component.scss']
})
export class OrderTemplateModalComponent implements OnInit {

    public isSuperAdmin: boolean = false;
    public companies: CompanyModel[] = [];
    public orderTemplates: OrderTemplateModel[] = [];
    public templateForm: FormGroup = new FormGroup({
        company: new FormControl('', [Validators.required]),
        customer: new FormControl('', [Validators.required]),
        orderTemplate: new FormControl('', [Validators.required])
    }, [])

    public get isValid(): boolean {
        return this.templateForm.get('company').value?.id && this.templateForm.get('customer').value?.id;
    }

    public get companyId(): string {
        return this.profileService.getCompanyValue(this.templateForm.get('company').value?.id);
    }
    @Output() action: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        protected dialog: MatDialogRef<OrderTemplateModalComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: any,
        protected companyService: CompanyService,
        protected customerService: CustomerService,
        protected orderTemplateService: OrderTemplateService,
        protected profileService: ProfileService
    ) {
        this.isSuperAdmin = profileService.isSuperAdmin;
    }

    ngOnInit(): void {
        if (this.isSuperAdmin) {
            this.getCompanies();
        }

        if (this.data.company) {
            this.templateForm.get('company').setValue(this.data.company);
        } else if (!this.isSuperAdmin) {
            this.templateForm.get('company').setValue(this.profileService.getCompanyValue());
        }

        if (this.data.customer) {
            this.templateForm.get('customer').setValue(this.data.customer);
            this.action.emit(this.data.customer?.name);
        }

        if (this.isValid) {
            this.getOrderTemplates(
                this.templateForm.get('company').value?.id,
                this.templateForm.get('customer').value?.id
            );
        }
    }

    private getCompanies(): void {
        this.companyService.getCompanies()
            .subscribe((results: CompanyModel[]) => {
                this.companies = results;
            })
    }

    private getOrderTemplates(orderId: string, customerId: string) {
        this.orderTemplateService.getOrderTemplates(orderId, customerId)
            .subscribe((results: OrderTemplateModel[]) => {
                this.orderTemplates = results;
            });
    }

    public setTemplate(template: OrderTemplateModel) {
        this.templateForm.get('orderTemplate').setValue(template);
    }

    public companyChanged(): void {
        this.templateForm.get('customer').setValue('');
        this.action.emit('full-reset');
    }

    public customerChanged(): void {
        if (this.isValid) {
            this.getOrderTemplates(
                this.templateForm.get('company').value?.id,
                this.templateForm.get('customer').value?.id,
            );
        }
    }

    public submit() {
        this.dialog.close(this.templateForm.get('orderTemplate').value);
    }

    public cancel() {
        this.dialog.close();
    }

    public templateSelected(id: string): boolean {
        return this.templateForm.get('orderTemplate').value?.id == id;
    }

    public readonly entitySelectCallback = this.entitySelect.bind(this);
    public readonly entityChangeCallback = this.entityChange.bind(this);

    public entitySelect($event: any, target: FormControl): void {
        target.setValue($event.option.value);
        this.customerChanged();
    }

    public entityChange(value: string): Observable<CustomerModel[]> {
        return this.customerService.getCustomers(this.companyId, value);
    }

    public entityValue(entity: CustomerModel): any {
        return { id: entity.id, name: entity.name };
    }

    public entityDisplay(entity: CustomerModel): string {
        return entity.name;
    }
}
