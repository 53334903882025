import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { FormGroup, FormControl, Validators } from '@angular/forms';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ResourceEditComponent, ResourceFormService, MediaItem } from '@smartsoftware/reflex-core';

import { ProfileService } from '../../services';

import { ChangePasswordDialog } from './change-password';
import { CloseAccountDialog } from './close-account';
import { ConfirmCloseAccountDialog } from './confirm-close-account';

import {
    UserModel, UserService,
    RoleModel, RoleService, CompanyService, CompanyModel,
} from '../../../resource-module';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
    selector: 'user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileEditComponent extends ResourceEditComponent<UserModel, UserService> {
	public roleList: RoleModel[] = [];
	public companyList: CompanyModel[] = [];
	protected _subscriptions: Array<Subscription> = [];
	protected _dialogRef: MatDialogRef<any>;
	protected _dialogSubscription: Subscription;
	public isSuperAdmin: boolean = false;

    protected _id: string | null = null;

    constructor(
        protected route: ActivatedRoute,
        protected entityService: UserService,
		protected _roleService: RoleService,
		protected _profileService: ProfileService,
		protected _companyService: CompanyService,
        protected dialog: MatDialog,
        protected resourceFormService: ResourceFormService<UserModel>
    ) {
        super(entityService, route, dialog, resourceFormService);

		this._subscriptions.push(this._profileService.profileChangeEvent.subscribe((event: UserModel) => {
			if (event) {
				this.entity = event;
				this._id = this.entity.id.toString();
			} else {
				this.entity = null;
			}
		}));

		this.isSuperAdmin = this._profileService.userModel ?.roleData ?.isSuperAdmin == true;

		this._companyService.list({
            allowCache: false // TODO what if user deletes cache?
        }).subscribe((results: CompanyModel[]) => {
            this.companyList = results;
		});
		
		this
			.entityStatus
			.subscribe(
				entity => {
					if (entity) {
						this.companyChanged({
							value: entity.company
						})
					}
				}
			)

	}
	
	getRole(id: string) {
		if(!id)
			return null;

		for(let i = 0; i < this.roleList.length; ++i) {
			if(id == this.roleList[i].id)
				return this.roleList[i];
		}
		return null;
	}

	public unsubscribe() {
		if(this.entity.email) {
			this.entityService.unsubscribeUser(this.entity.email).subscribe((result) => {
				if(result) {
					this.entityForm.patchValue({
						showNotifications: false,
						newOrders: false,
						orderModifications: false,
						driverUpdates: false,
						recurringOrders: false
					})
				}
			});
		}
	}

    companyChanged(event) {
        if (event.value.id) {
            this._roleService.rolesForCompany(event.value.id)
				.subscribe(response => {
                this.roleList = response.results;
            });
        }
	}
	
	changePassword() {
		this._dialogRef = this.dialog.open(ChangePasswordDialog, {
			data: {}
		});

		this._dialogSubscription = this._dialogRef.afterClosed().subscribe((data: any) => {
			this._dialogSubscription.unsubscribe();
		});
	}

	closeAccount() {
		this._dialogRef = this.dialog.open(CloseAccountDialog, {
			data: {id: this._profileService.userModel.id}
		});

		this._dialogSubscription = this._dialogRef.afterClosed().subscribe((data: any) => {
			this._dialogSubscription.unsubscribe();
		});
	}
    save() {
		this.entityForm.patchValue({
			driver: this.entity.driver,
			customer: this.entity.customer,
			cognitoId: this.entity.cognitoId,
			isCustomerManager: this.entity.isCustomerManager
		})
        super.save();
    }
}
