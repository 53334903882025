import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { SubscriptionTierModel } from '../models/subscriptionTier.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchResponse } from '@smartsoftware/reflex-core';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionTierService extends ResourceService<SubscriptionTierModel> {
    protected servicePath: string = '/SubscriptionTier';

    public ModelType = SubscriptionTierModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public getSubscriptions(id: string): Observable<SubscriptionTierModel | undefined> {
        return this._apiClient
            .get(
                this.serviceUrl + 'getSubscriptions/' + id,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map<any, SubscriptionTierModel>(
                    (response) =>
                        response ? new this.ModelType(response) : undefined
                )
            );
    }

    public flatSubscriptionTier(tier: SubscriptionTierModel): any {
        return {
            id: tier.id,
            name: tier.name
        };
    }

    /**
     * Gets Subscription Tier records
     *
     * @return  {Observable<SubscriptionTierModel>[]} Observable containing Subscription Tier records.
     */
    public getSubscriptionTiers(): Observable<SubscriptionTierModel[]> {
        let where: any = {
            query: {
                bool: {
                    must_not: [
                        {
                            exists: {
                                field: "deletedAt"
                            }
                        }
                    ]
                }
            },
            size: 1000
        }

        return this.list({
            allowCache: false,
            where: where
        }).pipe(
            map(
                (results: SubscriptionTierModel[]) => results.sort((a, b) => a.name.localeCompare(b.name))
            )
        );
    }
}
