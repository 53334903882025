import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { MessageModel } from '../models/message.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { ProfileService } from '../../user-profile/services';

@Injectable({
    providedIn: 'root'
})
export class MessageService extends ResourceService<MessageModel> {
    protected servicePath: string = '/Message';

    public ModelType = MessageModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService,
        protected _profileService: ProfileService
    ) {
        super(http, _apiClient);
    }

    public getEntityMessages(entityId: string) {
        let query: any = {
            "query": {
                "bool": {
                    "must": [
                        {
                            "term": {
                                "entityId": entityId
                            }
                        }
                    ]
                }
            },
            "sort": [
                {"messageTime": "desc"}
            ],
            "size": 25
        }
        return this.search(query);
    }

    public sendMessage(message: string, entityId: string, entityType: string) {
        let msg = new MessageModel({
            text: message,
            authorId: this._profileService.userModel.id,
            authorName: this._profileService.userModel.name,
            entityId: entityId,
            entityType: entityType,
            messageTime: new Date()
        })

        return this.push(msg);

    }
}
