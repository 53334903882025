import { Injectable, Optional, ApplicationRef, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { QuoteTemplateModel } from '../models/quoteTemplate.model';
import { QuoteModel } from '../models/quote.model';
import { ApiClientService, ServiceResponse } from './api-client.service';
import { ResourceService } from './resource.service';
import { jsPDF, AcroFormCheckBox, AcroFormTextField, AcroFormButton } from "jspdf";
import { ReflexEnvironment as Environment } from '@smartsoftware/reflex-core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NumberValueAccessor } from '@angular/forms';



@Injectable({
    providedIn: 'root'
})
export class QuoteTemplateService extends ResourceService<QuoteTemplateModel> {
    protected servicePath: string = '/QuoteTemplate';

    protected htmlChunks: string[] = [];
    protected htmlElements: HTMLElement[] = []

    public ModelType = QuoteTemplateModel;
    public rendering: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService,
        protected appRef: ApplicationRef
    ) {
        super(http, _apiClient);
    }


    /**
     * Request a pre-signed url to upload a company's background image to.
     */
	public uploadTemplateImage(file: File, filename: string, companyId: string) {

		return this.requestImageUploadUrl(file, filename, "templates", companyId).pipe(map((response: ServiceResponse) => {
			if (response.success) {
				let url: string = response.data['url'];
				let contentPath: string = response.data['path'];
				return this.uploadImageFile(file, url).pipe(
                    map((response: ServiceResponse) => {
                        return { url: [Environment.config['contentUrl'], contentPath].join('/')};
                    })
                ).toPromise();
			}
		}));
	}

    /**
     * Request a pre-signed url to upload a company's image to.
     */
	public requestImageUploadUrl(file: File, filename: string, uploadType: string, companyId: string): Observable<ServiceResponse> {
		return this._apiClient.request(
			'post',
			this.serviceUrl + "upload",
			{
				observe: 'response',
				body: {
					filename: filename,
					contentType: file.type,
                    uploadType: uploadType,
                    companyId: companyId,
				}
			}
		);
    }

    public uploadImageFile(file: File, url: string): Observable<ServiceResponse> {
		return this.http.put(
			url,
			file,
			{
				headers: {
					"Content-Type": file.type
				}
			}
		).pipe(map<any, ServiceResponse>(
			(response: any) => {
				return {
					success: true,
					data: response
				}
			}, (error: any) => {
				return {
					success: false,
					data: error
				}
			}
		));
	}


    public createPdfFromTemplate2(template: QuoteTemplateModel, quote: QuoteModel | null) {
        var doc = new jsPDF("p", "pt", [792, 612]);
        doc.setFontSize(16);
        doc.text("TextField:", 10, 145);
        var textField: any = new AcroFormTextField();
        this.setObjectProperties(textField);
        this.setTextFieldProperties(textField);
        textField.height = 20;
        textField.width = 60;
        textField.multiline = true;
        textField.fontSize = 16;
        textField.maxFontSize = 16;
        textField.value =
          "The quick brown fox ate the lazy mouse The quick brown fox ate the lazy mouse The quick brown fox ate the lazy mouse"; //
        textField.fieldName = "TestTextBox";
        //textField.x = 0;
        //textField.y = 0;
        textField.Rect = [50, 130, 60, 20];
        textField.maxLength = 500;
        var keys = Object.getOwnPropertyNames(textField).filter(function(key) { return (key != "content" && key != "appearanceStreamContent" && key != "scope" && key != "objId" && key.substring(0, 1) != "_");})
        console.log(keys);
        console.log(textField.getKeyValueListForStream());
        doc.addField(textField);
        doc.save("templatePreview.pdf");
    }

    public createPdfFromTemplate(template: QuoteTemplateModel, quote: QuoteModel | null) {
        this.rendering.emit(true);
        this.htmlChunks = [];
        this.htmlElements = [];
        var doc = new jsPDF("p", "px", [792, 612]);

        let html = template.templateHtml;
        if(quote) {
            Object.keys(quote).forEach(key => {
                let k = key.substr(1);
                if(typeof quote[k] == "object" && quote[k] !== null) {
                    if(quote[k] instanceof Date) {
                        // TODO: Add specific date printing logic
                        html = html.replace(new RegExp("{{" + k + "}}", "gm"), quote[k])
                    } else if(Array.isArray(quote[k])) {
                        // TODO: Add specific array handling logic
                        let startIndex = 0;
                        let index: number = 0;
                        let indices: number[] = [];
                        while ((index = html.indexOf("{{#" + k + "}}", startIndex)) > -1) {
                            indices.push(index);
                            startIndex = index + k.length + 4;
                        }

                        for(let repeatRow = 0; repeatRow < indices.length; ++repeatRow) {
                            let rowStart: number;
                            let rowEnd: number;

                            rowStart = html.substring(0, indices[repeatRow]).lastIndexOf("<tr");
                            rowEnd = rowStart + html.substring(rowStart).indexOf("</tr") + 5;

                            let repeatedTr = html.substring(rowStart, rowEnd);
                            let newRows : string[] = [];
                            for(let repeatIndex = 0; repeatIndex < quote[k].length; ++repeatIndex) {
                                let newRow = (' ' + repeatedTr).slice(1);
                                newRow = newRow.replace(new RegExp("{{#" + k + "}}", "gm"), (repeatIndex + 1) + ".")
                                Object.keys(quote[k][repeatIndex]).forEach(key => {
                                    newRow = newRow.replace(new RegExp("{{" + k + "." + key + "}}", "gm"), quote[k][repeatIndex][key])
                                });
                                newRows.push(newRow);
                            }
                            html = html.substring(0, rowStart) + newRows.join('') + html.substring(rowEnd);
                        }
                    } else {
                        // Handles subobject fields
                        Object.keys(quote[k]).forEach(subkey => {
                            html = html.replace(new RegExp("{{" + k + '.' + subkey + "}}", "gm"), quote[k][subkey])
                        });
                    }
                } else {
                    html = html.replace(new RegExp("{{" + k + "}}", "gm"), quote[k])
                }
            });

            if(!quote.preliminaryApplication) {
                // Remove the prelim section if it's not a prelim quote
                html = html.replace(new RegExp("{{prelim}}.*?{{/prelim}}", "gm"), "")
            } else {
                // Otherwise remove the tags and leave the original text
                html = html.replace(new RegExp("{{prelim}}(.*?){{/prelim}}", "gm"), "$1")
            }
        }
        html = html.replace(new RegExp("{{\\*x}}", "gm"), "<span style='width:10px; height: 10px; display: inline-block' class='checkbox-replace'></span>")
        html = html.replace(new RegExp("\\[\\[_+\\]\\]", "gm"), (m) => { return "<span style='width:" + ((m.length - 4) * 10) + "px; height: 16px; display: inline-block' class='text-field-replace'></span>" })
        this.htmlChunks = html.split("{{pagebreak}}");
        let renderDiv = document.getElementById("export-container");
        this.htmlChunks.forEach((chunk) => {
            var div = document.createElement('div');
            div.style.display = "block";
            div.style.visibility = "hidden";
            div.style.position = "absolute";
            div.innerHTML = chunk;
            div.id = "quotePdf";
            div.className = "ck-content export-render";
            this.htmlElements.push(div);
            renderDiv.append(this.htmlElements[this.htmlElements.length - 1]);
        });
        //this.renderNextPage(doc, 0);
        setTimeout(() => {
            this.renderNextPage(doc, 0);
        }, 10);

        /*htmlChunks.forEach((htmlChunk, index) => {

        })*/
        /*html = html = html.replace(new RegExp("{{pagebreak}}", "gm"), '<div style="display: block; page-break-after:always"></div>');
        var div = document.createElement('div');
        div.innerHTML = html;
        div.id = "quotePdf";
        div.className = "ql-editor";

        doc.html(div, {
            callback: function(doc) {
                doc.save("templatePreview.pdf");
            },
            width: 612,
            windowWidth: 612
        });*/

        //doc.save("templatePreview.pdf");

    }

    public renderNextPage(doc: jsPDF, index: number) {
        this.htmlElements[index].style.visibility = "visible";
        doc.html(this.htmlElements[index], {
            callback: (doc) => {
                let checkboxes = this.htmlElements[index].getElementsByClassName("checkbox-replace");
                let parentRect = this.htmlElements[index].getBoundingClientRect();
                for(let i = 0; i < checkboxes.length; ++i) {

                    let check = new AcroFormCheckBox();
                    this.setObjectProperties(check);
                    this.setCheckboxProperties(check);
                    check.height = 15;
                    check.width = 15;
                    let checkRect = checkboxes[i].getBoundingClientRect();
                    check.x = (checkRect.left - parentRect.left);
                    check.y = (checkRect.top - parentRect.top);
                    check.fontSize = 10;
                    doc.addField(check);
                }
                let textFields = this.htmlElements[index].getElementsByClassName("text-field-replace");
                for(let i = 0; i < textFields.length; ++i) {
                    var textField = new (doc.AcroForm.TextField as any)();
                    let fieldRect = textFields[i].getBoundingClientRect();
                    this.setObjectProperties(textField);
                    this.setTextFieldProperties(textField);
                    textField.width = (fieldRect.right - fieldRect.left);
                    textField.height = (fieldRect.bottom - fieldRect.top);
                    textField.multiline = true;
                    textField.fontSize = 16;
                    textField.maxFontSize = 16;
                    textField.value = ""; //
                    textField.fieldName = "TextField" + index + "_" + i;
                    textField.x = (fieldRect.left - parentRect.left);
                    textField.y = (fieldRect.top - parentRect.top);
                    //textField.Rect = [50, 160, 300, 100];
                    textField.maxLength = 10000;
                    var keys = Object.getOwnPropertyNames(textField).filter(function(key) { return (key != "content" && key != "appearanceStreamContent" && key != "scope" && key != "objId" && key.substring(0, 1) != "_");})
                    doc.addField(textField);

                }

                this.htmlElements[index].remove();
                if(index < this.htmlChunks.length - 1) {
                    doc.addPage();
                    this.renderNextPage(doc, index + 1);
                } else {
                    doc.save("templatePreview.pdf");
                    let renderDiv = document.getElementById("export-container");
                    var child = renderDiv.lastElementChild;
                    while (child) {
                        renderDiv.removeChild(child);
                        child = renderDiv.lastElementChild;
                    }
                }

                this.rendering.emit(false);
            },
            width: 612,
            windowWidth: 612,
            y: 792 * (doc.getNumberOfPages() - 1)
        });
    }

    private setObjectProperties(obj: any) {

        obj.scale = function(x: any) {
            return x * this.scaleFactor;
        };
        obj.pdfEscape = function(value) {
            return value
              .replace(/\\/g, "\\\\")
              .replace(/\(/g, "\\(")
              .replace(/\)/g, "\\)");
        };
        obj.pdfUnescape = function(value) {
            return value
              .replace(/\\\\/g, "\\")
              .replace(/\\\(/g, "(")
              .replace(/\\\)/g, ")");
        };

        obj.toPdfString = function(string, objId, scope) {
            var encryptor = function(data) {
              return data;
            };
            if (typeof objId !== "undefined" && scope)
              encryptor = scope.internal.getEncryptor(objId);
            string = string || "";
            string.toString();
            string = "(" + this.pdfEscape(encryptor(string)) + ")";
            return string;
        };

        obj._F = 4;
        obj._Rect = [];
        obj._Ff = 0;
        obj._FT = "";
        obj._T = null;
        obj._DA = "/F1 0 Tf 0 g";
        obj._DV = null;
        //obj._V = null;
        obj._Q = null;

        Object.defineProperty(obj, "F", Object.freeze({
            enumerable: false,
            configurable: false,
            get: function() {
                return this._F;
            },
            set: function(value) {
                if (!isNaN(value)) {
                    this._F = value;
                } else {
                    throw new Error(
                    'Invalid value "' + value + '" for attribute F supplied.'
                    );
                }
            }
        }));

        Object.defineProperty(obj, "Ff", Object.freeze({
            enumerable: false,
            configurable: false,
            get: function() {
                return this._Ff;
            },
            set: function(value) {
                if (!isNaN(value)) {
                    this._Ff = value;
                } else {
                    throw new Error(
                    'Invalid value "' + value + '" for attribute Ff supplied.'
                    );
                }
            }
        }));

        Object.defineProperty(obj, "FT", Object.freeze({
            enumerable: true,
            configurable: false,
            get: function() {
              return this._FT;
            },
            set: function(value) {
              switch (value) {
                case "/Btn":
                case "/Tx":
                case "/Ch":
                case "/Sig":
                  this._FT = value;
                  break;
                default:
                  throw new Error(
                    'Invalid value "' + value + '" for attribute FT supplied.'
                  );
              }
            }
        }));

        Object.defineProperty(obj, "T", Object.freeze({
            enumerable: true,
            configurable: false,
            get: function() {
              if (!this._T || this._T.length < 1) {
                this._T = "FieldObject" + Math.floor(Math.random() * 1000);
              }
              var encryptor = function(data) {
                return data;
              };
              if (this.scope) encryptor = this.scope.internal.getEncryptor(this.objId);
              return "(" + this.pdfEscape(encryptor(this._T)) + ")";
            },
            set: function(value) {
              this._T = value.toString();
            }
        }));

        Object.defineProperty(obj, "Rect", Object.freeze({
            enumerable: false,
            configurable: false,
            get: function() {
                if (this._Rect.length === 0) {
                return undefined;
                }
                return this._Rect;
            },
            set: function(value) {
                if (typeof value !== "undefined") {
                this._Rect = value;
                } else {
                this._Rect = [];
                }
            }
        }));

        Object.defineProperty(obj, "DA", Object.freeze({
            enumerable: true,
            configurable: false,
            get: function() {
              if (
                !this._DA ||
                this instanceof AcroFormTextField
              ) {
                return undefined;
              }
              return this.toPdfString(this._DA, this.objId, this.scope);
            },
            set: function(value) {
              value = value.toString();
              this._DA = value;
            }
        }));

        Object.defineProperty(obj, "DV", Object.freeze({
            enumerable: false,
            configurable: false,
            get: function() {
              if (!this._DV) {
                return undefined;
              }
              if (this instanceof AcroFormButton === false) {
                return this.toPdfString(this._DV, this.objId, this.scope);
              }
              return this._DV;
            },
            set: function(value) {
              value = value.toString();
              if (this instanceof AcroFormButton === false) {
                if (value.substr(0, 1) === "(") {
                    this._DV = this.pdfUnescape(value.substr(1, value.length - 2));
                } else {
                    this._DV = this.pdfUnescape(value);
                }
              } else {
                this._DV = value;
              }
            }
        }));

        /*Object.defineProperty(obj, "_V", Object.freeze({
            enumerable: false,
            configurable: false,
            get: function() {
              if (!this._V) {
                return undefined;
              }
              return this._V;
            },
            set: function(value) {
              this.V = value;
            }
        }));*/

        Object.defineProperty(obj, "V", Object.freeze({
            enumerable: false,
            configurable: false,
            get: function() {
              if (!this._V) {
                return undefined;
              }
              if (this instanceof AcroFormButton === false) {
                return this.toPdfString(this._V, this.objId, this.scope);
              }
              return this._V;
            },
            set: function(value) {
              value = value.toString();
              if (this instanceof AcroFormButton === false) {
                if (value.substr(0, 1) === "(") {
                    this._V = this.pdfUnescape(value.substr(1, value.length - 2));
                } else {
                    this._V = this.pdfUnescape(value);
                }
              } else {
                this._V = value;
              }
            }
        }));

        Object.defineProperty(obj, "Type", Object.freeze({
            enumerable: true,
            configurable: false,
            get: function() {
              return this.hasAnnotation ? "/Annot" : null;
            }
        }));

        Object.defineProperty(obj, "Subtype", Object.freeze({
            enumerable: true,
            configurable: false,
            get: function() {
              return this.hasAnnotation ? "/Widget" : null;
            }
        }));

        Object.defineProperty(obj, "Q", Object.freeze({
            enumerable: true,
            configurable: false,
            get: function() {
              if (this._Q === null) {
                return undefined;
              }
              return this._Q;
            },
            set: function(value) {
              if ([0, 1, 2].indexOf(value) !== -1) {
                this._Q = value;
              } else {
                throw new Error(
                  'Invalid value "' + value + '" for attribute Q supplied.'
                );
              }
            }
        }));

        Object.defineProperty(obj, 'x', Object.freeze({
            enumerable: true,
            configurable: true,
            get: function () {
                if (!this.Rect || isNaN(this.Rect[0])) {
                return 0;
                }
                return this._Rect[0];

            },
            set: function (value) {
                this._Rect[0] = value;
            }
        }));



        Object.defineProperty(obj, 'y', Object.freeze({
            enumerable: true,
            configurable: true,
            get: function () {
                if (!this._Rect || isNaN(this._Rect[1])) {
                return 0;
                }
                return this._Rect[1];
            },
            set: function (value) {
                this._Rect[1] = value;
            }
        }));

        Object.defineProperty(obj, 'width', Object.freeze({
            enumerable: true,
            configurable: true,
            get: function () {
                if (!this._Rect || isNaN(this._Rect[2])) {
                return 0;
                }
                return this._Rect[2];
            },
            set: function (value) {
                this._Rect[2] = value;
            }
        }));

        Object.defineProperty(obj, 'height', Object.freeze({
            enumerable: true,
            configurable: true,
            get: function () {
                if (!this._Rect || isNaN(this._Rect[3])) {
                return 0;
                }
                return this._Rect[3];
            },
            set: function (value) {
                this._Rect[3] = value;
            }
        }));
    }

    private setTextFieldProperties(obj: any) {
        obj._MaxLen = null;
        obj.FT = "/Tx";

        Object.defineProperty(obj, "MaxLen", Object.freeze({
            enumerable: true,
            configurable: false,
            get: function() {
              return this._MaxLen;
            },
            set: function(value) {
                this._MaxLen = value;
            }
        }));
    }

    private setCheckboxProperties(obj: any) {
      obj._MaxLen = null;
      obj.FT = "/Btn";
      obj._MK = {};
      obj._AS = undefined;

      Object.defineProperty(obj, "MK", Object.freeze({
        enumerable: false,
        configurable: false,
        get: function() {
          var encryptor = function(data) {
            return data;
          };
          if (this.scope) encryptor = this.scope.internal.getEncryptor(this.objId);
          if (Object.keys(this._MK).length !== 0) {
            var result = [];
            result.push("<<");
            var key;
            for (key in this._MK) {
              result.push("/" + key + " (" + this.pdfEscape(encryptor(this._MK[key])) + ")");
            }
            result.push(">>");
            return result.join("\n");
          }
          return undefined;
        },
        set: function(value) {
          if (typeof value === "object") {
            this._MK = value;
          }
        }
      }));

      Object.defineProperty(obj, "AS", Object.freeze({
        enumerable: false,
        configurable: false,
        get: function() {
          return this._AS;
        },
        set: function(value) {
          this._AS = value;
        }
      }));
  }
}
