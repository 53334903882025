import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { DriverActivityLogModel } from '../models/driverActivityLog.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';

@Injectable({
    providedIn: 'root'
})
export class DriverActivityLogService extends ResourceService<DriverActivityLogModel> {
    protected servicePath: string = '/DriverActivityLog';

    public ModelType = DriverActivityLogModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

}
