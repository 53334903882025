import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { PaymentModel } from '../models';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentService extends ResourceService<PaymentModel> {

    protected servicePath: string = '/Payment';
    public ModelType = PaymentModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService,
    ) {
        super(http, _apiClient);
    }

}
