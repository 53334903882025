import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthGuardService } from './modules';

import {
    SplashComponent,
    NotFoundErrorComponent
} from '@smartsoftware/reflex-core';

import {
    AdminContainerComponent,
    AppComponent,
	LoginComponent
} from './components';

const routes: Routes = [
    {
        path: '',
        //canActivate: [AdminAuthGuardService]
        component: AppComponent,
        children: [
            {
                path: '',
                component: AppComponent,
                canActivate: [AuthGuardService],
                loadChildren: () => import('./modules/landing/landing.module').then((module) => module.LandingModule)
            },
            {
                path: 'login',
                component: LoginComponent
			},
			{
                path: 'unsubscribe',
				component: AppComponent,
				loadChildren: () => import('./modules/notifications/notifications.module').then((module) => module.NotificationsModule)
            },
            {
                path: 'auth',
                loadChildren: () => import('./modules/auth/auth.module').then((module) => module.AuthModule)
            },
            {
                path: 'dashboard',
                component: AdminContainerComponent,
                data: {
                    breadcrumb: "Dashboard"
                },
                canActivate: [AuthGuardService],
                loadChildren: () => import('./modules/dashboard/dashboard.module').then((module) => module.DashboardModule)
            },
            {
                path: 'registration',
                loadChildren: () => import('./modules/registration/registration.module').then((module) => module.RegistrationModule)
            },
            {
                path: 'companies',
                component: AdminContainerComponent,
                data: {
                    breadcrumb: "Companies"
                },
                canActivate: [AuthGuardService],
                loadChildren: () => import('./modules/company-management/company-management.module').then((module) => module.CompanyManagementModule)
            },
            {
                path: 'drivers',
                component: AdminContainerComponent,
                data: {
                    breadcrumb: "Drivers"
                },
                canActivate: [AuthGuardService],
                loadChildren: () => import('./modules/drivers/drivers.module').then((module) => module.DriversModule)
            },
            {
                path: 'customers',
                component: AdminContainerComponent,
                data: {
                    breadcrumb: "Customers"
                },
                canActivate: [AuthGuardService],
                loadChildren: () => import('./modules/customers/customers.module').then((module) => module.CustomersModule)
            },
            {
                path: 'orders',
                component: AdminContainerComponent,
                data: {
                    breadcrumb: "Orders"
                },
                canActivate: [AuthGuardService],
                loadChildren: () => import('./modules/orders/orders.module').then((module) => module.OrdersModule)
            },
            {
                path: 'users',
                component: AdminContainerComponent,
                data: {
                    breadcrumb: "Users"
                },
                canActivate: [AuthGuardService],
                loadChildren: () => import('./modules/user-management/user-management.module').then((module) => module.UserManagementModule)
            },
            {
                path: 'system',
                component: AdminContainerComponent,
                data: {
                    breadcrumb: "System"
                },
                canActivate: [AuthGuardService],
                loadChildren: () => import('./modules/system/system.module').then((module) => module.SystemModule)
            },
            {
                path: 'assets',
                component: AdminContainerComponent,
                data: {
                    breadcrumb: "Assets"
                },
                canActivate: [AuthGuardService],
                loadChildren: () => import('./modules/assets/assets.module').then((module) => module.AssetsModule)
            },
            {
                path: 'landfills',
                component: AdminContainerComponent,
                data: {
                    breadcrumb: "Landfills"
                },
                canActivate: [AuthGuardService],
                loadChildren: () => import('./modules/landfills/landfills.module').then((module) => module.LandfillsModule)
            },
            {
				path: 'user-profile',
				component: AdminContainerComponent,
                data: {
                    breadcrumb: "User Profile"
                },
                canActivate: [AuthGuardService],
                loadChildren: () => import('./modules/user-profile/user-profile.module').then((module) => module.UserProfileModule)
            },
            {
                path: 'billing',
                component: AdminContainerComponent,
                data: {
                    breadcrumb: "Billing"
                },
                canActivate: [AuthGuardService],
                loadChildren: () => import('./modules/billing/billing.module').then((module) => module.BillingModule)
			},
			{
                path: 'quotes',
                component: AdminContainerComponent,
                data: {
                    breadcrumb: "Quotes"
                },
                canActivate: [AuthGuardService],
                loadChildren: () => import('./modules/quotes/quotes.module').then((module) => module.QuotesManagementModule)
            },
            {
                path: 'reports',
                component: AdminContainerComponent,
                data: {
                    breadcrumb: "Reports"
                },
                canActivate: [AuthGuardService],
                loadChildren: () => import('./modules/reports/reports.module').then((module) => module.ReportsModule)
            },
            {
                path: 'c/:code',
                component: AppComponent,
                canActivate: [],
                loadChildren: () => import('./modules/landing/landing.module').then((module) => module.LandingModule)
            },
            {
                path: "content/about",
                resolve: {
                    url: "externalUrlRedirectResolver"
                },
                component: AppComponent,
                data: {
                    externalUrl: "https://www.fermagreenbox.com/about.html"
                }
            },
            {
                path: "content/privacy-policy",
                resolve: {
                    url: "externalUrlRedirectResolver"
                },
                component: AppComponent,
                data: {
                    externalUrl: "https://www.fermagreenbox.com/privacy-policy.html"
                }
            }
        ]
    },
    {
        path: '**',
        component: SplashComponent,
        children: [
            {
                path: '**',
                component: NotFoundErrorComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    declarations: [],
    exports: [RouterModule],
    providers: [
        {
            provide: "externalUrlRedirectResolver",
            useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            {
                window.location.href = (route.data as any).externalUrl;
            }
        }
    ]
})
export class AppRoutingModule { }
