import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { DriverActivityTypeModel } from '../models/driverActivityType.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DriverActivityTypeService extends ResourceService<DriverActivityTypeModel> {
    protected servicePath: string = '/DriverActivityType';

    public ModelType = DriverActivityTypeModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public flatType(type: DriverActivityTypeModel): any {
        return {
            id: type.id,
            name: type.name
        }
    }

    /**
     * Gets Driver Activity Type records
     *
     * @param   {string}                                 companyId  Optional parameter to filter down driver activity type records by company.
     *
     * @return  {Observable<DriverActivityTypeModel>[]}             List of driver activity type records.
     */
    public getDriverActivityTypes(companyId: string = null): Observable<DriverActivityTypeModel[]> {
        let where: any = null

        if (companyId) {
            where = {
                query: {
                    bool: {
                        must: [
                            {
                                nested: {
                                    path: "company",
                                    query: {
                                        term: {
                                            "company.id": companyId
                                        }
                                    }
                                }
                            }
                        ]
                    }
                }
            }
        }

        return this.list({
            allowCache: false,
            where: where
        }).pipe(
            map(
                (results: DriverActivityTypeModel[]) => results.sort((a, b) => a.name.localeCompare(b.name))
            )
        );
    }
}
