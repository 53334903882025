import {
    Field
} from '@smartsoftware/reflex-core';
import {
    RelatedCompany
} from './company.model';
import {
    ResourceModel
} from './resource.model';


export enum CycleType {
    Monthly = "Monthly",
    SemiMonthly = "SemiMonthly",
    Weekly = "Weekly",
    BiWeekly = "BiWeekly",
    Quarterly = "Quarterly"
}

export enum NotificationType {
    None = "None",
    Email = "Email",
    Mail = "Mail"
}

export class BillingCycleModel extends ResourceModel {

    @Field(Object)
    public company: RelatedCompany = {
        id: "",
        name: ""
    }

    @Field(String)
    public code: string = "";

    @Field(String)
    public name: string = "";

    @Field(String)
    public description: string = "";

    @Field(String)
    public type: CycleType = CycleType.Monthly;

    @Field(Number)
    public billingDay: number = 0;

    @Field(String)
    public notificationType: NotificationType = NotificationType.None

    @Field(Boolean)
    public isNonBilling: boolean = false;

    @Field(Boolean)
    public isRoutingOnly: boolean = false;

    @Field(Number)
    public netTerms: number = 30;

    @Field(String)
    public notes: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }
}
