import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

import { Company } from './companyStatement.model';
import { User } from './driverActivityLog.model';
import { ServiceType } from './serviceType.model';
import { Order } from './order.model';
import { Zone } from './bin.model';

export interface Invoice {
    id: string,
    invoiceNumber: string
}

export interface Transaction {
    date: Date,
    order: Order,
    service: ServiceType,
    perUnit: number,
    units: number,
    volume: number,
    amount: number
}

export class InvoiceModel extends ResourceModel {

    @Field(Object)
    public company: Company = { id: "", name: "" };

    @Field(Object)
    public customer: User = { name: "", id: "" };

    @Field(Object)
    public site: Zone = { name: "", id: "", accountNumber: "" };

    @Field(String)
    public invoice: string = "";

    @Field(Object)
    public order: Order = { id: "", orderNumber: "" };

    @Field(Number)
    public total: number = 0.00

    @Field(Date)
    public dateIssued: Date | null = null;

    @Field(Date)
    public invoicePeriodStart: Date | null = null;

    @Field(Date)
    public invoicePeriodEnd: Date | null = null;

    @Field(String)
    public invoiceStatus: string = "";

    @Field(Object)
    public transactions: Transaction[] = [];

    @Field(String)
    public notes: string = "";

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
