import { NgModule, Injectable } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { } from "googlemaps";

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, AdminContainerComponent, LoginComponent, UserActionControlComponent } from './components';

import { AuthModule } from './modules/auth';
//import { UserProfileModule } from './modules/user-profile';
//import { ResourceModule } from './modules/resource-module';

import { MatSidenavModule } from '@angular/material/sidenav';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core'
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import {
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatDateFormats,
    NGX_MAT_DATE_FORMATS,
    NgxMatDateAdapter
} from '@angular-material-components/datetime-picker';
import {
    StripePaymentsService,
} from './services/';
import {
    FgResourceToolbarModule,
    FgTypeaheadModule,
    PaymentDialogComponent,
    SimpleModalComponent,
    AmountModalComponent,
    MessageBoxModule
} from './components';

const CUSTOM_DATE_INPUT_FORMAT = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hourCycle: 'h12',
    hour: '2-digit',
    minute: '2-digit'
};

export const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
    parse: {
        dateInput: CUSTOM_DATE_INPUT_FORMAT
    },
    display: {
        dateInput: CUSTOM_DATE_INPUT_FORMAT,
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' }
    }
};

@NgModule({
    declarations: [
        AppComponent,
        AdminContainerComponent,
        LoginComponent,
        UserActionControlComponent,
        PaymentDialogComponent,
        SimpleModalComponent,
        AmountModalComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AuthModule,
        CommonModule,
        //ResourceModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxMaskModule.forRoot(),
        CKEditorModule,

        //UserProfileModule,

        MatSidenavModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        MatTabsModule,
        MatIconModule,
        MatDialogModule,
        MatListModule,
        MatMenuModule,
        MatSelectModule,
        MatAutocompleteModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        //CustomDateModule,
        NgxMatTimepickerModule,
        FgResourceToolbarModule,
        FgTypeaheadModule,
        MessageBoxModule
    ],
    providers: [
        Title,
        StripePaymentsService,
        { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
    ],
    bootstrap: [AppComponent],
    exports: [
        FgResourceToolbarModule,
        MessageBoxModule
    ],
    entryComponents: [
        SimpleModalComponent,
        AmountModalComponent
    ]
})
export class AppModule { }
