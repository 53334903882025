import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';

import { Company } from './companyStatement.model';
import { User } from './driverActivityLog.model';
import { DefaultTruck } from './user.model'

export class TruckInspectionModel extends ResourceModel {

    @Field(Object)
    public company: Company = { name: "", id: "" };

    @Field(String)
    public code: string = "";

    @Field(Object)
    public truck: DefaultTruck = { name: "", id: "" };

    @Field(String)
    public trailer: string = "";

    @Field(Object)
    public driver: User = { id: "", name: "" };

    @Field(Object)
    public mechanic: User = { id: "", name: "" };

    @Field(Date)
    public date: Date | null = null;

    @Field(Number)
    public odometer: number = 0;

    @Field(String)
    public results: string = "";

    @Field(String)
    public remarks: string = "";


    @Field(Boolean)
    public fireExtinguisher: boolean = false;
    @Field(Boolean)
    public reflectiveTriangles: boolean = false;
    @Field(Boolean)
    public flagsFlaresFuses: boolean = false;
    @Field(Boolean)
    public spareBulbsFuses: boolean = false;
    @Field(Boolean)
    public spareSealBeam: boolean = false;

    @Field(Boolean)
    public clutch: boolean = false;
    @Field(Boolean)
    public defrostHeater: boolean = false;
    @Field(Boolean)
    public horn: boolean = false;
    @Field(Boolean)
    public mirrors: boolean = false;
    @Field(Boolean)
    public starter: boolean = false;
    @Field(Boolean)
    public steering: boolean = false;
    @Field(Boolean)
    public tachograph: boolean = false;
    @Field(Boolean)
    public windows: boolean = false;
    @Field(Boolean)
    public windshieldWipers: boolean = false;

    @Field(Boolean)
    public airCompressor: boolean = false;
    @Field(Boolean)
    public battery: boolean = false;
    @Field(Boolean)
    public engine: boolean = false;
    @Field(Boolean)
    public oilPressure: boolean = false;
    @Field(Boolean)
    public radiator: boolean = false;
    @Field(Boolean)
    public transmission: boolean = false;

    @Field(Boolean)
    public airLines: boolean = false;
    @Field(Boolean)
    public body: boolean = false;
    @Field(Boolean)
    public couplingDevices: boolean = false;
    @Field(Boolean)
    public exhaust: boolean = false;
    @Field(Boolean)
    public frameAndAssembly: boolean = false;
    @Field(Boolean)
    public fuelTanks: boolean = false;
    @Field(Boolean)
    public muffler: boolean = false;
    @Field(Boolean)
    public rearEnd: boolean = false;
    @Field(Boolean)
    public reflectors: boolean = false;
    @Field(Boolean)
    public otherBody: boolean = false;

    @Field(Boolean)
    public headStop: boolean = false;
    @Field(Boolean)
    public tailDash: boolean = false;
    @Field(Boolean)
    public turnIndicators: boolean = false;

    @Field(Boolean)
    public brakeAccessories: boolean = false;
    @Field(Boolean)
    public brakesParking: boolean = false;
    @Field(Boolean)
    public brakesService: boolean = false;
    @Field(Boolean)
    public driveLine: boolean = false;
    @Field(Boolean)
    public fifthWheel: boolean = false;
    @Field(Boolean)
    public frontAxle: boolean = false;
    @Field(Boolean)
    public suspensionSystem: boolean = false;
    @Field(Boolean)
    public tires: boolean = false;
    @Field(Boolean)
    public tireChains: boolean = false;
    @Field(Boolean)
    public wheelsRims: boolean = false;

    @Field(Boolean)
    public brakeConnections: boolean = false;
    @Field(Boolean)
    public brakes: boolean = false;
    @Field(Boolean)
    public couplingDevice: boolean = false;
    @Field(Boolean)
    public couplingKingPin: boolean = false;
    @Field(Boolean)
    public doors: boolean = false;
    @Field(Boolean)
    public hitch: boolean = false;
    @Field(Boolean)
    public landingGear: boolean = false;
    @Field(Boolean)
    public lightsAll: boolean = false;
    @Field(Boolean)
    public roof: boolean = false;
    @Field(Boolean)
    public suspensionSystemTrailer: boolean = false;
    @Field(Boolean)
    public tarpaulin: boolean = false;
    @Field(Boolean)
    public tiresTrailer: boolean = false;
    @Field(Boolean)
    public wheelsRimsTrailer: boolean = false;
    @Field(Boolean)
    public otherTrailer: boolean = false;

    @Field(String)
    public notes: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
