import { Component, Input, OnInit } from '@angular/core';
import { MediaItem, ListDataSource } from '@smartsoftware/reflex-core';
import { FileUploadService, FileUploadModel, ServiceResponse } from 'src/app/modules';
import html2canvas from 'html2canvas';

@Component({
    selector: 'app-file-manager-pane',
    templateUrl: './file-manager-pane.component.html',
    styleUrls: ['./file-manager-pane.component.scss']
})
export class FileManagerPaneComponent implements OnInit {

    @Input('title')
    public title: string = "Uploads";

    @Input('entityTable')
    public entityTable: string = null;

    @Input('entityId')
    public entityId: string = null;

    @Input('relativePath')
    public relativePath: string = "";

    @Input('acceptedFileExtensions')
    public acceptedFileExtensions: string = "";

    @Input('pdfToImage')
    public pdfToImage: boolean = false;

    public isNew: boolean = false;
    public filesSource: ListDataSource<FileUploadModel> = new ListDataSource<FileUploadModel>()
    public isMaxSize: boolean = false;
    public errorUploading: boolean = false;
    public pdfSrc: any = "";

    // pdf to image private variables;
    private selectedMedia: MediaItem | null = null;
    private selectedModel: FileUploadModel | null = null;

    public displayColumns: string[] = ['filename', 'actions'];

    @Input('includeColumns')
    public includeColumns: boolean = false;

    public spinnerText: string = "";
    public showSpinner: boolean = false;

    constructor(protected fileUploadService: FileUploadService) { }

    ngOnInit(): void {
        this.isNew = this.entityId === "new" || !this.entityId;

        if (this.isNew || !this.entityTable) {
            return;
        }

        if (this.includeColumns) {
            this.displayColumns = ['filename', 'includeWithInvoice', 'includeWithLEEDReport', 'actions'];
        }

        this.getFileUploads();
    }

    /**
     * Downloads selected file
     *
     * @param   {FileUploadModel}  entity  Entity record to pull relevant information.
     *
     * @return  {void}                     Opens window for file download.
     */
    public downloadFile(entity: FileUploadModel): void {
        return this.fileUploadService.downloadFile(entity);
    }

    /**
     * Deletes selected file from dynamo and s3.
     *
     * @param   {FileUploadModel}  entity  Entity record to pull relevant information.
     *
     * @return  {void}                     Deletes relevant information.
     */
    public deleteFile(entity: FileUploadModel): void {
        this.spinnerText = "Deleting...";
        this.showSpinner = true;

        this.fileUploadService.deleteFile(entity)
            .subscribe(() => {
                let temp: FileUploadModel[] = this.filesSource.entities;
                temp = temp.filter((x: FileUploadModel) => x != entity);
                this.filesSource.entities = temp;
                this.showSpinner = false;
            });
    }

    /**
     * When media is selected, will upload file and create an new fileUpload record. If pdf to image is true. Will also generate
     * a dependent fileUpload record and convert pdf to image, uploading that to s3 as well.
     *
     * @param   {MediaItem}  media  SSS Reflex MediaItem object containing file.
     *
     * @return  {void}              Success would insert new file and fileUpload record(s).
     */
    public onNewMedia(media: MediaItem | any): void {
        // Return if no media or file to upload.
        if (!media || !media.file) {
            return;
        }

        // Check to see if file is too big
        if (media.totalBytes / 1048576 > 10) {
            this.isMaxSize = true;
            return;
        }

        // Show spinner
        this.spinnerText = "Saving...";
        this.showSpinner = true;

        // Reset booleans for file size and errors. Set media object to selectedMedia.
        this.isMaxSize = false;
        this.errorUploading = false;
        this.selectedMedia = media;

        // Generate temp model for saving to dynamo/elastic.
        let newFileRecord: FileUploadModel = new FileUploadModel({
            entityTable: this.entityTable,
            entityId: this.entityId,
            relativeFilePath: this.relativePath,
            fileName: media.file.name,
            contentType: media.file.type
        });

        // Upload file, update statuses and table.
        this.fileUploadService.uploadFile(media.file, newFileRecord)
            .subscribe((response: ServiceResponse) => {
                if (response.success) {
                    newFileRecord = new FileUploadModel(response.data.fileUpload);
                    let tempRecords: FileUploadModel[] = this.filesSource.entities;
                    tempRecords.push(newFileRecord);
                    this.filesSource.entities = tempRecords.sort((a, b) => a.fileName.localeCompare(b.fileName));

                    // Check to see if need to add image for pdf uploaded.
                    if (media?.file.type == "application/pdf" && this.pdfToImage) {
                        this.selectedModel = newFileRecord;
                        this.pdfSrc = "";
                        let reader = new FileReader();

                        reader.onload = (e: any) => {
                            this.pdfSrc = e.target.result;
                        };

                        reader.readAsArrayBuffer(media.file);
                    } else {
                        this.showSpinner = false;
                    }

                } else {
                    this.showSpinner = false;
                    this.errorUploading = true;
                }
            });

    }

    /**
     * Gets all entity's fileUpload records that are visible.
     *
     * @return  {void}    Sets data table for fileUpload objects.
     */
    private getFileUploads(): void {
        let query: any = {
            query: {
                bool: {
                    must: [
                        {
                            term: {
                                entityTable: this.entityTable
                            }
                        },
                        {
                            term: {
                                entityId: this.entityId
                            }
                        },
                        {
                            match: {
                                visible: true
                            }
                        }
                    ]
                }
            }
        };

        this.fileUploadService.list({
            where: query,
            allowCache: false
        }).subscribe((results: FileUploadModel[]) => {
            this.filesSource.entities = results.sort((a, b) => a.fileName.localeCompare(b.fileName));
        });
    }

    /**
     * Event handling for when ng2-pdf-viewer finishes rendering the pdf.
     *
     * @return  {void}    Inserts new image and fileUpload records.
     */
    public afterPageRendered(): void {
        html2canvas(document.querySelector(".hidden-pdf-viewer") as HTMLElement).then((canvas: any) => {
            let imageFile: File = this.getCanvasToFile(canvas);

            let dependentFileRecord: FileUploadModel = new FileUploadModel({
                entityTable: this.entityTable,
                entityId: this.entityId,
                relativeFilePath: this.relativePath,
                fileName: this.selectedMedia.file.name.replace(".pdf", ".png"),
                contentType: "image/png",
                parentId: this.selectedModel.id,
                visible: false
            });

            this.fileUploadService.uploadFile(imageFile, dependentFileRecord)
                .subscribe((response: ServiceResponse) => {
                    if (!response.success) {
                        this.errorUploading = true;
                    }
                    this.showSpinner = false;
                });
        });
    }

    /**
     * Converts a rendered html canvas of the pdf to a new png file.
     *
     * @param   {any}   canvas  html canvas where the pdf is rendered
     *
     * @return  {File}          New png image file from the pdf.
     */
    private getCanvasToFile(canvas: any): File {
        let ctx = canvas.getContext('2d');
        ctx.scale(3, 3);

        let image: any = canvas.toDataURL("image/png");
        let blob: Blob = this.dataURLtoBlob(image);
        let imageFile: File = new File([blob], "my-image.png", { type: "image/png" });
        console.log(imageFile);
        return imageFile;
    }

    /**
     * Takes in a dataUrl object and returns a blob.
     *
     * @param   {any}   dataUrl  dataUrl object to covert.
     *
     * @return  {Blob}           Blob object.
     */
    private dataURLtoBlob(dataUrl: any): Blob {
        var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    /**
     * Event function that will save changes to FileUpload record
     *
     * @param   {any}              event       MatCheckbox event containing value to set
     * @param   {FileUploadModel}  fileUpload  FileUpload record to update
     * @param   {string}           value       String representing whether to update invoice or Recycled Materials Summary report include value.
     *
     * @return  {void}                         Updates the FileUpload record.
     */
    public saveFileUpload(event: any, fileUpload: FileUploadModel, value: string): void {
        if (value == "invoice") {
            fileUpload.includeWithInvoice = event.checked;
        } else {
            fileUpload.includeWithLEEDReport = event.checked;
        }

        this.fileUploadService.push(fileUpload).subscribe(() => { });
    }
}

