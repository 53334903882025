import { Component } from '@angular/core';

import { ResourceToolbar as ReflexResourceToolbar } from '@smartsoftware/reflex-core';

@Component({
    selector: 'user-profile-resource-toolbar',
    templateUrl: './resource-toolbar.component.html',
    styleUrls: ['./resource-toolbar.component.scss']
})
export class UserProfileResourceToolbarComponent extends ReflexResourceToolbar {

}
