import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

import {
    GPSEntryModel
} from './gpsEntry.model'

export class TripModel extends ResourceModel {

    @Field(String)
    public truckId: string = "";

    @Field(String)
    public driverId: string = "";

    @Field(String)
    public orderId: string = "";

    @Field(String)
    public startTime: Date = new Date();

    @Field(String)
    public endTime: Date = new Date();

    public gpsEntries: GPSEntryModel[] = [];

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
