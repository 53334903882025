import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ApiClientService, ServiceResponse } from './api-client.service'
import { ResourceService } from './resource.service';
import { Auth } from '../../auth/services/auth.service';

import { Router } from '@angular/router';

import { UserModel } from '../models';

@Injectable({
    providedIn: 'root'
})
/**
 * Service for making API requests to the /Auth API Gateway endpoint.
 *
 * TODO subscribe to Auth's credentialsUpdatedEvent and make request fetch
 * latest identity info from AWS API Gateway.
 */
export class AuthResourceService extends ResourceService {

    protected servicePath : string = '/Auth';

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService,
        protected _auth: Auth,
        protected _router: Router
    ) {
        super(http, _apiClient);

        this.maintainAuth();
    }

    /**
     * TODO testing. Refresh authentication token 30 minutes before it is due to expire.
     */
    private maintainAuth() {
        if(this._auth.credentials && this._auth.credentials['expiresAt']) {
            let authExpires = (this._auth.credentials['expiresAt'] - new Date().getTime()) || 0;
            if(authExpires < 1800000) {
                this.refresh().subscribe(()=>{});
                setTimeout(() => {
                    this.maintainAuth();
                }, 1800000);
            } else {
                setTimeout(() => {
                    this.maintainAuth();
                }, 1800000-authExpires);
            }
        }
    }


    /**
     * Make API request to AWS API Gateway Auth endpoint to start the InitAuth
     * workflow via refresh token. Store credentials in application's Auth
     * service, which will handle loading and maintaining session/tokens.
     */
    public refresh() : Observable<ServiceResponse> {
        let credentials: RefreshCredentials = {
            refreshToken: this._auth.credentials && this._auth.credentials['refreshToken'] ? this._auth.credentials['refreshToken'] : ''
        };
        return this._apiClient.post(
            this.serviceUrl + 'refresh',
            credentials,
            {
                observe: 'response'
            }
        ).pipe(
            map(
                (response: ServiceResponse) => {
                    if(response.success) {
                        if(response.data['AuthenticationResult']) {
                            // Successful login, update credentials in the auth
                            // service.
                            this._auth.credentials = {
                                accessToken: response.data['AuthenticationResult']['AccessToken'],
                                refreshToken: this._auth.credentials['refreshToken'], // don't lose this, we only get one when the user signs in with their password.
                                expiresAt: new Date().getTime() + ((response.data['AuthenticationResult']['ExpiresIn'] || 0) * 1000)
                            }
                        } else if (response.data['ChallengeName'] && response.data['Session']) {
                            // Successful login, but the account must complete
                            // an auth challenge before being fully
                            // authenticated.
                            // Provided session token will be needed for the
                            // auth challenge.
                            response.success = false;
                            this._auth.credentials = {
                                Session: response.data['Session']
                            }

                            // TODO trigger ChallengeName workflow / route.
                            this._router.navigate(['/auth', (<String> response.data['ChallengeName']).toLowerCase()])
                        }
                    }
                    return response;
                }
            )
        ).pipe(
            catchError(this.handleError)
        );
    }

    /**
     * Make API request to AWS API Gateway Auth endpoint to start the InitAuth
     * workflow. Store credentials in application's Auth service, which will
     * handle loading and maintaining session/tokens.
     */
    public authenticate(credentials: AuthenticationCredentials) : Observable<ServiceResponse> {
        return this._apiClient.post(
            this.serviceUrl + 'login',
            credentials,
            {
                observe: 'response'
            }
        ).pipe(
            map(
                (response: ServiceResponse) => {
                    if(response.success) {
                        if(response.data['AuthenticationResult']) {
                            // Successful login, update credentials in the auth
                            // service.
                            this._auth.credentials = {
                                accessToken: response.data['AuthenticationResult']['AccessToken'],
                                refreshToken: response.data['AuthenticationResult']['RefreshToken'],
                                expiresAt: new Date().getTime() + ((response.data['AuthenticationResult']['ExpiresIn'] || 0) * 1000),
                                email: credentials.username
                            }
                        } else if (response.data['ChallengeName'] && response.data['Session']) {
                            // Successful login, but the account must complete
                            // an auth challenge before being fully
                            // authenticated.
                            // Provided session token will be needed for the
                            // auth challenge.
                            response.success = false;
                            this._auth.credentials = {
                                Session: response.data['Session'],
                                email: credentials.username
                            }

                            // TODO trigger ChallengeName workflow / route.
                            this._router.navigate(['/auth', (<String> response.data['ChallengeName']).toLowerCase()])
                        }
                    }
                    return response;
                }
            )
        ).pipe(
            catchError(this.handleError)
        );
    }

    /**
     * Returns the currently authenticated cognito user.
     */
    public getCurrentUser() : Observable<ServiceResponse> {
        //console.log(this.serviceUrl + 'user');
        return this._apiClient.get(
            this.serviceUrl + 'user',
            {
                observe: 'response'
            }
        );
    }

    /**
     * Returns the cognito groups that currently authenticated cognito user
     * belongs to.
     */
    public getCurrentUserGroups() : Observable<ServiceResponse> {
        return this._apiClient.get(
            this.serviceUrl + 'groups',
            {
                observe: 'response'
            }
        );
    }

    /**
     * Submit an auth challenge response when presented with one during
     * authentication.
     */
    public respondToAuthChallenge(challengeName: string, challengeResponses: {[key: string]: any}) : Observable<ServiceResponse> {
        return this._apiClient.post(
            this.serviceUrl + 'challenge',
            {
                challengeName: challengeName,
                challengeResponses: challengeResponses,
                session: this._auth.credentials['Session']
            },
            {
                observe: 'response'
            }
        ).pipe(
            map(
                (response: ServiceResponse) => response
            )
        ).pipe(
            catchError(this.handleError)
        );
    }

    /**
     * Start password recovery workflow (sends user a verification code).
     */
    public forgotPassword(username: string) : Observable<ServiceResponse> {
        return this._apiClient.post(
            this.serviceUrl + 'user/forgotPassword',
            {
                username: username
            },
            {
                observe: 'response'
            }
        ).pipe(
            map(
                (response: ServiceResponse) => response
            )
        ).pipe(
            catchError(this.handleError)
        );
    }

    /**
     * Finish forgot password workflow by supplying new password and
     * verification code.
     */
    public confirmForgotPassword(username: string, code: string, password: string) : Observable<ServiceResponse> {
        return this._apiClient.post(
            this.serviceUrl + 'user/confirmForgotPassword',
            {
                username: username,
                code: code,
                password: password
            },
            {
                observe: 'response'
            }
        ).pipe(
            map(
                (response: ServiceResponse) => response
            )
        ).pipe(
            catchError(this.handleError)
        );
    }

    /**
     * Finish forgot password workflow by supplying new password and
     * verification code.
     */
    public verifyUserAttribute(attributeName: string, code: string) : Observable<ServiceResponse> {
        return this._apiClient.post(
            this.serviceUrl + 'user/verifyAttribute',
            {
                code: code,
                attributeName: attributeName
            },
            {
                observe: 'response'
            }
        ).pipe(
            map(
                (response: ServiceResponse) => response
            )
        ).pipe(
            catchError(this.handleError)
        );
    }

    /**
     * Allows a user to change their password.
     */
    public changePassword(oldPassword: string, newPassword: string) : Observable<ServiceResponse> {
        return this._apiClient.post(
            this.serviceUrl + 'user/changePassword',
            {
                oldPassword: oldPassword,
                newPassword: newPassword
            },
            {
                observe: 'response'
            }
        ).pipe(
            map(
                (response: ServiceResponse) => response
            )
        ).pipe(
            catchError(this.handleError)
        );
    }

    /**
     * Resends the verification code
     */
    public resendVerification(username: string) : Observable<ServiceResponse> {
        return this._apiClient.post(
            this.serviceUrl + 'resendVerification',
            {
                username: username
            },
            {
                observe: 'response'
            }
        ).pipe(
            map(
                (response: ServiceResponse) => response
            )
        ).pipe(
            catchError(this.handleError)
        );
    }

    /**
     * Make API request to AWS API Gateway Auth endpoint to logout. Have
     * AuthService clear local session/token data.
     * TODO
     */
    public logout(): Observable<ServiceResponse> {
        return this._apiClient.post(
            this.serviceUrl + 'logout',
            null,
            {
                observe: 'response'
            }
        ).pipe(
            map(
                (response: ServiceResponse) =>  {
                    return response
                }
            )
        ).pipe(
            catchError(this.handleError)
        );
    }
}

export interface AuthenticationCredentials {
	username: string;
	password: string;
}

export interface RefreshCredentials {
    refreshToken: string;
}

export interface CreateUserEventBody {
    username?: string;
    email?: string;
    password? :string;
    phone_number? : string;
    given_name? : string;
    family_name? : string;
    email_verified?: string;
    phone_number_verified?: string;
}
