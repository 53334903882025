import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-amount-modal',
    templateUrl: './amount-modal.component.html',
    styleUrls: ['./amount-modal.component.scss']
})
export class AmountModalComponent {

    public invoiceTotal: number = 0.00;
    public remainingBalance: number = 0.00;
    public currencyRegex: RegExp = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/

    public form: FormGroup = new FormGroup({
        amount: new FormControl(0.00, [])
    });

    constructor(
        protected dialog: MatDialogRef<AmountModalComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: any,
    ) {
        this.invoiceTotal = data.invoiceTotal ? data.invoiceTotal : this.invoiceTotal;
        this.remainingBalance = data.amountPaid ? data.amountPaid : this.remainingBalance;

        this.form.get('amount').setValue((this.invoiceTotal - this.remainingBalance).toFixed(2));
        this.form.get('amount').setValidators(
            [
                Validators.required,
                Validators.min(0.01),
                Validators.max(this.invoiceTotal - this.remainingBalance),
                Validators.pattern(this.currencyRegex)
            ]
        );
    }

    public pay(): void {
        this.dialog.close({
            action: true,
            amount: this.form.get('amount').value
        });
    }

    public cancel(): void {
        this.dialog.close({
            action: false
        });
    }
    
}
