import { Injectable } from '@angular/core'

import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { UserModel } from '../../resource-module';

/**
 * Class for maintaining and retrieiving locally stored auth tokens and
 * providing them when necessary. Emitts events when auth info changes for UI
 * components to respond accordingly.
 */
@Injectable({
    providedIn: 'root'
})
export class Auth {

    protected _authCredentials: AuthCredentials | null = null;

    protected _credentialsEventSource: BehaviorSubject<AuthCredentials | null> = new BehaviorSubject(this._authCredentials);
    public credentialsUpdatedEvent: Observable<AuthCredentials | null> = this._credentialsEventSource.asObservable();

    constructor() {
        // check for auth credentials in localstorage when first initialized.
        let auth = localStorage.getItem('auth');
        if (auth) {
            this.credentials = <AuthCredentials | null>JSON.parse(window.atob(auth));
        }
    }

    /**
     * Retrieve the user's credentials.
     */
    public get credentials(): AuthCredentials {
        return this._authCredentials;
    }

    /**
     * Set the user's current credentials and persist locally for quick
     * retrieval.
     * Emit event whenever this changes so that other UI components/services
     * can subscribe and update accordingly.
     */
    public set credentials(creds: AuthCredentials | null) {
        localStorage.setItem('auth', window.btoa(JSON.stringify(creds)));
        this._authCredentials = creds;
        this._credentialsEventSource.next(this.credentials);
    }

    /**
     * Set credentials without sending update events (used in registration).
     */
    public silentSetCredentials(creds: AuthCredentials) {
        localStorage.setItem('auth', window.btoa(JSON.stringify(creds)));
        this._authCredentials = creds;
    }

    /**
     * Clear all stored identity and credential data. Emit event to update
     * relevate UI components.
     */
    public clearLocal() {
        localStorage.removeItem('auth');

        //        this.identity = null;
        this._authCredentials = null;

        //        this._identyEventSource.next(null);
        this._credentialsEventSource.next(null);
    }

    public setUserProfile(user: any): void {
        window.sessionStorage.setItem('user', JSON.stringify(user));
    }

    public getUserProfile(): UserModel {
        return JSON.parse(window.sessionStorage.getItem('user')) as UserModel;
    }
}

export interface AuthCredentials {
    accessToken?: string,
    [key: string]: any
}
