import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthResourceService, ServiceResponse } from '../../../resource-module';

@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {

    public message: string = '';

    public passwordForm:FormGroup = new FormGroup({
        oldPassword: new FormControl('', [Validators.required]),
        newPassword: new FormControl('', [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(255),
        ]),
        confirmPassword: new FormControl('', [
            Validators.required,
        ])
    });

    constructor(
        protected _router: Router,
        protected _authResourceService: AuthResourceService
    ) {}

    public submit() {

        let values = this.passwordForm.value;
        this.message = '';

        this._authResourceService.changePassword(values['oldPassword'], values['newPassword']).subscribe((response: ServiceResponse) => {
            console.log('response: ', response);
            if (response.success) {
                this._router.navigate(['/']);
            } else {
                this.message = 'Debug message: ' + response.message;
            }
        });
    }

}

