import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable, merge } from 'rxjs/index';
import { map, tap } from 'rxjs/operators';

import {
    SearchResponse,
    ResourceService as ReflexResourceService,
    ExtendedResourceService as ReflexExtendedResourceService,
    ResourceListOptions
} from '@smartsoftware/reflex-core';

import { ApiClientService } from './api-client.service';
import {
    ResourceModel,
    ExtendedResourceModel,
    UserModel,
} from '../models';

@Injectable({
    providedIn: 'root'
})
/**
 * TODO overload ReflexResourceService functions and use ApiClientService in place of
 * HttpClient for requests that we want to have auth headers/tokens added.
 */
export class ResourceService<M extends ResourceModel = ExtendedResourceModel> extends ReflexResourceService<M> {

    public companyCheckList: string[] = ['/Bin', '/CompanyStatement', '/DriverActivityLog', '/DriverActivityType', '/Incident', '/Role',
        '/Landfill', '/Material', '/Truck', '/TruckInspection', '/TruckMaintenanceLog', '/TruckMaintenanceType', '/FuelLog', '/FuelLocation',
        '/Customer', '/BillingCycle', '/Batch'];
    public customerCheckList: string[] = ['/Quote', '/Invoice', '/Order']

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http);
    }

    public get(id: string): Observable<M | undefined> {
        return this._apiClient
            .get(
                this.serviceUrl + id,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map<any, M>(
                    (response) =>
                        response ? new this.ModelType(response) : undefined
                )
            );
    }

    public push(entry: M): Observable<M | undefined> {
        // For elastic provider
        if (entry.id)
            entry.updatedAt = new Date();

        return this._apiClient
            .request
            (
                entry.id ? 'put' : 'post',
                this.serviceUrl + (entry.id || ''),
                {
                    observe: 'response',
                    body: entry.data
                }
            )
            .pipe(
                map<any, M>(
                    (response) =>
                        response ? new this.ModelType(response) : undefined
                )
            );
    }

    /**
     * rlouro 10-10-2020 - defaulting allowCache to 'false' to prevent caching.
     * When the application is run in a prod setting, the model names are
     * minified resulting in caching collisions.
     */
    public list(options: ResourceListOptions = { allowCache: false }): Observable<M[]> {

        let sources: Observable<M[]>[] = [];

        let cachePrefix = options.where ? window.btoa(options.where).replace(/=+$/, '') : '';

        // Load the cache unless explicitly requested not to
        if (options.allowCache !== false)
            sources.push(
                this.listFromCache(cachePrefix)
            )

        // Dynamically build request options to send to server
        let requestOptions: any = {
            observe: 'response'
        };

        if (this.companyCheckList.includes(this.servicePath)) {
            let profile: UserModel = this.getUserProfile();
            let isSuperAdmin: boolean = profile?.roleData?.isSuperAdmin == true;

            if (!isSuperAdmin) {
                if (!options?.where) {
                    options.where = {}
                }

                if (!options.where.query) {
                    options.where.query = {}
                }

                if (!options.where.query.bool) {
                    options.where.query.bool = {}
                }

                if (!options.where.query.bool.must)
                    options.where.query.bool.must = [];

                if (options.where) {
                    if (!options.where.query.bool.must.find((x: any) => x?.nested?.path == "company")) {
                        options.where.query.bool.must.push({
                            nested: {
                                path: "company",
                                query: {
                                    term: {
                                        "company.id": profile?.company?.id
                                    }
                                }
                            }
                        })
                    }
                } else {
                    options.where = {
                        query: {
                            bool: {
                                must: [
                                    {
                                        nested: {
                                            path: "company",
                                            query: {
                                                term: {
                                                    "company.id": profile?.company?.id
                                                }
                                            }
                                        }
                                    }
                                ]
                            }
                        }
                    }
                }
            }
        }

        if (this.customerCheckList.includes(this.servicePath)) {
            let profile: UserModel = this.getUserProfile();
            let isCustomer: boolean = profile?.roleData?.isCustomer == true;

            if (isCustomer) {
                if (options.where) {
                    if (!options.where.query.bool.must.find((x: any) => x?.nested?.path == "customer")) {
                        options.where.query.bool.must.push({
                            nested: {
                                path: "customer",
                                query: {
                                    term: {
                                        "customer.id": profile?.customerId
                                    }
                                }
                            }
                        })
                    }
                } else {
                    options.where = {
                        query: {
                            bool: {
                                must: [
                                    {
                                        nested: {
                                            path: "customer",
                                            query: {
                                                term: {
                                                    "customer.id": profile?.customerId
                                                }
                                            }
                                        }
                                    }
                                ]
                            }
                        }
                    }
                }
            }
        }

        // Added to increase the size of dropdown lists
        if (!options.where) {
            options.where = {
                size: 1000
            }
        }

        // Add in where clause if it came in with options
        if (options.where)
            requestOptions.body = options.where;

        // Reload from server unless explicitly requested not to
        if (options.reloadFresh !== false)
            sources.push(
                this._apiClient
                    .request
                    (
                        options.where ? 'post' : 'get',
                        this.serviceUrl + (options.where ? 'elastic' : ''),
                        requestOptions
                    )
                    .pipe(
                        map<any, M[]>(
                            response =>
                                response.map(e => new this.ModelType(e))
                        ),
                        // rlouro 10-10-2020 removed due to error involving model name minification resulting in caching collisions when run with prod settings.
                        //                        tap(
                        //                            // Update the cache for future loads
                        //                            (entities) => this.listToCache(cachePrefix, entities)
                        //                        )
                    )
            )

        return merge<M[], M[]>(...sources);
    }

    public search(query: any): Observable<SearchResponse<M>> {

        // Added to increase the size of dropdown lists
        if (!query.size) {
            query.size = 1000;
        }

        if (this.companyCheckList.includes(this.servicePath)) {
            let profile: UserModel = this.getUserProfile();
            let isSuperAdmin: boolean = profile?.roleData?.isSuperAdmin == true;

            if (!isSuperAdmin) {
                if (!query.query.bool.must.find((x: any) => x?.nested?.path == "company")) {
                    query.query.bool.must.push({
                        nested: {
                            path: "company",
                            query: {
                                term: {
                                    "company.id": profile?.company?.id
                                }
                            }
                        }
                    })
                }
            }
        }

        if (this.customerCheckList.includes(this.servicePath)) {
            let profile: UserModel = this.getUserProfile();
            let isCustomer: boolean = profile?.roleData?.isCustomer == true;

            if (isCustomer) {
                if (!query.query.bool.must.find((x: any) => x?.nested?.path == "customer")) {
                    query.query.bool.must.push({
                        nested: {
                            path: "customer",
                            query: {
                                term: {
                                    "customer.id": profile?.customerId
                                }
                            }
                        }
                    })
                }
            }
        }

        return this.http
            .post(
                this.serviceUrl + 'elastic',
                query,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (serverResponse: HttpResponse<any[]>) => {
                        let records = serverResponse.body;

                        let response = new SearchResponse<M>();
                        response.totalRecords = parseInt(serverResponse.headers.get('totalrecords')) || records.length;
                        response.results = records.map(r => new this.ModelType(r));

                        return response;
                    }
                )
            );
    }

    /**
     * TODO formalize return typings?
     * Run a query to retrieve raw elastic response so that score can be
     * returned.
     */
    public searchRaw(query: any): Observable<{
        totalRecords: number,
        aggregations: any,
        results: Array<{
            record: M,
            score: number
        }>
    }> {
        return this.http
            .post(
                this.serviceUrl + 'elastic?raw=1',
                query,
                {
                    observe: 'response'
                }
            )
            .pipe(
                map(
                    (serverResponse: HttpResponse<any[]>) => {
                        let records: any = serverResponse.body;
                        let response: any = {};
                        response.totalRecords = parseInt(serverResponse.headers.get('totalrecords')) || records['data']['hits']['value'];
                        response.results = (<any[]>records['data']['hits']['hits']).map((result: any) => {
                            return {
                                record: new this.ModelType(result['_source']),
                                score: result['_score']
                            }
                        });
                        response.aggregations = records['data']['aggregations'];
                        return response;
                    }
                )
            );
    }

    public delete(entry: M): Observable<M | undefined> {
        return this._apiClient
            .request
            (
                'delete',
                this.serviceUrl + (entry.id),
                {
                    observe: 'response'
                }
            )
            .pipe(
                map<any, M>(
                    (response) =>
                        response ? new this.ModelType(response) : undefined
                )
            );
    }

    public compareModels(a: any, b: any): boolean {
        if (!a || !b) {
            // Note: Not sure if this should be returning true or false.
            return false;
        }

        return a.id == b.id;
    }

    protected getUserProfile(): UserModel {
        return JSON.parse(window.sessionStorage.getItem('user')) as UserModel;
    }
}

@Injectable()
export class ExtendedResourceService<M extends ResourceModel = ExtendedResourceModel> extends ResourceService<M> {

}
