import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'
import { Observable, of } from 'rxjs/index';
import { InvoiceModel } from '../models/invoice.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { flatMap, map } from 'rxjs/operators';
import { Order } from '../models/order.model';
import { OrderModel } from '../models/order.model';
import { Transaction } from '../models/invoice.model';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService extends ResourceService<InvoiceModel> {
    protected servicePath: string = '/Invoice';

    public ModelType = InvoiceModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public flatInvoice(invoice: InvoiceModel): any {
        return {
            id: invoice.id,
            invoiceNumber: invoice.invoice
        }
    }

    public updateOrderData(orderForm) {
        this.get(orderForm.get("invoice").value?.id).subscribe((invoice: InvoiceModel) => {

            if (!invoice.dateIssued || new Date(orderForm.get("createdAt").value).getTime() > new Date(invoice.dateIssued).getTime()) {
                invoice.order = { id: orderForm.get("id").value, orderNumber: orderForm.get("orderId").value };

                invoice.dateIssued = orderForm.get("createdAt").value;

                let statusLog = orderForm.get("statusLog").value;
                statusLog.sort(function (a, b) { return a.start.valueOf() - b.start.valueOf(); });
                if (!invoice.invoicePeriodStart || new Date(invoice.invoicePeriodStart).getTime() > new Date(statusLog[0].start).getTime()) {
                    invoice.invoicePeriodStart = statusLog[0].start;
                }
                statusLog.sort(function (a, b) { return b.end.valueOf() - a.end.valueOf(); });
                if (!invoice.invoicePeriodEnd || new Date(invoice.invoicePeriodEnd).getTime() < new Date(statusLog[0].end).getTime()) {
                    invoice.invoicePeriodEnd = statusLog[0].end;
                }
            }

            let temp: Transaction[] = invoice.transactions.filter(function (x) { return x.order?.id != orderForm.get("id").value; });
            orderForm.get("services").value.forEach(element => {
                if (element.active) {
                    temp.push({
                        date: orderForm.get("createdAt").value,
                        order: {
                            id: orderForm.get("id").value,
                            orderNumber: orderForm.get("orderId").value
                        },
                        service: element.service,
                        perUnit: element.cost / element.units,
                        units: element.units,
                        volume: element.volume,
                        amount: element.cost
                    })
                }
            });
            invoice.transactions = temp;

            invoice.total = temp.reduce((accumulator, object) => {
                return accumulator + object.amount;
            }, 0);

            this.push(invoice).subscribe();
        });
    }

    /**
     * Gets Invoice records, specific to a Company if companyId is provided.
     *
     * @param   {string<InvoiceModel>[]}      companyId  Optional parameter to return Company specific Invoice records.
     *
     * @return  {Observable<InvoiceModel>[]}             Returns Invoice records if any.
     */
    public getInvoices(companyId: string = null): Observable<InvoiceModel[]> {
        let where: any = {
            query: {
                bool: {
                    must: [],
                    must_not: [
                        {
                            exists: {
                                field: "deletedAt"
                            }
                        }
                    ]
                }
            },
            size: 1000
        };

        if (companyId) {
            where.query.bool.must.push({
                nested: {
                    path: "company",
                    query: {
                        term: {
                            "company.id": companyId
                        }
                    }
                }
            });
        }

        return this.list({
            allowCache: false,
            where: where
        }).pipe(
            map(
                (results: InvoiceModel[]) => results.sort((a, b) => a.invoice.localeCompare(b.invoice))
            )
        );
    }
}
