import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { TruckModel } from '../models/truck.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TruckService extends ResourceService<TruckModel> {
    protected servicePath: string = '/Truck';

    public ModelType = TruckModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public flatType(type: TruckModel): any {
        return {
            id: type.id,
            name: type.name
        }
    }

    /**
     * Returns a list of trucks, filtered by company if company id is provided.
     *
     * @param   {string}                    companyId  Optional parameter to filter trucks by company.
     *
     * @return  {Observable<TruckModel>[]}             List of truck records.
     */
    public getTrucks(companyId: string = null): Observable<TruckModel[]> {
        let where: any = {
            query: {
                bool: {
                    must: [],
                    must_not: [
                        {
                            exists: {
                                field: "deletedAt"
                            }
                        }
                    ]
                }
            },
            size: 1000
        }

        if (companyId) {
            where.query.bool.must.push({
                nested: {
                    path: "company",
                    query: {
                        term: {
                            "company.id": companyId
                        }
                    }
                }
            });
        }

        return this.list({
            allowCache: false,
            where: where
        }).pipe(
            map((results: TruckModel[]) => {
                return results.sort((a, b) => a.name.localeCompare(b.name));
            })
        );
    }
}
