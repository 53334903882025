import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { TruckInspectionModel } from '../models/truckInspection.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';

@Injectable({
    providedIn: 'root'
})
export class TruckInspectionService extends ResourceService<TruckInspectionModel> {
    protected servicePath: string = '/TruckInspection';

    public ModelType = TruckInspectionModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }
    
    public flatInspection(inspection: TruckInspectionModel): any {
        return {
            id: inspection.id,
            code: inspection.code
        }
    }
}
