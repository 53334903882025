import { Injectable } from '@angular/core';

import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import {
    UserService,
} from '../../../modules/resource-module/services/user.service';

import {
    ServiceResponse
} from '../../../modules/resource-module/services/api-client.service'

import { ProfileService } from '../../user-profile';

import { Observable, BehaviorSubject, Subject, Subscription } from 'rxjs';

import { ReflexEnvironment as Environment, SearchResponse } from '@smartsoftware/reflex-core';
import { GPSEntryService } from '../../resource-module';
import { RouteTrackingDeviceService, RouteTrackingDeviceModel } from '../../resource-module/';

export interface GPSPoint {
    lat: number;
    lng: number;
    logTime: Date;
}

export interface GPSData {
    orderId: string;
    truckId: string;
    driverId: string;
    gpsPoints: GPSPoint[];
}

@Injectable({
    providedIn: 'root'
})
export class RouteTrackingService {

    /**
     * Singleton containing the current user profile for the app.
     */
    protected _gpsData: GPSData = this.getQueuedData() || {
        orderId: "",
        truckId: "",
        driverId: "",
        gpsPoints: []
    };

    /**
     * Fetch the user profile.
     */
    public get gpsData(): GPSData | null {
        return this._gpsData || this.getQueuedData();
    }

    protected _isRecording: boolean = false;

    /**
     * Update the user profile and emit event to services/components listening
     * to changes.
     */
    public set gpsData(data: GPSData | null) {
        this._gpsData = data;
        localStorage.setItem('gpsData', JSON.stringify(data))
    }

    protected _subscriptions: Array<Subscription> = [];

    constructor(
        protected _profileService: ProfileService,
        protected _routeTrackingDeviceService: RouteTrackingDeviceService,
        protected _gpsEntryService: GPSEntryService,
        protected _router: Router
    ) {
        // TODO: Use cordova plugin to get the actual UUID of device
        let deviceId = window.localStorage.getItem('deviceId');
        //let deviceId = "TESTUUID";
        
        // Only record if this is a device currently using the mobile app.
        if(deviceId && deviceId != '')
        {
            this._routeTrackingDeviceService.checkCurrentDevice(deviceId).subscribe((response) => {
                let device = new RouteTrackingDeviceModel(response);
                if(device.assignedTruckId) {
                    let data = this.gpsData
                    data.truckId = device.assignedTruckId;
                    this.gpsData = data;
                }
                // If the device isn't assigned to a company and the user is logged in
                if(!device.company && this._profileService.userModel && this._profileService.userModel?.company?.id != "") {
                    // Update the device to belong to the company of the logged in user
                    device.company = {
                        id: this._profileService.userModel?.company?.id,
                        name: this._profileService.userModel?.company?.name
                    }
                    this._routeTrackingDeviceService.push(device).subscribe(()=>{});
                }
            })
            this.startDataRecording();
        }
    }

    public getQueuedData(): GPSData {
        return JSON.parse(window.localStorage.getItem('gpsData')) as GPSData;
    }

    public startDataRecording() {
        // Every minute, store the gps point locally
        if(!this._isRecording) {
            this._isRecording = true;
            this.grabLocation();
            this.startDataSending();
        }
    }

    public grabLocation() {
        navigator.geolocation.getCurrentPosition((pos) => {
            console.log("GPS info gathered:");
            console.log(pos.coords);
            let point: GPSPoint = {
                lat: pos.coords.latitude,
                lng: pos.coords.longitude,
                logTime: new Date()
            };
            let data = this.gpsData;
            //let truck = JSON.parse(localStorage.getItem('assignedTruck'));
            //data.truckId = truck.id || '';
            data.driverId = this._profileService.userModel?.id.toString();
            if(this._isRecording)
                data.gpsPoints.push(point);
            this.gpsData = data;
        }, (error) => {
            console.log("Error gathering GPS info:");
            console.log(error);
        }, {
            timeout: 30000,
            enableHighAccuracy: true
        })
        
        setTimeout(this.grabLocation.bind(this), 60000);
    }

    public stopDataRecording() {
        this._isRecording = false;
        this.grabLocation()
        this.sendData();
    }

    public startDataSending() {
        setTimeout(this.sendData.bind(this), 600000);
    }

    public sendData() {
        // Only send data if we're assigned to a truck, otherwise it's pointless
        if(this._isRecording && this.gpsData.truckId) {
            this._gpsEntryService.bulkCreate(this.gpsData).subscribe(() => {
                // Clear out the gps points we just sent
                let data = this.gpsData;
                data.gpsPoints = [];
                this.gpsData = data;
            }, (err: any) => {
                // Handle errors depending on what it is
            })
        }
        
        setTimeout(this.sendData.bind(this), 600000);
    }


    public getDistanceFromLatLonInMi(lat1: number, lon1: number, lat2: number, lon2: number) {
        var R = 3963; // Radius of the earth in mi
        var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
        var dLon = this.deg2rad(lon2-lon1); 
        var a = 
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
          Math.sin(dLon/2) * Math.sin(dLon/2)
          ; 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in mi
        return d;
      }
      
      public deg2rad(deg: number) {
        return deg * (Math.PI/180)
      }
}
