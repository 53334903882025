import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import {
    UserProfileEditComponent,
    UserProfileResourceToolbarComponent,
    ChangePasswordDialog,
    CloseAccountDialog,
    ConfirmCloseAccountDialog

} from './components';

import {
    ProfileService
} from './services';


import { AestheticsModule, ResourceModule } from '@smartsoftware/reflex-core';

import { RoutingModule } from './routing.module';

@NgModule({
    imports: [
        CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatTabsModule,
        MatIconModule,
        NgxMaskModule,

		RouterModule,

		//     UiCommonModule,

		ResourceModule,
		//NavigationModule,
		AestheticsModule,

		RoutingModule
    ],
    exports: [],
    declarations: [
        UserProfileEditComponent,
        UserProfileResourceToolbarComponent,
        ChangePasswordDialog,
        CloseAccountDialog,
        ConfirmCloseAccountDialog
    ],
    entryComponents: [
    ],
    providers: [
    ]
})
export class UserProfileModule {}
