import { Component, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import {
    AuthResourceService,
    ServiceResponse
} from '../../../../resource-module';

@Component({
    selector: 'confirm-forgot-password',
    templateUrl: './confirm-forgot-password.component.html',
    styleUrls: ['./confirm-forgot-password.component.scss']
})
export class ConfirmForgotPasswordComponent implements OnDestroy {

    public message: string = '';

    public passwordForm:FormGroup = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        code: new FormControl('', [Validators.required]),
        password: new FormControl('', [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(255)
        ]),
        confirmPassword: new FormControl('', [
            Validators.required,
        ])
    });

    protected _subscriptions: Subscription[] = [];

    constructor(
        protected _activatedRoute: ActivatedRoute,
        protected _router: Router,
        protected _authResourceService: AuthResourceService
    ) {
    }

    ngOnDestroy() {
        this._subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    public submit() {

        let values = this.passwordForm.value;
        this.message = '';

        // TODO what do if user does not have validation code? (did not follow link provided in email).
        if(values['email'].length == 0 || values['code'].length == 0) {
            return;
        }

        if(!values['password'] === values['confirmPassword']) {
            this.message = 'Passwords do not match';
            return;
        }

        this._authResourceService.confirmForgotPassword(values['email'], values['code'], values['password']).subscribe((response: ServiceResponse) => {
            if (response.success) {
                this._router.navigate(['/']);
            } else {
                this.message = response.message;
            }
        });
    }

}
