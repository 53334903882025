import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { CompanyStatementModel } from '../models';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyStatementService extends ResourceService<CompanyStatementModel> {
    protected servicePath: string = '/CompanyStatement';

    public ModelType = CompanyStatementModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }
}
