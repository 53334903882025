import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';

import { Company } from './companyStatement.model';
import { User } from './driverActivityLog.model';
import { DefaultTruck } from './user.model'

export class IncidentModel extends ResourceModel {

    @Field(Object)
    public company: Company = { name: "", id: "" };

    @Field(String)
    public type: string = "";

    @Field(Object)
    public vehicle: DefaultTruck = { name: "", id: "" };

    @Field(Date)
    public date: Date | null = null;

    @Field(Object)
    public reportedBy: User = { id: "", name: "" };

    @Field(Object)
    public orderId: string | null = null;

    @Field(String)
    public incidentStatus: string = "";

    @Field(String)
    public description: string = "";

    @Field(String)
    public lng: number = 0;

    @Field(String)
    public lat: number = 0;

    @Field(String)
    public notes: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

}
