import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { CustomerUserModel } from '../models/';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerUserService extends ResourceService<CustomerUserModel> {
    protected servicePath: string = '/CustomerUser';

    public ModelType = CustomerUserModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

}
