import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

export class PaymentModel extends ResourceModel {

    @Field(String)
    public companyId: string | null = null;

    @Field(String)
    public paymentIntentId: string | null = null;

    @Field(String)
    public paymentStatus: string | null = null;

    @Field(Number)
    public amount: number = 0.00;

    @Field(String)
    public entityTable: string | null = null;

    @Field(String)
    public entityId: string | null = null;

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
