import {
    Field
} from '@smartsoftware/reflex-core';

import {
    ResourceModel
} from './resource.model';

import {
    RelatedCompany
} from './company.model';

export interface BatchItem {
    entry: number,
    customer_uuid: string,
    invoice_uuid: string,
    created_at: Date,
    code: string,
    reference: string,
    description: string,
    units: number,
    unitAmount: number,
    invoiceAmount: number,
    balancePaid: number,
    discount: number,
    customerBalance: number
}

import { User } from './driverActivityLog.model';

export class BatchModel extends ResourceModel {

    @Field(Object)
    public company: RelatedCompany = { id: "", name: "" };

    @Field(String)
    public batchId: string = "";

    @Field(Number)
    public total: number = 0.00

    @Field(String)
    public batchStatus: string = "";

    @Field(Boolean)
    public isCustomerSpecific: boolean = false;

    @Field(Object)
    public customer: User | null = null;

    @Field(Array)
    public batchItems: BatchItem[] = [];

    @Field(String)
    public notes: string = "";

    constructor(data?: any) {
        super();
        this.mapData(data);
    }

    public get name(): string {
        return this.batchId;
    }

}
