import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { GPSEntryModel, GPSData, GPSPoint } from '../models/gpsEntry.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';



@Injectable({
    providedIn: 'root'
})
export class GPSEntryService extends ResourceService<GPSEntryModel> {
    protected servicePath: string = '/GPSEntry';

    public ModelType = GPSEntryModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }
    
    public bulkCreate(entry: GPSData): Observable<GPSEntryModel[]> {
        return this._apiClient
            .request
            (
            'post',
            this.serviceUrl + 'bulkCreate',
            {
                observe: 'response',
                body: entry
            }
            )
            .pipe(
                map<any, GPSEntryModel[]>(
                    (response) => {
                        return response ? response.map((m) => {new this.ModelType(m)}) : undefined
                    }
                )
            );
    }
}
