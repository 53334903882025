import {
	Field
} from '@smartsoftware/reflex-core';

import {
	ResourceModel
} from './resource.model';

import { Company } from './companyStatement.model';
import { User } from './driverActivityLog.model';
import { ServiceType } from './serviceType.model';
import { Zone } from './bin.model';
import { DefaultTruck } from './user.model'
import { Landfill } from './landfill.model';
import { Invoice } from './invoice.model';
import { Bin } from './bin.model';
import { ActivityType } from './driverActivityType.model';
import { Material } from './material.model';

export interface Order {
    id: string,
    orderNumber: string
}

export interface Service {
    active: boolean,
    service: ServiceType,
    units: number,
    cost: number,
    override: number,
    volume: number
}

export interface Container {
    dropoff: Bin,
    pickup: Bin,
    date: Date
}

export interface ActivityLog {
    activity: ActivityType,
    start: Date,
    end: Date,
    duration: string,
    createdAt: Date
}

export interface Content {
    material: Material,
    volume: number,
    diversionRate: number,
    recVol: number,
    createdAt: Date
}

export interface StatusLog {
    status: string,
    author: User,
    start: Date,
    end: Date,
    createdAt: Date
}

export interface Cost {
    item: string,
    units: number | null,
    costPerUnit: number | null,
    totalCost: number
}

export class OrderModel extends ResourceModel {

    @Field(String)
    public orderId: string = "";

    @Field(Object)
    public customer: User | null = { name: "", id: "" };

    @Field(Object)
    public company: Company | null = { name: "", id: "" };

    @Field(Number)
    public amount: number = 0.00

    @Field(String)
    public orderStatus: string = "";

    @Field(Object)
    public serviceType: ServiceType | null = { name: "", id: "", code: "", description: "" };

    @Field(String)
    public customPurchaseOrder: string = "";

    @Field(Object)
    public createdBy: User | null = { name: "", id: "" };

    @Field(Object)
    public site: Zone | null = { name: "", id: "", accountNumber: "" };

    @Field(String)
    public siteAddress: string = "";

    @Field(String)
    public siteCity: string = "";

    @Field(String)
    public siteState: string = "";

    @Field(String)
    public siteZip: string = "";

    //  Pending creation of route component
    /*
    @Field(Object)
    public scheduledRoute: any = null;
    */

    @Field(Date)
    public scheduledTime: Date | null = null;

    @Field(Number)
    public routeDuration: number = 1;

    @Field(Object)
    public routeDriver: User | null = { name: "", id: "" };

    @Field(Object)
    public routeTruck: DefaultTruck | null = { name: "", id: "" };

    @Field(Number)
    public odometerOut: number = 0;

    @Field(Number)
    public odometerIn: number = 0;

    @Field(Object)
    public landfill: Landfill | null = { name: "", code: "", id: "" };

    @Field(String)
    public docketNumber: string = "";

    @Field(Number)
    public landfillVolume: number = 0.000;

    @Field(Number)
    public landfillCost: number = 0.00;

    @Field(Object)
    public invoice: Invoice | null = { id: "", invoiceNumber: "" }

    @Field(Number)
    public invoiceCost: number = 0.00;

    @Field(Number)
    public invoiceRevenue: number = 0.00;

    @Field(Number)
    public invoiceProfit: number = 0.00;

    @Field(Object)
    public paginatorOptions: any = {};

    @Field(Array)
    public services: Service[] = [];

    @Field(Array)
    public containers: Container[] = [];

    //@Field(Array)
    //public messages: Message[] = [];

    @Field(Array)
    public activityLog: ActivityLog[] = [];

    @Field(Array)
    public content: Content[] = [];

    @Field(Array)
    public costs: Cost[] = [];

    @Field(Array)
    public statusLog: StatusLog[] = [];

    @Field(Number)
    public auditLevel: number = 1;

    @Field(String)
    public notes: string = "";

	constructor(data?: any) {
		super();
		this.mapData(data);
	}

}
