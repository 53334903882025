import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { TruckMaintenanceTypeModel } from '../models/truckMaintenanceType.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';

@Injectable({
    providedIn: 'root'
})
export class TruckMaintenanceTypeService extends ResourceService<TruckMaintenanceTypeModel> {
    protected servicePath: string = '/TruckMaintenanceType';

    public ModelType = TruckMaintenanceTypeModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public flatType(type: TruckMaintenanceTypeModel): any {
        return {
            id: type.id,
            name: type.name
        }
    }
}
