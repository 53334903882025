import { NgModule } from '@angular/core';
import { SpinnerComponent } from './spinner.component';

@NgModule({
    imports: [],
    exports: [
        SpinnerComponent
    ],
    declarations: [
        SpinnerComponent
    ],
    entryComponents: [],
    providers: []
})
export class SpinnerModule { }
