import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { LandfillModel } from '../models/landfill.model';
import { ApiClientService } from './api-client.service';
import { ResourceService } from './resource.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LandfillService extends ResourceService<LandfillModel> {
    protected servicePath: string = '/Landfill';

    public ModelType = LandfillModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public flatLandfill(landfill: LandfillModel): any {
        return {
            id: landfill.id,
            name: landfill.name,
            code: landfill.code
        }
    }

    /**
     * Gets list of Landfills
     *
     * @param   {string}                       companyId     The Company for Landfills, if applicable.
     * @param   {string}                       landfillName  The Landfill Name to look up
     *
     * @return  {Observable<LandfillModel>[]}                List of Landfills.
     */
    public getLandfills(companyId: string = null, landfillName: string = null): Observable<LandfillModel[]> {
        let where: any = {
            query: {
                bool: {
                    must: [],
                    must_not: [
                        {
                            exists: {
                                field: "deletedAt"
                            }
                        }
                    ]
                }
            },
            size: 1000
        }

        if (companyId) {
            where.query.bool.must.push({
                nested: {
                    path: "company",
                    query: {
                        term: {
                            "company.id": companyId
                        }
                    }
                }
            });
        }

        if (landfillName) {
            let landFillArr: string[] = landfillName.replace(/[^a-zA-Z0-9 ]/g, "").split(" ");

            landFillArr.forEach((landfill: string) => {
                where.query.bool.must.push({
                    wildcard: {
                        "name": `*${landfill}*`
                    }
                });
            });
        }

        return this.list({
            allowCache: false,
            where: where
        }).pipe(
            map(
                (results: LandfillModel[]) => results.sort((a, b) => a.name.localeCompare(b.name))
            )
        );
    }
}
