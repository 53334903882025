import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { CompanyModel } from '../models/company.model';
import { Amplify, Geo } from 'aws-amplify';
import { ApiClientService, ServiceResponse } from './api-client.service';
import { ResourceService } from './resource.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReflexEnvironment as Environment } from '@smartsoftware/reflex-core';

@Injectable({
    providedIn: 'root'
})
export class CompanyService extends ResourceService<CompanyModel> {
    protected servicePath: string = '/Company';

    public ModelType = CompanyModel;

    constructor(
        protected http: HttpClient,
        protected _apiClient: ApiClientService
    ) {
        super(http, _apiClient);
    }

    public flatCompany(company: CompanyModel): any {
        return {
            id: company.id,
            name: company.name
        };
    }

    /**
     * Request a pre-signed url to upload a company's background image to.
     */
    public uploadBackgroundImage(file: File, filename: string, company: CompanyModel) {

        this.requestImageUploadUrl(file, filename, String(company.id), "backgrounds").subscribe((response: ServiceResponse) => {
            if (response.success) {
                let url: string = response.data['url'];
                let contentPath: string = response.data['path'];
                this.uploadImageFile(file, url).subscribe((response: ServiceResponse) => {

                    company.backgroundFilename = [Environment.config['contentUrl'], contentPath].join('/');
                    this.push(company).subscribe((response) => {
                        // TODO browser caches the image because the url doesn't change.
                        // So in the local copy of CompanyModel that gets
                        // propagated during runtime update events append a
                        // cache-breaking query string.
                        let cacheFix = new Date();
                        let time = cacheFix.getTime();
                        response.backgroundFilename += `?updated=${time}`
                        company.backgroundFilename = response.backgroundFilename;
                        return response;
                    });
                });
            }
        })
    }

    /**
     * Request a pre-signed url to upload a company's letterhead image to.
     */
    public uploadLetterheadImage(file: File, filename: string, company: CompanyModel) {

        this.requestImageUploadUrl(file, filename, String(company.id), "letterheads").subscribe((response: ServiceResponse) => {
            if (response.success) {
                let url: string = response.data['url'];
                let contentPath: string = response.data['path'];
                this.uploadImageFile(file, url).subscribe((response: ServiceResponse) => {

                    company.letterheadFilename = [Environment.config['contentUrl'], contentPath].join('/');
                    this.push(company).subscribe((response) => {
                        // TODO browser caches the image because the url doesn't change.
                        // So in the local copy of CompanyModel that gets
                        // propagated during runtime update events append a
                        // cache-breaking query string.
                        let cacheFix = new Date();
                        let time = cacheFix.getTime();
                        response.letterheadFilename += `?updated=${time}`
                        company.letterheadFilename = response.letterheadFilename;
                        return response;
                    });
                });
            }
        })
    }

    /**
     * Request a pre-signed url to upload a company's image to.
     */
    public requestImageUploadUrl(file: File, filename: string, id: string, uploadType: string): Observable<ServiceResponse> {
        return this._apiClient.request(
            'post',
            this.serviceUrl + "upload/" + id,
            {
                observe: 'response',
                body: {
                    filename: filename,
                    contentType: file.type,
                    uploadType: uploadType
                }
            }
        );
    }

    public uploadImageFile(file: File, url: string): Observable<ServiceResponse> {
        return this.http.put(
            url,
            file,
            {
                headers: {
                    "Content-Type": file.type
                }
            }
        ).pipe(map<any, ServiceResponse>(
            (response: any) => {
                return {
                    success: true,
                    data: response
                }
            }, (error: any) => {
                return {
                    success: false,
                    data: error
                }
            }
        ));
    }

    /**
     * Returns a list of Company records that are not deleted.
     *
     * @return  {Observable<CompanyModel>[]}    Observable containing list of Company records sorted alphabetically.
     */
    public getCompanies(): Observable<CompanyModel[]> {
        let where: any = {
            query: {
                bool: {

                    must_not: [
                        {
                            exists: {
                                field: "deletedAt"
                            }
                        }
                    ]
                }
            },
            size: 1000
        }

        return this.list({
            allowCache: false,
            where: where
        }).pipe(
            map(
                (results: CompanyModel[]) => results.sort((a, b) => a.name.localeCompare(b.name))
            )
        );
    }

    public getCompanyLngLat(companyId: string) {
        return this.get(companyId).toPromise().then((company: CompanyModel) => {
            return Geo.searchByText(company.address).then((place) => {
                if (place.length) {
                    return [place[0].geometry.point[0], place[0].geometry.point[1]];
                }
            });
        })
    }
}
